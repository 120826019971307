import React, { PropsWithChildren, useState } from 'react';
import { TrendParameter } from './optionTypes';

export const OptionsContext = React.createContext({
  optionValues: {
    trendParameter: TrendParameter.RiskDevelopment,
    timespan: 12,
    mostRecentLeft: false,
    absolute: false,
    mortality: true,
    stroke: true,
    bleeding: true,
    atrialFibrillation: true,
    coronaryArteryDisease: true,
    heartFailure: true,
    carotidArteryDisease: true,
    peripheralArteryDisease: true,
    historyOfStroke: true,
    transientIschemicAttack: true,
    thromboembolism: true,
    historyOfBleeding: true,
    chronicKidneyDisease: true,
    diabetes: true,
    hypertension: true,
    dementia: true,
  },
  optionSetter: {
    setAbsolute: (absolute: boolean) => {},
    setMostRecentLeft: (mostRecentLeft: boolean) => {},
    setTimespan: (timespan: number) => {},
    setMortality: (mortality: boolean) => {},
    setStroke: (stroke: boolean) => {},
    setBleeding: (bleeding: boolean) => {},
    setTrendParameter: (trendParameter: TrendParameter) => {},
    setAtrialFibrillation: (atrialFibrillation: boolean) => {},
    setCoronaryArteryDisease: (coronaryArteryDisease: boolean) => {},
    setHeartFailure: (heartFailure: boolean) => {},
    setCarotidArteryDisease: (carotidArteryDisease: boolean) => {},
    setPeripheralArteryDisease: (peripheralArteryDisease: boolean) => {},
    setHistoryOfStroke: (historyOfStroke: boolean) => {},
    setTransientIschemicAttack: (transientIschemicAttack: boolean) => {},
    setThromboembolism: (thromboembolism: boolean) => {},
    setHistoryOfBleeding: (historyOfBleeding: boolean) => {},
    setChronicKidneyDisease: (chronicKidneyDisease: boolean) => {},
    setDiabetes: (diabetes: boolean) => {},
    setHypertension: (hypertension: boolean) => {},
    setDementia: (dementia: boolean) => {},
  },
});

export interface OptionSetter {
  setAbsolute: (absolute: boolean) => void;
  setMostRecentLeft: (mostRecentLeft: boolean) => void;
  setTimespan: (timespan: number) => void;
  setMortality: (mortality: boolean) => void;
  setStroke: (stroke: boolean) => void;
  setBleeding: (bleeding: boolean) => void;
  setTrendParameter: (trendParameter: TrendParameter) => void;
  setAtrialFibrillation: (atrialFibrillation: boolean) => void;
  setCoronaryArteryDisease: (coronaryArteryDisease: boolean) => void;
  setHeartFailure: (heartFailure: boolean) => void;
  setCarotidArteryDisease: (carotidArteryDisease: boolean) => void;
  setPeripheralArteryDisease: (peripheralArteryDisease: boolean) => void;
  setHistoryOfStroke: (historyOfStroke: boolean) => void;
  setTransientIschemicAttack: (transientIschemicAttack: boolean) => void;
  setThromboembolism: (thromboembolism: boolean) => void;
  setHistoryOfBleeding: (historyOfBleeding: boolean) => void;
  setChronicKidneyDisease: (chronicKidneyDisease: boolean) => void;
  setDiabetes: (diabetes: boolean) => void;
  setHypertension: (hypertension: boolean) => void;
  setDementia: (dementia: boolean) => void;
}

export interface OptionValues {
  trendParameter: TrendParameter;
  timespan: number;
  mostRecentLeft: boolean;
  absolute: boolean;
  mortality: boolean;
  stroke: boolean;
  bleeding: boolean;
  atrialFibrillation: boolean;
  coronaryArteryDisease: boolean;
  heartFailure: boolean;
  carotidArteryDisease: boolean;
  peripheralArteryDisease: boolean;
  historyOfStroke: boolean;
  transientIschemicAttack: boolean;
  thromboembolism: boolean;
  historyOfBleeding: boolean;
  chronicKidneyDisease: boolean;
  diabetes: boolean;
  hypertension: boolean;
  dementia: boolean;
}

export function OptionsProvider({ children }: PropsWithChildren<{}>) {
  const [trendParameter, setTrendParameter] = useState<TrendParameter>(
    TrendParameter.RiskDevelopment,
  );
  const [mostRecentLeft, setMostRecentLeft] = useState<boolean>(false);
  const [timespan, setTimespan] = useState<number>(12);
  const [mortality, setMortality] = useState<boolean>(true);
  const [stroke, setStroke] = useState<boolean>(true);
  const [bleeding, setBleeding] = useState<boolean>(true);
  const [atrialFibrillation, setAtrialFibrillation] = useState<boolean>(true);
  const [coronaryArteryDisease, setCoronaryArteryDisease] = useState<boolean>(true);
  const [heartFailure, setHeartFailure] = useState<boolean>(true);
  const [carotidArteryDisease, setCarotidArteryDisease] = useState<boolean>(true);
  const [peripheralArteryDisease, setPeripheralArteryDisease] = useState<boolean>(true);
  const [historyOfStroke, setHistoryOfStroke] = useState<boolean>(true);
  const [transientIschemicAttack, setTransientIschemicAttack] = useState<boolean>(true);
  const [thromboembolism, setThromboembolism] = useState<boolean>(true);
  const [historyOfBleeding, setHistoryOfBleeding] = useState<boolean>(true);
  const [chronicKidneyDisease, setChronicKidneyDisease] = useState<boolean>(true);
  const [diabetes, setDiabetes] = useState<boolean>(true);
  const [hypertension, setHypertension] = useState<boolean>(true);
  const [dementia, setDementia] = useState<boolean>(true);
  const [absolute, setAbsolute] = useState<boolean>(true);
  const optionValues: OptionValues = {
    absolute: absolute,
    atrialFibrillation: atrialFibrillation,
    bleeding: bleeding,
    carotidArteryDisease: carotidArteryDisease,
    chronicKidneyDisease: chronicKidneyDisease,
    coronaryArteryDisease: coronaryArteryDisease,
    dementia: dementia,
    diabetes: diabetes,
    heartFailure: heartFailure,
    historyOfBleeding: historyOfBleeding,
    historyOfStroke: historyOfStroke,
    hypertension: hypertension,
    mortality: mortality,
    mostRecentLeft: mostRecentLeft,
    peripheralArteryDisease: peripheralArteryDisease,
    stroke: stroke,
    thromboembolism: thromboembolism,
    timespan: timespan,
    transientIschemicAttack: transientIschemicAttack,
    trendParameter: trendParameter,
  };

  const optionSetter: OptionSetter = {
    setAbsolute: setAbsolute,
    setAtrialFibrillation: setAtrialFibrillation,
    setBleeding: setBleeding,
    setCarotidArteryDisease: setCarotidArteryDisease,
    setChronicKidneyDisease: setChronicKidneyDisease,
    setCoronaryArteryDisease: setCoronaryArteryDisease,
    setDementia: setDementia,
    setDiabetes: setDiabetes,
    setHeartFailure: setHeartFailure,
    setHistoryOfBleeding: setHistoryOfBleeding,
    setHistoryOfStroke: setHistoryOfStroke,
    setHypertension: setHypertension,
    setMortality: setMortality,
    setMostRecentLeft: setMostRecentLeft,
    setPeripheralArteryDisease: setPeripheralArteryDisease,
    setStroke: setStroke,
    setThromboembolism: setThromboembolism,
    setTimespan: setTimespan,
    setTransientIschemicAttack: setTransientIschemicAttack,
    setTrendParameter: setTrendParameter,
  };

  return (
    <OptionsContext.Provider
      value={{
        optionValues,
        optionSetter,
      }}
    >
      {children}
    </OptionsContext.Provider>
  );
}
