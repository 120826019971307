import React, { FunctionComponent, useState } from 'react';
import ReactPlayer from 'react-player';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { StyledPopup } from '../StyledPopup/StyledPopup';

interface DocumentPopupProps {
  title: string;
  src: string;
}

export const VideoPopup: FunctionComponent<DocumentPopupProps> = (props) => {
  const [videoOpen, setVideoOpen] = useState(false);

  return (
    <div style={{ cursor: 'pointer' }}>
      <PlayCircleOutlineIcon
        fontSize={'large'}
        color={'primary'}
        onClick={() => setVideoOpen(true)}
      />

      <StyledPopup open={videoOpen} title={props.title} handleClose={() => setVideoOpen(false)}>
        <ReactPlayer playing url={props.src} controls={true} />
      </StyledPopup>
    </div>
  );
};
