import { useNavigate } from 'react-router';
import { AssessmentIdOrLatest } from '../api/report';

export const PATIENT_PROFILE_SUBPAGES = {
  TREND: 'trend',
  DOWNLOAD: 'download',
  VISITS: 'visits',
  ASSESSMENT: 'assessment',
};

function useNavigateTo(path: string) {
  const navigate = useNavigate();
  return () => navigate(path);
}

export function useNavigateToPatientList() {
  return useNavigateTo('/patient');
}

export function useNavigateToWebRiskEvaluation(patientId: string | number): () => void {
  return useNavigateTo(`/patient/${patientId}/evaluation/risk`);
}

export function useNavigateToRedoxRiskEvaluation(searchParams: URLSearchParams) {
  return useNavigateTo(`/redox/evaluation/risk?${searchParams.toString()}`);
}

export function useNavigateToNewWebAssessment() {
  const navigate = useNavigate();
  return (patientId: number) =>
    navigate(
      `/patient/${patientId}/${PATIENT_PROFILE_SUBPAGES.VISITS}/${PATIENT_PROFILE_SUBPAGES.ASSESSMENT}/new`,
    );
}

export function useNavigateToLatestWebAssessment() {
  const navigate = useNavigate();
  return (patientId: number) =>
    navigate(
      `/patient/${patientId}/${PATIENT_PROFILE_SUBPAGES.VISITS}/${PATIENT_PROFILE_SUBPAGES.ASSESSMENT}/latest/view`,
    );
}

export function useNavigateToExistingWebAssessment() {
  const navigate = useNavigate();
  return (patientId: string | number, assessmentId: AssessmentIdOrLatest) =>
    navigate(
      `/patient/${patientId}/${PATIENT_PROFILE_SUBPAGES.VISITS}/${PATIENT_PROFILE_SUBPAGES.ASSESSMENT}/${assessmentId}/view`,
    );
}

export function useNavigateToTrendTimeseriesPage() {
  const navigate = useNavigate();
  return (patientId: number | string) =>
    navigate(`/patient/${patientId}/${PATIENT_PROFILE_SUBPAGES.TREND}/timeseries`);
}

export function useNavigateToTrendDifferencePage() {
  const navigate = useNavigate();
  return (patientId: number | string) =>
    navigate(`/patient/${patientId}/${PATIENT_PROFILE_SUBPAGES.TREND}/difference`);
}

export function useNavigateToDownloadReportPage() {
  const navigate = useNavigate();
  return (patientId: number | string, assessmentId: AssessmentIdOrLatest) =>
    navigate(
      `/patient/${patientId}/evaluation/${PATIENT_PROFILE_SUBPAGES.DOWNLOAD}?assessmentId=${assessmentId}`,
    );
}

export function useNavigateToVisitPage() {
  const navigate = useNavigate();
  return (patientId: number) =>
    navigate(`/patient/${patientId}/${PATIENT_PROFILE_SUBPAGES.VISITS}`);
}
export function useNavigateToEvaluation() {
  const navigate = useNavigate();
  return (patientId: string | number, assessmentId: AssessmentIdOrLatest) => {
    navigate(
      `/patient/${patientId}/${PATIENT_PROFILE_SUBPAGES.VISITS}/${PATIENT_PROFILE_SUBPAGES.ASSESSMENT}/${assessmentId}/evaluate`,
    );
  };
}

export function useNavigatePatientDemographics(id: number): () => void {
  return useNavigateTo(`/patient/${id}`);
}
