import { Box, Typography } from '@mui/material';
import React from 'react';
import { VideoPopup } from '../../core/VideoPopup/VideoPopup';
import { useTranslation } from 'react-i18next';

export default function GeneralDescription() {
  const { t } = useTranslation('pages');

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography align="center" variant="h4" sx={{ color: 'white' }}>
        {t('homePage.generalDescription.title')}
      </Typography>
      <Typography align="center" sx={{ color: 'white', py: 1, width: 0.8 }}>
        {t('homePage.generalDescription.description')}
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <VideoPopup
          src={'/videos/tenacio_demo_video.mp4'}
          title={t('homePage.generalDescription.videoDescription')}
        />
        <Typography color="primary" variant="body2">
          {t('homePage.generalDescription.videoDescription')}
        </Typography>
      </Box>
    </Box>
  );
}
