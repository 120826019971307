import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import React from 'react';
import { diagnosisColorScheme } from '../../TimeSeriesView/ColorSchemes';
import { Diagnosis } from '../../../../../../api/patients';
import { HorizontalDivider } from './HorizontalDivider';
import { OptionSetter, OptionValues } from '../OptionsProvider';
import { CheckboxProps, DisplayOptionCheckbox } from './CheckboxHelper';
import { getUsedDiagnosisKeys } from '../../TimeSeriesView/DiagnosisTimeSeries';

interface DiagnosisCheckboxProps extends CheckboxProps {
  diagnosisData: Diagnosis[];
}
export function DiagnosisCheckboxes({
  optionValues,
  optionSetter,
  translator,
  diagnosisData,
}: DiagnosisCheckboxProps) {
  const diagnosisKeys = getUsedDiagnosisKeys(diagnosisData);
  const checkboxes = getDiagnosisCheckboxProps({ optionValues, optionSetter });
  const diagnosisEntries = Object.entries(checkboxes).filter((d) => diagnosisKeys.includes(d[0]));

  return (
    <FormGroup>
      {diagnosisEntries.map(([diagnosis, { checked, setChecked }], index) => (
        <React.Fragment key={diagnosis}>
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={(event) => setChecked(event.target.checked)}
                sx={{
                  color: diagnosisColorScheme[diagnosis as keyof Diagnosis],
                  '&.Mui-checked': {
                    color: diagnosisColorScheme[diagnosis as keyof Diagnosis],
                  },
                }}
              />
            }
            label={translator(`patientProfile.trend.tooltip.key.${diagnosis}.label`)}
          />
          {index < diagnosisEntries.length - 1 && <HorizontalDivider />}
        </React.Fragment>
      ))}
    </FormGroup>
  );
}

function getDiagnosisCheckboxProps({
  optionValues,
  optionSetter,
}: {
  optionValues: OptionValues;
  optionSetter: OptionSetter;
}): DisplayOptionCheckbox {
  return {
    atrialFibrillation: {
      checked: optionValues.atrialFibrillation,
      setChecked: optionSetter.setAtrialFibrillation,
    },
    coronaryArteryDisease: {
      checked: optionValues.coronaryArteryDisease,
      setChecked: optionSetter.setCoronaryArteryDisease,
    },
    heartFailure: {
      checked: optionValues.heartFailure,
      setChecked: optionSetter.setHeartFailure,
    },
    carotidArteryDisease: {
      checked: optionValues.carotidArteryDisease,
      setChecked: optionSetter.setCarotidArteryDisease,
    },
    peripheralArteryDisease: {
      checked: optionValues.peripheralArteryDisease,
      setChecked: optionSetter.setPeripheralArteryDisease,
    },
    historyOfStroke: {
      checked: optionValues.historyOfStroke,
      setChecked: optionSetter.setHistoryOfStroke,
    },
    transientIschemicAttack: {
      checked: optionValues.transientIschemicAttack,
      setChecked: optionSetter.setTransientIschemicAttack,
    },
    thromboembolism: {
      checked: optionValues.thromboembolism,
      setChecked: optionSetter.setThromboembolism,
    },
    historyOfBleeding: {
      checked: optionValues.historyOfBleeding,
      setChecked: optionSetter.setHistoryOfBleeding,
    },
    chronicKidneyDisease: {
      checked: optionValues.chronicKidneyDisease,
      setChecked: optionSetter.setChronicKidneyDisease,
    },
    diabetes: {
      checked: optionValues.diabetes,
      setChecked: optionSetter.setDiabetes,
    },
    hypertension: {
      checked: optionValues.hypertension,
      setChecked: optionSetter.setHypertension,
    },
    dementia: {
      checked: optionValues.dementia,
      setChecked: optionSetter.setDementia,
    },
  };
}
