import { Button, Grid, Tooltip } from '@mui/material';
import { CheckboxWithLabelAndTooltip } from '../../../../core/input-components/CheckboxWithLabelAndTooltip';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isRedoxPage } from '../../../../../utils/isRedoxPage';
import { InformationTextPopup } from '../../../../core/InformationText/InformationTextPopup';
import useInformationText from '../../../../core/InformationText/useInformationText';
import { useSettings, useTriggerOnSettingsChange } from '../../../../../hooks/useSettings';
import AssessmentCardHeading from '../../subComponents/AssessmentCard/AssessmentCardHeading';
import { useSubmitFunctionWithCorrectUnits } from '../../logic/correctUnits';
import { AssessmentFormType } from '../../AssessmentForm';
import { AssessmentParameters } from '../../../../../types/assessments';

interface Props {
  submitHandler: (params: AssessmentParameters) => void;
  form: AssessmentFormType;
}

export function ConsentSubmit(props: Props) {
  const { t } = useTranslation('forms');

  const { settings } = useSettings();
  const isRedox = isRedoxPage();
  const confirmationForm = useForm({
    defaultValues: {
      consent: isRedox,
      dataAccuracyConfirm: false,
    },
  });

  const termsOfUse = useInformationText('termsOfUse');

  useTriggerOnSettingsChange(() => {
    confirmationForm.setValue('consent', false);
    confirmationForm.setValue('dataAccuracyConfirm', false);
  });

  const submitFunction = useSubmitFunctionWithCorrectUnits(
    props.form,
    settings.units,
    props.submitHandler,
  );

  const consent = confirmationForm.watch('consent');
  const dataAccuracyConfirm = confirmationForm.watch('dataAccuracyConfirm');
  return (
    <div style={{ margin: '1rem', padding: '2rem' }}>
      <AssessmentCardHeading text={t('confirmations.heading')} />
      <Grid container spacing={3}>
        {!isRedox && (
          <Grid item xs={12}>
            <CheckboxWithLabelAndTooltip
              name="consent"
              label={
                <p>
                  {t('confirmations.consentText.beforeLink')}
                  <InformationTextPopup
                    document={termsOfUse}
                    linkText={t('confirmations.consentText.link')}
                  />
                  {t('confirmations.consentText.afterLink')}
                </p>
              }
              control={confirmationForm.control}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <CheckboxWithLabelAndTooltip
            name="dataAccuracyConfirm"
            label={<p>{t('confirmations.dataConfirmation')}</p>}
            control={confirmationForm.control}
          />
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={6}>
          <Tooltip title={t<string>('confirmations.consentText.noConsentTooltip')}>
            <span>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={submitFunction}
                disabled={!(consent || isRedoxPage()) || !dataAccuracyConfirm}
              >
                {t('submitButton')}
              </Button>
            </span>
          </Tooltip>
        </Grid>
      </Grid>
    </div>
  );
}
