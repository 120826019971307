import { DownloadFormChoices, RecommendationGroupChoice } from './report.types';

export const defaultDownloadChoices: DownloadFormChoices = {
  comment: null,
  selectAll: false,
  medicalHistory: false,
  medications: false,
  optionalParameters: false,
  personalData: true,
  riskProfile: false,
  vitals: false,
  recommendationGroupChoices: [RecommendationGroupChoice.none],
};

export const checkBoxChoices: Array<keyof DownloadFormChoices> = [
  'personalData',
  'vitals',
  'optionalParameters',
  'medicalHistory',
  'medications',
  'riskProfile',
];

export const disabledCheckboxes: Array<keyof DownloadFormChoices> = ['personalData'];
