import { styled } from '@mui/material/styles';
import { Container } from '@mui/material';

const PREFIX = 'PatientsListPage';

export const classes = {
  searchField: `${PREFIX}-searchField`,
  container: `${PREFIX}-container`,
};

export const StyledContainer = styled(Container)({
  [`& .${classes.searchField}`]: {
    margin: '2rem 0rem',
  },
  [`&.${classes.container}`]: {
    paddingTop: '3rem',
    paddingBottom: '3rem',
  },
});
