import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { sessionStorageKeys } from '../config/constants';

// will not take care of cleaning the session storage => otherwise using it would be senseless
// will overwrite the storage if it is already in use
export function useSessionStorage<T>(
  key: keyof typeof sessionStorageKeys,
  defaultValue: T,
  reset: boolean = false,
): [T, Dispatch<SetStateAction<T>>] {
  const [value, setValue] = useState(getSessionStorageOrDefault(key, defaultValue));

  useEffect(() => {
    if (reset) {
      sessionStorage.removeItem(key);
    }
  }, [reset, key]);

  useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
}

function getSessionStorageOrDefault<T>(key: string, defaultValue: T): T {
  const stored = sessionStorage.getItem(key);
  if (!stored) {
    return defaultValue;
  }
  return JSON.parse(stored);
}
