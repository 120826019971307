import React from 'react';
import { Route, Routes } from 'react-router';
import Footer from './core/Footer/Footer';
import Header from './core/Header/Header';
import { HomePage } from './home/HomePage';
import { PatientsListPage } from './PatientListPage/PatientsListPage';
import { WebPatientProfile } from './patient-profile/WebPatientProfile';
import { Box } from '@mui/material';
import { topNavigationHeaderSize } from '../config/constants';
import { RedoxInputAssessmentPage } from './assessment/assessmentPageVariants/RedoxInputAssessmentPage';
import { WebInputAssessmentPage } from './assessment/assessmentPageVariants/WebInputAssessmentPage';
import { RedoxPatientProfile } from './patient-profile/RedoxPatientProfile';
import { WebEvaluationProfile } from './patient-profile/WebEvaluationProfile';
import { ViewOnlyWebAssessmentPage } from './assessment/assessmentPageVariants/ViewOnlyWebAssessmentPage';
import { DemographicDataPage } from './PatientDemographicsPage/DemographicDataPage';
import { Visits } from './patient-profile/visits/Visits';
import { NewTrend } from './patient-profile/trend/NewTrend';

function App() {
  return (
    <>
      <Header />
      <Box
        sx={{ minHeight: `calc(100vh - ${topNavigationHeaderSize})`, pt: topNavigationHeaderSize }}
      >
        <Routes>
          <Route path="/patient/:patientId/visits" element={<Visits />} />
          <Route path="/patient/:patientId/evaluation/:subpage" element={<WebPatientProfile />} />
          <Route
            path="/patient/:patientId/visits/assessment/:assessmentId/evaluate"
            element={<WebEvaluationProfile />}
          />
          <Route
            path="/patient/:patientId/visits/assessment/new"
            element={<WebInputAssessmentPage />}
          />
          <Route
            path="/patient/:patientId/visits/assessment/:assessmentId/view"
            element={<ViewOnlyWebAssessmentPage />}
          />
          <Route path="/patient" element={<PatientsListPage />} />
          <Route path="/patient/:patientId" element={<DemographicDataPage />} />
          <Route path="/patient/:patientId/trend/:subpage" element={<NewTrend />} />
          <Route path="/redox/assessment/:assessmentId" element={<RedoxInputAssessmentPage />} />
          <Route path="/redox/evaluation/:subpage" element={<RedoxPatientProfile />} />
          <Route path="/" element={<HomePage />} />
        </Routes>
      </Box>
      <Footer />
    </>
  );
}

export default App;
