import React, { useState } from 'react';
import { IconButton, Link, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { StyledPopup } from '../StyledPopup/StyledPopup';
import ReactPlayer from 'react-player';
import { useTranslation } from 'react-i18next';

export default function HelpMenu() {
  const { t } = useTranslation(['pages', 'header']);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [demoVideoOpen, setDemoVideoOpen] = useState<boolean>(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };

  const handleDemoVideo = () => {
    setDemoVideoOpen(true);
    closeMenu();
  };

  return (
    <>
      <Tooltip
        title={<Typography variant={'caption'}>{t('header:help.tooltip')}</Typography>}
        placement={'bottom'}
      >
        <IconButton color="primary" onClick={handleClick} size="large">
          <QuestionMarkIcon color="primary" />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={anchorEl !== null}
        onClose={closeMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{
          '& .MuiPaper-root': {
            color: (theme) => theme.palette.primary.main,
            backgroundColor: (theme) => theme.palette.action.active,
            '& .MuiMenuItem-root': {
              '&:hover': {
                background: 'rgba(255, 255, 255, 0.125)',
              },
            },
          },
        }}
      >
        <Link
          href={'/documents/Opticor_User_Guide.pdf'}
          rel="noopener noreferrer"
          target="_blank"
          underline="none"
        >
          <MenuItem onClick={closeMenu}>User Guide</MenuItem>
        </Link>
        <MenuItem onClick={handleDemoVideo}>Demo Video</MenuItem>
      </Menu>
      <StyledPopup
        title={t('pages:homePage.generalDescription.videoDescription')}
        open={demoVideoOpen}
        handleClose={() => setDemoVideoOpen(false)}
      >
        <ReactPlayer playing url={'/videos/tenacio_demo_video.mp4'} controls={true} />
      </StyledPopup>
    </>
  );
}
