import { useContext } from 'react';
import { OptionsContext } from './OptionsProvider';

export function useOptions() {
  const { optionValues, optionSetter } = useContext(OptionsContext);

  return {
    optionValues,
    optionSetter,
  };
}
