import React, { useEffect, useState } from 'react';
import {
  AssessmentComponent,
  useAssessments,
  useRiskDifference,
} from '../../../../../api/patients';
import useWithLoadingAndErrorIndication from '../../../../../hooks/loadingIndication/useWithLoadingAndErrorIndication';
import { NoTrendData } from '../NoTrendData';
import { Button, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { filterOutByAssessmentId } from '../../../NavigationComponents/AssessmentDateDropdown';
import { useSettings } from '../../../../../hooks/useSettings';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { EmptyPlaceholderPage } from '../../../../core/EmptyPlaceholderPage';
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { riskColorScheme } from '../TimeSeriesView/ColorSchemes';
import { useOptions } from '../Options/useOptions';
import { RiskDifferenceTooltip } from '../CustomTooltip/RiskDifferenceTooltip';
import { useTranslation } from 'react-i18next';

export function RiskDifference({ patientId }: { patientId: number }) {
  const { data: assessmentList } = useWithLoadingAndErrorIndication(useAssessments(patientId));
  const [fromAssessment, setFromAssessment] = useState<AssessmentComponent>();
  const [toAssessment, setToAssessment] = useState<AssessmentComponent>();
  if (!assessmentList || assessmentList.length < 2) {
    return <NoTrendData />;
  }

  return (
    <>
      <AssessmentSelection
        assessmentList={assessmentList}
        fromAssessment={fromAssessment}
        toAssessment={toAssessment}
        setFromAssessment={setFromAssessment}
        setToAssessment={setToAssessment}
      />
      {fromAssessment && toAssessment ? (
        <RiskChart
          patientId={patientId}
          fromAssessment={fromAssessment}
          toAssessment={toAssessment}
        />
      ) : null}
    </>
  );
}

interface AssessmentSelectionProps {
  fromAssessment: AssessmentComponent | undefined;
  toAssessment: AssessmentComponent | undefined;
  setFromAssessment: (assessment: AssessmentComponent) => void;
  setToAssessment: (assessment: AssessmentComponent) => void;
  assessmentList: AssessmentComponent[];
}

function AssessmentSelection({
  assessmentList,
  fromAssessment,
  toAssessment,
  setFromAssessment,
  setToAssessment,
}: AssessmentSelectionProps) {
  const { t } = useTranslation('pages');
  useEffect(() => {
    if (!fromAssessment || !toAssessment) {
      setFromAssessment(assessmentList[1]);
      setToAssessment(assessmentList[0]);
    }
  }, [fromAssessment, toAssessment, assessmentList, setFromAssessment, setToAssessment]);

  return (
    <>
      <Stack direction={'row'}>
        <Typography variant={'h6'} sx={{ marginTop: 1, marginRight: 1 }}>
          {t('patientProfile.trend.difference.headline.differenceInPercent')}
        </Typography>
        <AssessmentDropdown
          assessment={fromAssessment}
          assessmentList={assessmentList}
          setAssessment={setFromAssessment}
        />
        <Typography variant={'h6'} sx={{ marginTop: 1, marginX: 2 }}>
          {t('patientProfile.trend.difference.headline.comparison')}
        </Typography>
        <AssessmentDropdown
          assessment={toAssessment}
          assessmentList={assessmentList}
          setAssessment={setToAssessment}
        />
      </Stack>
    </>
  );
}

function AssessmentDropdown({
  assessment,
  assessmentList,
  setAssessment,
}: {
  assessment: AssessmentComponent | undefined;
  assessmentList: AssessmentComponent[];
  setAssessment: (assessment: AssessmentComponent) => void;
}) {
  const { helpers } = useSettings();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };

  if (!assessment) {
    return <EmptyPlaceholderPage />;
  }

  const assessmentsInDropdown = filterOutByAssessmentId(assessmentList, assessment.assessmentId);
  return (
    <>
      <Button
        onClick={handleClick}
        variant={'outlined'}
        endIcon={anchorEl == null ? <ExpandMore /> : <ExpandLess />}
        sx={{
          color: 'neutral.main',
          padding: 0,
          width: '6rem',
          height: '2.5rem',
          border: 'none',
          borderBottom: '2px solid black',
          ':hover': {
            border: 'none',
            borderBottom: '3px solid currentColor',
          },
        }}
      >
        <Typography variant={'h6'} display={'inline'} sx={{ mb: 0, ml: 1 }}>
          {helpers.formatDate(assessment.date)}
        </Typography>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={anchorEl !== null}
        onClose={closeMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{
          '& .MuiPaper-root': {
            color: (theme) => theme.palette.primary.main,
            backgroundColor: (theme) => theme.palette.secondary.light,
            '& .MuiMenuItem-root': {
              '&:hover': {
                background: 'secondary.main',
              },
            },
          },
        }}
      >
        {assessmentsInDropdown.map((assessmentComponent) => (
          <MenuItem
            onClick={() => {
              setAssessment(assessmentComponent);
              closeMenu();
            }}
            sx={{ color: 'neutral.main' }}
            key={assessmentComponent.assessmentId}
          >
            <Typography>{helpers.formatDate(assessmentComponent.date)}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

function RiskChart({
  patientId,
  fromAssessment,
  toAssessment,
}: {
  patientId: number;
  fromAssessment: AssessmentComponent;
  toAssessment: AssessmentComponent;
}) {
  const { t } = useTranslation('pages');
  const { optionValues } = useOptions();
  const { data: riskDifference } = useWithLoadingAndErrorIndication(
    useRiskDifference(
      patientId,
      fromAssessment.assessmentId,
      toAssessment.assessmentId,
      optionValues.absolute,
    ),
  );
  const [clickable, setClickable] = useState<boolean>(false);

  if (!riskDifference) {
    return <EmptyPlaceholderPage />;
  }
  const riskValues = [riskDifference.mortality, riskDifference.bleeding, riskDifference.stroke];

  const maxRisk = Math.ceil(Math.max(...riskValues.map(Math.abs)));
  const range = Math.ceil(maxRisk + 0.25 * maxRisk);
  const ticks = getTicks(range);
  return (
    <ResponsiveContainer width={'100%'} height={500}>
      <BarChart
        width={500}
        height={300}
        data={[riskDifference]}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip
          trigger={clickable ? 'click' : 'hover'}
          content={<RiskDifferenceTooltip setClickable={setClickable} />}
        />
        <XAxis
          tick={false}
          label={{
            value: t('patientProfile.trend.difference.chartLabel'),
            position: 'insideBottomLeft',
            offset: 0,
          }}
        />
        <YAxis domain={[-range, range]} ticks={ticks} />

        <ReferenceLine y={0} stroke="#000000" />
        <Bar
          dataKey={'mortality'}
          fill={optionValues.mortality ? riskColorScheme.mortality : 'transparent'}
          maxBarSize={80}
        >
          {optionValues.mortality ? (
            <LabelList content={renderRiskLabel('Mortality')} position={'top'} />
          ) : null}
        </Bar>
        <Bar
          dataKey={'bleeding'}
          fill={optionValues.bleeding ? riskColorScheme.bleeding : 'transparent'}
          maxBarSize={80}
        >
          {optionValues.bleeding ? (
            <LabelList content={renderRiskLabel('Bleeding')} position={'top'} />
          ) : null}
        </Bar>
        <Bar
          dataKey={'stroke'}
          fill={optionValues.stroke ? riskColorScheme.stroke : 'transparent'}
          maxBarSize={80}
        >
          {optionValues.stroke ? (
            <LabelList content={renderRiskLabel('Stroke')} position={'top'} />
          ) : null}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}

function renderRiskLabel(label: string) {
  return (props: any) => {
    const { x, y, width, value } = props;
    const isPositive = value >= 0;
    const radius = isPositive ? 10 : 37;

    const yPos = isPositive ? y - radius : y + radius;

    return (
      <text x={x + width / 2} y={yPos} textAnchor="middle" dominantBaseline="middle">
        <Typography component="tspan" x={x + width / 2} dy="-1.2em" variant={'h5'}>
          {label}
        </Typography>
        <Typography
          component="tspan"
          x={x + width / 2}
          dy="1.2em"
          variant={'h5'}
          fill={isPositive ? '#cf4646' : '#5ea763'}
        >
          {`${isPositive ? '+' : ''}${value} %`}
        </Typography>
      </text>
    );
  };
}

function getTicks(range: number) {
  const halfRange = Math.floor(range / 2);
  return [-range, -halfRange, 0, halfRange, range];
}
