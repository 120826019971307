import { TrendData } from '../TimeSeriesView/RiskTimeSeries';
import {
  getAssessmentDifference,
  getAssessmentDifferenceFromTo,
  RiskDifferenceComponent,
} from '../../../../../api/patients';
import { useQuery } from 'react-query';
import { AssessmentDifference } from '../../../../../types/assessments';
import { AxiosError } from 'axios';
import React, { ReactNode } from 'react';
import { AddCircle, Outbound, RemoveCircle } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { InfoIcon } from '../../../../core/customIcons/InfoIcon';

export const NEGATIVE_EFFECT_COLOR = '#dca8a8';
export const POSITIVE_EFFECT_COLOR = '#72c3a6';
export function useAssessmentDifferenceForTimeSeriesTooltip(active: any, payload: any) {
  const trendData = active && payload && payload.length ? (payload[0].payload as TrendData) : null;
  const patientId = trendData?.patientId;
  const assessmentId = trendData?.assignmentId;

  return useQuery<AssessmentDifference, AxiosError>(
    ['patient', patientId, 'assessment', assessmentId, 'assessmentDifference'],
    () => getAssessmentDifference(patientId, assessmentId),
    { enabled: !!trendData },
  );
}

export function useAssessmentDifferenceForDifferenceTooltip(active: any, payload: any) {
  const riskDifference =
    active && payload && payload.length ? (payload[0].payload as RiskDifferenceComponent) : null;
  const patientId = riskDifference?.patientId;
  const fromAssessmentId = riskDifference?.fromAssessmentId;
  const toAssessmentId = riskDifference?.toAssessmentId;
  return useQuery<AssessmentDifference, AxiosError>(
    ['patient', patientId, 'assessmentDifference', fromAssessmentId, toAssessmentId],
    () => getAssessmentDifferenceFromTo(patientId, fromAssessmentId, toAssessmentId),
    { enabled: !!riskDifference },
  );
}
export function getTooltipAssessmentDifference(
  assessmentDifference: AssessmentDifference,
  translation: (key: string) => string,
) {
  const tooltipAssessmentDifference: TooltipAssessmentDifference = {};
  const keysForNumberDifference = [
    'bmi',
    'pulse',
    'systolicBloodPressure',
    'diastolicBloodPressure',
    'eGFR',
    'LADiameter',
    'LFEjectionFraction',
  ];

  const keysForBooleanDifference = [
    'atrialFibrillation',
    'coronaryArteryDisease',
    'heartFailure',
    'carotidArteryDisease',
    'peripheralArteryDisease',
    'historyOfStroke',
    'transientIschemicAttack',
    'thromboembolism',
    'historyOfBleeding',
    'chronicKidneyDisease',
    'diabetes',
    'hypertension',
    'currentSmoker',
    'dementia',
    'antiplateletMedication',
  ];

  const keysForMedicationDifference = [
    'contraindicationForOAC',
    'oralAnticoagulant',
    'diabetesMedication',
  ];

  keysForNumberDifference.forEach((key) => {
    if (assessmentDifference[key] !== undefined && assessmentDifference[key] > 0) {
      tooltipAssessmentDifference[key] = <IncreaseIcon />;
    } else if (assessmentDifference[key] !== undefined && assessmentDifference[key] < 0) {
      tooltipAssessmentDifference[key] = <DecreaseIcon />;
    }
  });

  keysForBooleanDifference.forEach((key) => {
    if (assessmentDifference[key] !== undefined && assessmentDifference[key] === true) {
      tooltipAssessmentDifference[key] = <PlusIcon />;
    } else if (assessmentDifference[key] !== undefined && assessmentDifference[key] === false) {
      tooltipAssessmentDifference[key] = <MinusIcon />;
    }
  });

  keysForMedicationDifference.forEach((key) => {
    if (assessmentDifference[key] !== undefined) {
      tooltipAssessmentDifference[key] = (
        <Tooltip
          title={translation(
            `patientProfile.trend.tooltip.key.${key}.values.${assessmentDifference[key]}.tooltip`,
          )}
          placement={'right-start'}
          arrow
        >
          <IconButton size={'small'} sx={{ padding: 0 }}>
            <InfoIcon sx={{ color: 'primary.main' }} />
          </IconButton>
        </Tooltip>
      );
    }
  });

  return tooltipAssessmentDifference;
}
function IncreaseIcon() {
  return <Outbound sx={{ color: NEGATIVE_EFFECT_COLOR }} />;
}

function DecreaseIcon() {
  return <Outbound sx={{ transform: 'scaleY(-1)', color: POSITIVE_EFFECT_COLOR }} />;
}

function PlusIcon() {
  return <AddCircle sx={{ color: NEGATIVE_EFFECT_COLOR }} />;
}

function MinusIcon() {
  return <RemoveCircle sx={{ color: POSITIVE_EFFECT_COLOR }} />;
}

export interface TooltipAssessmentDifference {
  [key: string]: ReactNode;
  bmi?: ReactNode;
  pulse?: ReactNode;
  systolicBloodPressure?: ReactNode;
  diastolicBloodPressure?: ReactNode;
  CCS_SAF_score?: ReactNode;
  atrialFibrillation?: ReactNode;
  coronaryArteryDisease?: ReactNode;
  heartFailure?: ReactNode;
  carotidArteryDisease?: ReactNode;
  peripheralArteryDisease?: ReactNode;
  historyOfStroke?: ReactNode;
  transientIschemicAttack?: ReactNode;
  thromboembolism?: ReactNode;
  historyOfBleeding?: ReactNode;
  chronicKidneyDisease?: ReactNode;
  diabetes?: ReactNode;
  hypertension?: ReactNode;
  currentSmoker?: ReactNode;
  dementia?: ReactNode;
  antiplateletMedication?: ReactNode;
  LFEjectionFraction?: ReactNode;
  LADiameter?: ReactNode;
  eGFR?: ReactNode;
  rhythmControlDesired?: ReactNode;
}
