import { Diagnosis } from '../../../../../api/patients';
import { RiskComponents } from '../../../../../types/risk';

type DiagnosisColorScheme = {
  [K in keyof Diagnosis]: string;
};

type RiskColorScheme = {
  [K in keyof RiskComponents]: string;
};

export const riskColorScheme: RiskColorScheme = {
  mortality: '#6bbbc1',
  stroke: '#87bc8a',
  bleeding: '#c17f6b',
};

export const diagnosisColorScheme: DiagnosisColorScheme = {
  atrialFibrillation: '#8b4513',
  carotidArteryDisease: '#4682b4',
  chronicKidneyDisease: '#6bbbc1',
  coronaryArteryDisease: '#aaaadf',
  dementia: '#70b174',
  diabetes: '#9cb1c9',
  heartFailure: '#f5ba60',
  historyOfBleeding: '#808000',
  historyOfStroke: '#708090',
  hypertension: '#2f4f4f',
  peripheralArteryDisease: '#dca8a8',
  thromboembolism: '#800000',
  transientIschemicAttack: '#008080',
};
