import { UseFormReturn } from 'react-hook-form';
import { PDFReportRequest, RecommendationCategoryReportConfiguration } from '../../../api/report';

export interface DownloadFormChoices {
  selectAll: boolean;
  personalData: boolean;
  vitals: boolean;
  optionalParameters: boolean;
  medicalHistory: boolean;
  medications: boolean;
  riskProfile: boolean;
  recommendationGroupChoices: RecommendationGroupChoice[];
  comment: string | null;
}

export type DownloadFormChoicesForm = UseFormReturn<DownloadFormChoices>;

export enum RecommendationGroupChoice {
  'all',
  'none',
  'considerations',
  'tickedRecommendations',
  'notTickedRecommendations',
}

export function getRecommendationsConfig(
  choices: RecommendationGroupChoice[],
): PDFReportRequest['configuration']['include']['recommendations'] {
  let recommendation: RecommendationCategoryReportConfiguration['recommendation'] = 'none';

  if (
    choices.includes(RecommendationGroupChoice.tickedRecommendations) &&
    choices.includes(RecommendationGroupChoice.notTickedRecommendations)
  ) {
    recommendation = 'all';
  } else if (choices.includes(RecommendationGroupChoice.tickedRecommendations)) {
    recommendation = 'applied';
  } else if (choices.includes(RecommendationGroupChoice.notTickedRecommendations)) {
    recommendation = 'notApplied';
  }
  let consideration: RecommendationCategoryReportConfiguration['consideration'] = 'none';

  if (choices.includes(RecommendationGroupChoice.considerations)) {
    consideration = 'all';
  }

  return {
    recommendation,
    consideration,
  };
}
