import { DEFAULT_ASSESSMENT_VALUES_METRIC } from '../../../../../config/defaultValues';
import { AssessmentFormType } from '../../AssessmentForm';
import { MinMaxType } from '../../logic/rule-helper';
import { AssessmentParameters, OptionalParameters } from '../../../../../types/assessments';
import {
  DataRepresentations,
  getAllKeys,
  RepresentationMapping,
} from '../../logic/DataRepresentations';

const optionalParameterDataRepresentationMapping: RepresentationMapping<OptionalParameters> = {
  LADiameter: DataRepresentations.other,
  LFEjectionFraction: DataRepresentations.other,
  eGFR: DataRepresentations.other,
};

export const optionalParameterKeys = getAllKeys(optionalParameterDataRepresentationMapping);

export function resetOptionalParameters(
  form: AssessmentFormType,
  prefilledValues?: Partial<AssessmentParameters>,
) {
  form.reset({
    ...form.getValues(),
    ...Object.fromEntries(
      optionalParameterKeys.map((key) => [
        key,
        prefilledValues?.[key] ?? DEFAULT_ASSESSMENT_VALUES_METRIC[key],
      ]),
    ),
  });
}

export const allowedValueRangesForOptionalParameters: MinMaxType<keyof OptionalParameters> = {
  eGFR: {
    min: 0,
    max: 150,
  },
  LADiameter: {
    min: 10,
    max: 70,
  },
  LFEjectionFraction: {
    min: 0,
    max: 100,
  },
};
