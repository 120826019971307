import { AssessmentFormType } from '../../AssessmentForm';
import React from 'react';
import Grid from '@mui/material/Grid';
import { StyledTextField } from '../../../../core/input-components/StyledTextField';
import { ruleHelper } from '../../logic/rule-helper';
import { useTranslation } from 'react-i18next';
import { allowedValueRangesForOptionalParameters } from './optionalParameters.logic';
import { OptionalParameters } from '../../../../../types/assessments';

const UNITS = {
  eGFR: 'ml/min/1.73m²',
  LADiameter: 'mm',
  LFEjectionFraction: '%',
};

export function OptionalParametersEditable({ form }: { form: AssessmentFormType }) {
  return (
    <Grid container columnSpacing={3} rowSpacing={1} sx={{ p: 0 }}>
      <OptionalParameterInput fieldName={'eGFR'} form={form} />
      <OptionalParameterInput fieldName={'LADiameter'} form={form} />
      <OptionalParameterInput fieldName={'LFEjectionFraction'} form={form} />
    </Grid>
  );
}

function OptionalParameterInput({
  fieldName,
  form,
}: {
  fieldName: keyof OptionalParameters;
  form: AssessmentFormType;
}) {
  const { t } = useTranslation('forms');

  return (
    <Grid item xs={12} sm={6} md={12} sx={{ p: 0 }}>
      <StyledTextField
        name={fieldName}
        type="number"
        label={t(`optionalParameters.${fieldName}`)}
        register={form.register(
          fieldName,
          ruleHelper(t, allowedValueRangesForOptionalParameters, fieldName),
        )}
        errors={form.formState.errors}
        adornment={UNITS[fieldName]}
      />
    </Grid>
  );
}
