export enum DocumentSectionType {
  title = 'title',
  paragraph = 'paragraph',
  boldParagraph = 'boldParagraph',
  link = 'link',
  text = 'text',
  image = 'image',
  newLine = 'newLine',
}

export interface DocumentSection {
  type: DocumentSectionType;
  content: string;
}

export interface DocumentConfiguration {
  title: string;
  sections: Array<DocumentSection>;
}
