import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import React from 'react';
import { NumericalParameterLine } from '../../subComponents/NumericalParameterLine';
import { AssessmentFormType } from '../../AssessmentForm';

export function OptionalParametersNonEditable({ form }: { form: AssessmentFormType }) {
  const { t } = useTranslation('forms');
  const values = form.getValues();

  return (
    <Stack>
      <NumericalParameterLine
        title={t('optionalParameters.eGFR')}
        value={values.eGFR}
        unitLabel={'ml/min/1.73m²'}
      />
      <NumericalParameterLine
        title={t('optionalParameters.LADiameter')}
        value={values.LADiameter}
        unitLabel={'mm'}
      />

      <NumericalParameterLine
        title={t('optionalParameters.LFEjectionFraction')}
        value={values.LFEjectionFraction}
        unitLabel={'%'}
        withDivider={false}
      />
    </Stack>
  );
}
