import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import React from 'react';

export function PatientsListHeading() {
  const { t } = useTranslation('pages');
  return (
    <Typography variant="h3" component="h1">
      {t('patients.heading', 'Please select a patient')}
    </Typography>
  );
}
