import { Box, Card, Grid, Typography, useTheme } from '@mui/material';
import React from 'react';
import { PreviousYearButton, YearProp } from './PreviousYearButton';
import { DATE_BOX_HEIGHT, DATE_BOX_MARGIN_RIGHT, DATE_BOX_MARGIN_TOP } from './Calendar';

function getMonthsOfTheYear(): string[] {
  return [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
}

export function Months({ yearRange, setYearRange }: YearProp) {
  const months = getMonthsOfTheYear();
  const theme = useTheme();
  const secondaryColor = theme.palette.secondary.dark;
  return (
    <Grid container direction={'column'} item xs>
      <Grid item xs>
        <PreviousYearButton yearRange={yearRange} setYearRange={setYearRange} />
      </Grid>
      {months.map((month) => (
        <Grid item xs key={month}>
          <Card
            sx={{
              height: DATE_BOX_HEIGHT,
              mt: DATE_BOX_MARGIN_TOP,
              mr: DATE_BOX_MARGIN_RIGHT,
              border: 'none',
              boxShadow: 'none',
              backgroundColor: 'transparent',
            }}
          >
            <Box display="flex" justifyContent="flex-end" color={secondaryColor} sx={{ pr: 1 }}>
              <Typography>{month}</Typography>
            </Box>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}
