import React from 'react';
import ReactPDF, { Document, Font, Link, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { EvaluationResult, Patient, Recommendation } from '../api/patients';
import { DocumentSectionType } from '../components/core/DocumentPopup/document.types';
import { useTranslation } from 'react-i18next';

// We need to use ts-ignore here because these files do not have any types associated with them.
// This is necessary because the Font.register method does not support using the css font-faces we defined in fonts.
/* eslint-disable */
// @ts-ignore
import OpenSansLight from '../fonts/open_sans/Open_Sans_300.ttf';
// @ts-ignore
import OpenSans from '../fonts/open_sans/Open_Sans_400.ttf';
// @ts-ignore
import OpenSansBold from '../fonts/open_sans/Open_Sans_700.ttf';
// @ts-ignore
import RoundedElegance from '../fonts/rounded_elegance/Rounded Elegance.ttf';
/* eslint-enable */

// Styling the pdf; Note: NOT full CSS (see https://react-pdf.org/styling)
const styles = StyleSheet.create({
  page: {
    fontFamily: 'Open Sans',
    fontWeight: 400,
    fontSize: '5mm',
    lineHeight: 1.5,
  },
  header: {
    backgroundColor: '#303030',
    height: '1.75cm',
    width: '100vw',
    alignContent: 'center',
  },
  headerTitle: {
    fontFamily: 'Rounded Elegance',
    alignSelf: 'flex-start',
    color: '#39E991',
    fontSize: '8mm',
    marginVertical: 'auto',
    marginLeft: '5mm',
  },
  content: {
    flexDirection: 'column',
  },
  patientHeader: {
    height: '10mm',
    flexDirection: 'row',
    marginTop: '3mm',
    width: '100vw',
  },
  patientNameContainer: {
    marginLeft: '1cm',
    backgroundColor: '#39E991',
    flexGrow: 1,
  },
  patientName: {
    marginVertical: 'auto',
    paddingLeft: '5mm',
    fontStyle: 'bold',
    color: '#000000',
  },
  patientNameSpacer: {
    marginRight: '1cm',
    backgroundColor: '#000000',
    color: '#39E991',
    flexGrow: 1,
  },
  contentHeader: {
    width: '100vw',
    marginTop: '3mm',
    paddingHorizontal: '1cm',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    fontStyle: 'bold',
  },
  evalContent: {
    marginTop: '5mm',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    fontStyle: 'normal',
  },
  column: {
    flexDirection: 'column',
    maxWidth: '47vw',
  },
  columnTitle: {
    fontStyle: 'bold',
    fontSize: '6mm',
    textDecoration: 'underline',
    marginHorizontal: 'auto',
  },
  section: {
    margin: 5,
    padding: 5,
    flexGrow: 1,
    fontStyle: 'normal',
  },
});

// Create Document Component
interface OptiCorPDFReportProps {
  evalData: EvaluationResult;
  patient: Patient;
}

function OptiCorPDFReport({ evalData, patient }: OptiCorPDFReportProps) {
  const { t } = useTranslation('pages');
  const difference = (currentRisk: number, adjustedRisk: number) =>
    Math.round((100 * (adjustedRisk - currentRisk)) / currentRisk);

  const riskSection = (risk: string, current: number, adjusted: number) => {
    const diff = difference(current, adjusted);
    const prefix = 'patientProfile.evaluation.risks.';
    return (
      <View style={styles.section}>
        <Text style={{ fontStyle: 'bold' }}>{risk}</Text>
        <Text style={{ color: 'darkgrey' }}>
          {t(
            prefix + (diff === 0 ? 'noChangedRisk' : diff < 0 ? 'decreasedRisk' : 'increasedRisk'),
          )}
          &nbsp;
          {diff} %
        </Text>
        <Text>
          {t(prefix + 'currentRisk')}: &nbsp;&nbsp; {(current * 100).toFixed(1)}
        </Text>
        <Text>
          {t(prefix + 'adjustedRisk')}: &nbsp; {(adjusted * 100).toFixed(1)}
        </Text>
      </View>
    );
  };

  const mapRecommendationInfo = (r: Recommendation) => {
    return (
      <View style={styles.section}>
        <Text style={{ fontStyle: 'bold' }}>{r.title}</Text>
        {r.information.map((i) =>
          i.type === DocumentSectionType.link ? (
            <Link src={i.content}> {i.content} </Link>
          ) : (
            <Text> {i.content} </Text>
          ),
        )}
      </View>
    );
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Text style={styles.headerTitle}> {t('patientProfile.pdf.title')} </Text>
        </View>

        <View style={styles.content}>
          <View style={styles.patientHeader}>
            <View style={styles.patientNameContainer}>
              <Text style={styles.patientName}> {`${patient.firstName} ${patient.lastName}`}</Text>
            </View>
            <View style={styles.patientNameSpacer} />
          </View>
          <View style={styles.contentHeader}>
            <Text>
              {t('patientProfile.evaluation.dateLabel', { date: evalData.date.toDateString() })}
            </Text>
            <Text>
              {t('patientProfile.evaluation.risks.cha2ds2vasc', {
                score: evalData.currentRisks.cha2ds2vascScore,
              })}
            </Text>
          </View>

          <View style={styles.evalContent}>
            <View style={styles.column}>
              <Text style={styles.columnTitle}>
                {t('patientProfile.evaluation.risks.individualRiskProfile')}
              </Text>
              {riskSection(
                t('patientProfile.evaluation.risks.mortalityRisk'),
                evalData.currentRisks.mortality,
                evalData.adjustedRisks.mortality,
              )}
              {riskSection(
                t('patientProfile.evaluation.risks.strokeRisk'),
                evalData.currentRisks.stroke,
                evalData.adjustedRisks.stroke,
              )}
              {riskSection(
                t('patientProfile.evaluation.risks.bleedingRisk'),
                evalData.currentRisks.bleeding,
                evalData.adjustedRisks.bleeding,
              )}
            </View>

            <View style={styles.column}>
              <Text style={styles.columnTitle}>
                {t('patientProfile.evaluation.recommendations.heading')}
              </Text>
              {evalData.recommendations.map((r) => (
                <View style={styles.section}>
                  <Text style={{ fontStyle: 'bold' }}> {r.title}</Text>
                  <Text> {r.text} </Text>
                </View>
              ))}
            </View>
          </View>
        </View>
      </Page>

      <Page size="A4" style={styles.page}>
        <Text
          style={{
            fontStyle: 'bold',
            fontSize: '7mm',
            textDecoration: 'underline',
            marginTop: '5mm',
            marginHorizontal: 'auto',
          }}
        >
          {t('patientProfile.pdf.recommendationDetails')}
        </Text>
        {evalData.recommendations.map(mapRecommendationInfo)}
      </Page>
    </Document>
  );
}

export function generateOptiCorPDFReport(
  evalData: EvaluationResult,
  patient: Patient,
): Promise<Blob> {
  Font.register({
    family: 'Open Sans',
    fonts: [
      { src: OpenSansLight, fontStyle: 'light', fontWeight: 300 },
      { src: OpenSans, fontStyle: 'normal', fontWeight: 400 },
      { src: OpenSansBold, fontStyle: 'bold', fontWeight: 700 },
    ],
  });
  Font.register({ family: 'Rounded Elegance', src: RoundedElegance });
  return ReactPDF.pdf(<OptiCorPDFReport evalData={evalData} patient={patient} />).toBlob();
}
