import { Checkbox, CheckboxProps } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import React, { ReactElement } from 'react';
import LabelWithTooltip from './LabelWithTooltip/LabelWithTooltip';
import { Control, Controller, FieldErrors, Path } from 'react-hook-form';

interface CheckboxWithLabelAndTooltipProps<T> {
  name: Path<T>;
  label: string | ReactElement;
  tooltip?: string;
  disabled?: boolean;
  errors?: FieldErrors;
  control: Control<T>;
  onChange?: CheckboxProps['onChange'];
}

export function CheckboxWithLabelAndTooltip<T>(props: CheckboxWithLabelAndTooltipProps<T>) {
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field: { ref, value, ...field } }) => {
        return (
          <FormControlLabel
            control={
              <Checkbox
                // @ts-ignore
                checked={value}
                inputRef={ref}
                {...field}
                onChange={(e, checked) => {
                  field.onChange(e);
                  if (props.onChange) props.onChange(e, checked);
                }}
                color="primary"
                disabled={props.disabled}
              />
            }
            label={<LabelWithTooltip label={props.label} tooltipText={props.tooltip} />}
          />
        );
      }}
    />
  );
}
