import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import { InformationTexts } from './config/InformationTexts';

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    debug: window.location.hostname.startsWith('localhost'),
    backend: {
      loadPath: (languages, namespaces) => {
        if (InformationTexts.some((infoText) => infoText === namespaces[0])) {
          return '/locales/{{lng}}/informationTexts/{{ns}}.json';
        }
        return '/locales/{{lng}}/{{ns}}.json';
      },
    },
    keySeparator: '.',

    lng: 'en',
    fallbackLng: 'en',
    ns: ['forms', 'pages', 'settings', 'termsOfUse'],

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
