import { LOGOUT_URL } from '../config/urlConfiguration';
import { sessionStorageKeys } from '../config/constants';

export default function logout() {
  deleteSessionStorage();
  // @ts-ignore
  window.location = LOGOUT_URL;
}

const shouldBeDeletedOnLogout: Record<keyof typeof sessionStorageKeys, boolean> = {
  recommendationConfig: true,
} as const;

function deleteSessionStorage() {
  const keys = Object.keys(sessionStorageKeys) as (keyof typeof sessionStorageKeys)[];
  for (const key of keys) {
    if (shouldBeDeletedOnLogout[key]) {
      sessionStorage.removeItem(sessionStorageKeys[key]);
    }
  }
}
