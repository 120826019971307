import { AssessmentIdOrLatest } from '../api/report';
import { Recommendation } from '../api/patients';
import { useSessionStorage } from '../hooks/useSessionStorage';
import { sessionStorageKeys } from '../config/constants';

export interface StoredRecommendationConfiguration {
  assessment: AssessmentIdOrLatest;
  patientId: number;
  recommendations: Recommendation[];
}

export function useRecommendationsSessionStorage(
  defaultValue: StoredRecommendationConfiguration,
  reset: boolean = false,
) {
  return useSessionStorage<StoredRecommendationConfiguration>(
    sessionStorageKeys.recommendationConfig,
    defaultValue,
    reset,
  );
}
export function deleteRecommendationsSessionStorage() {
  sessionStorage.removeItem(sessionStorageKeys.recommendationConfig);
}

export function belongsToCorrectAssessment(
  storedRecommendations: StoredRecommendationConfiguration,
  correctValues: Omit<StoredRecommendationConfiguration, 'recommendations'>,
): boolean {
  return (
    Object.keys(correctValues)
      // @ts-ignore
      .map((key) => storedRecommendations[key] === correctValues[key])
      .reduce((previous, current) => previous && current, true)
  );
}
