import { DownloadFormChoicesForm, RecommendationGroupChoice } from './report.types';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { checkBoxChoices, defaultDownloadChoices, disabledCheckboxes } from './report.config';
import {
  Box,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';
import { CheckboxWithLabelAndTooltip } from '../../core/input-components/CheckboxWithLabelAndTooltip';
import LabelWithTooltip from '../../core/input-components/LabelWithTooltip/LabelWithTooltip';
import { Controller } from 'react-hook-form';

export function PdfContentChoicesGridItem({ form }: { form: DownloadFormChoicesForm }) {
  const { t } = useTranslation('downloadPage');
  const listItemSX = { ml: 1 };

  const [recommendationsCheckboxChecked, setRecommendationsCheckboxChecked] = useState<boolean>(
    false,
  );

  const updateSelectAllButton = () => {
    const currentValues = form.getValues();
    if (
      checkBoxChoices.every((fieldName) => currentValues[fieldName]) &&
      recommendationsCheckboxChecked
    ) {
      form.setValue('selectAll', true);
    } else {
      form.setValue('selectAll', false);
    }
  };

  useEffect(updateSelectAllButton, [form, recommendationsCheckboxChecked]);

  const setRecommendationsCheckbox = (
    checked: boolean,
    setOnlyTickedRecommendations: boolean = false,
  ) => {
    if (checked) {
      form.setValue(
        'recommendationGroupChoices',
        setOnlyTickedRecommendations
          ? [RecommendationGroupChoice.tickedRecommendations]
          : (Object.values(RecommendationGroupChoice).filter(
              (el) => !isNaN(Number.parseInt(el.toString())),
            ) as Array<RecommendationGroupChoice>)
              .filter((val) => val !== RecommendationGroupChoice.none)
              .filter((val) => val !== RecommendationGroupChoice.all),
      );
    } else {
      form.setValue(
        'recommendationGroupChoices',
        defaultDownloadChoices.recommendationGroupChoices,
      );
    }
    setRecommendationsCheckboxChecked(checked);
  };

  return (
    <Grid item xs={12} md={5}>
      <List>
        <ListItem disableGutters={true}>
          <CheckboxWithLabelAndTooltip
            name={'selectAll'}
            label={t(`choices.selectAll`)}
            control={form.control}
            disabled={disabledCheckboxes.includes('selectAll')}
            onChange={(e, checked) => {
              checkBoxChoices
                .filter((fieldName) => !disabledCheckboxes.includes(fieldName))
                .forEach((fieldName) => form.setValue(fieldName, checked));
              setRecommendationsCheckbox(checked);
            }}
          />
        </ListItem>
        <Divider />
        {checkBoxChoices.map((field, index) => (
          <React.Fragment key={field}>
            <ListItem disableGutters={true} key={field} sx={listItemSX}>
              <CheckboxWithLabelAndTooltip
                name={field}
                label={t(`choices.${field}`)}
                control={form.control}
                disabled={disabledCheckboxes.includes(field)}
                onChange={updateSelectAllButton}
              />
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
        <ListItem disableGutters={true} sx={listItemSX}>
          <Stack direction={'row'}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={recommendationsCheckboxChecked}
                  onChange={(event, checked) => {
                    setRecommendationsCheckbox(checked, true);
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label={<LabelWithTooltip label={t('choices.recommendationGroup.title')} />}
            />
            <FormControl>
              <Controller
                name={'recommendationGroupChoices'}
                control={form.control}
                render={({ field, fieldState, formState }) => {
                  return (
                    <Select
                      multiple
                      onClose={(event) => {
                        if (
                          form
                            .getValues()
                            .recommendationGroupChoices.includes(RecommendationGroupChoice.none)
                        ) {
                          setRecommendationsCheckbox(false);
                        }
                      }}
                      onChange={({
                        target: { value: newChoices },
                      }: {
                        target: { value: RecommendationGroupChoice[] | string };
                      }) => {
                        if (typeof newChoices === 'string') return;
                        if (
                          newChoices.includes(RecommendationGroupChoice.all) ||
                          newChoices.length >= Object.keys(RecommendationGroupChoice).length / 2 - 2
                        ) {
                          form.setValue(
                            'recommendationGroupChoices',
                            (Object.values(RecommendationGroupChoice).filter(
                              (el) => !isNaN(Number.parseInt(el.toString())),
                            ) as Array<RecommendationGroupChoice>)
                              .filter((val) => val !== RecommendationGroupChoice.none)
                              .filter((val) => val !== RecommendationGroupChoice.all),
                          );
                        } else if (newChoices.length === 0) {
                          form.setValue('recommendationGroupChoices', [
                            RecommendationGroupChoice.none,
                          ]);
                        } else {
                          form.setValue(
                            'recommendationGroupChoices',
                            newChoices.filter((val) => val !== RecommendationGroupChoice.none),
                          );
                        }
                      }}
                      value={field.value}
                      disabled={!recommendationsCheckboxChecked}
                      renderValue={(selected: RecommendationGroupChoice[]) => {
                        if (
                          selected.length >=
                          Object.keys(RecommendationGroupChoice).length / 2 - 2
                        ) {
                          selected = [RecommendationGroupChoice.all];
                        }
                        return (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected
                              .sort((a, b) => a - b)
                              .map((value) => (
                                <Chip
                                  key={value}
                                  label={t(
                                    `choices.recommendationGroup.chips.${RecommendationGroupChoice[value]}`,
                                  )}
                                />
                              ))}
                          </Box>
                        );
                      }}
                    >
                      {(Object.keys(RecommendationGroupChoice).filter((el) =>
                        isNaN(Number.parseInt(el)),
                      ) as Array<keyof typeof RecommendationGroupChoice>)
                        .filter(
                          (val) =>
                            RecommendationGroupChoice[val] !== RecommendationGroupChoice.none,
                        )
                        .map((key) => (
                          <MenuItem
                            key={key}
                            value={RecommendationGroupChoice[key]}
                            sx={{
                              ml: key === 'all' ? 0 : 1,
                              cursor:
                                key === 'all' &&
                                field.value.length >=
                                  Object.keys(RecommendationGroupChoice).length / 2 - 2
                                  ? 'not-allowed'
                                  : 'pointer',
                            }}
                            selected={field.value.includes(RecommendationGroupChoice[key])}
                          >
                            {key !== 'all' && (
                              <Checkbox
                                checked={field.value.includes(RecommendationGroupChoice[key])}
                              />
                            )}
                            {t(
                              `choices.recommendationGroup.menu.${
                                RecommendationGroupChoice[RecommendationGroupChoice[key]]
                              }`,
                            )}
                          </MenuItem>
                        ))}
                    </Select>
                  );
                }}
              ></Controller>
            </FormControl>
          </Stack>
        </ListItem>
      </List>
    </Grid>
  );
}
