import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import React from 'react';
import { CheckboxWithLabelAndTooltip } from '../../../../core/input-components/CheckboxWithLabelAndTooltip';
import { AssessmentFormType } from '../../AssessmentForm';

const rhythmControlName = 'rhythmControlDesired';
export function TreatmentEditable({ form }: { form: AssessmentFormType }) {
  const { t } = useTranslation('forms');

  return (
    <div style={{ pageBreakInside: 'avoid' }}>
      <Grid container spacing={5}>
        <Grid item xs={4}>
          <CheckboxWithLabelAndTooltip
            name={rhythmControlName}
            label={t(`treatment.${rhythmControlName}.label`)}
            tooltip={t(`treatment.${rhythmControlName}.tooltip`)}
            control={form.control}
          />
        </Grid>
      </Grid>
    </div>
  );
}
