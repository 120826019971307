import { Typography } from '@mui/material';
import { useOptions } from '../Options/useOptions';
import useWithLoadingAndErrorIndication from '../../../../../hooks/loadingIndication/useWithLoadingAndErrorIndication';
import { Diagnosis, DiagnosisComponent, useDiagnosisComponent } from '../../../../../api/patients';
import { useSettings } from '../../../../../hooks/useSettings';
import React from 'react';
import {
  CartesianGrid,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  XAxis,
  YAxis,
  ZAxis,
} from 'recharts';
import { NoTrendData } from '../NoTrendData';
import { EmptyPlaceholderPage } from '../../../../core/EmptyPlaceholderPage';
import { filterDataByLatestOnSameDate } from './RiskTimeSeries';
import { diagnosisColorScheme } from './ColorSchemes';
import { useTranslation } from 'react-i18next';

interface ShowDiagnosis extends Diagnosis {
  [key: string]: boolean;
}

export function DiagnosisTimeSeries({ patientId }: { patientId: number }) {
  const { optionValues } = useOptions();
  const { t } = useTranslation('pages');
  const { data: diagnosisComponents } = useWithLoadingAndErrorIndication(
    useDiagnosisComponent(patientId, optionValues.timespan),
  );

  const showDiagnosisData: ShowDiagnosis = {
    atrialFibrillation: optionValues.atrialFibrillation,
    carotidArteryDisease: optionValues.carotidArteryDisease,
    chronicKidneyDisease: optionValues.chronicKidneyDisease,
    coronaryArteryDisease: optionValues.coronaryArteryDisease,
    dementia: optionValues.dementia,
    diabetes: optionValues.diabetes,
    heartFailure: optionValues.heartFailure,
    historyOfBleeding: optionValues.historyOfBleeding,
    historyOfStroke: optionValues.historyOfStroke,
    hypertension: optionValues.hypertension,
    peripheralArteryDisease: optionValues.peripheralArteryDisease,
    thromboembolism: optionValues.thromboembolism,
    transientIschemicAttack: optionValues.transientIschemicAttack,
  };

  const { helpers } = useSettings();
  if (!diagnosisComponents) {
    return <EmptyPlaceholderPage />;
  }
  if (diagnosisComponents.length <= 1) {
    return <NoTrendData />;
  }

  const diagnosisKeys = getUsedDiagnosisKeys(diagnosisComponents);
  const diagnosisData = filterDataByLatestOnSameDate(
    diagnosisComponents,
    optionValues.mostRecentLeft,
  ).map((d) =>
    convertDiagnosisComponentToData(
      d,
      optionValues.mostRecentLeft,
      showDiagnosisData,
      diagnosisKeys,
    ),
  );

  const maxValue = diagnosisKeys.length + 2;
  const keys = Object.keys(showDiagnosisData);

  return (
    <>
      <Typography sx={{ paddingLeft: 6 }}>
        {t('patientProfile.trend.diagnosisView.headline')}
      </Typography>
      <ResponsiveContainer width="100%" height={500}>
        <ScatterChart
          width={500}
          height={400}
          data={diagnosisData}
          margin={{
            top: 30,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <ZAxis range={[150, 151]} />
          <XAxis
            dataKey="time"
            domain={['auto', 'auto']}
            padding={{ left: 10, right: 10 }}
            tickFormatter={(unixTime) =>
              helpers.formatDateAbbreviated(new Date(Math.abs(unixTime)))
            }
            ticks={diagnosisData.map((item) => item.time)}
            type={'number'}
          />
          <YAxis domain={[0, maxValue]} axisLine={false} tick={false} />
          {keys.map((key) => (
            <Scatter
              dataKey={key}
              type={'number'}
              shape={'circle'}
              fill={diagnosisColorScheme[key as keyof Diagnosis]}
            />
          ))}
        </ScatterChart>
      </ResponsiveContainer>
    </>
  );
}

function convertDiagnosisComponentToData(
  diagnosisComponent: DiagnosisComponent,
  mostRecentLeft: boolean,
  showDiagnosis: ShowDiagnosis,
  diagnosisKeys: string[],
) {
  let index = 1;
  let result: any = {
    time: mostRecentLeft
      ? diagnosisComponent.date.getTime() * -1
      : diagnosisComponent.date.getTime(),
  };

  for (const key of diagnosisKeys) {
    index++;
    if (showDiagnosis[key] && diagnosisComponent[key as keyof Diagnosis]) {
      result[key] = index;
    }
  }
  return result;
}

export function getUsedDiagnosisKeys(diagnoses: Diagnosis[]): string[] {
  const trueKeys = new Set<string>();

  for (const diagnosis of diagnoses) {
    for (const key in diagnosis) {
      if (
        diagnosis[key as keyof Diagnosis] &&
        typeof diagnosis[key as keyof Diagnosis] === 'boolean'
      ) {
        trueKeys.add(key);
      }
    }
  }

  return Array.from(trueKeys);
}
