import { Card, CardActionArea, Grid, Stack, Typography, useTheme } from '@mui/material';
import { NavigateNext } from '@mui/icons-material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DATE_BOX_HEIGHT, DATE_BOX_MARGIN_TOP } from './Calendar';

interface NextYearProp {
  yearRange: number;
  setYearRange: (yearRange: number) => void;
}

function nextYearOnClick(yearRange: number, setYearRange: (yearRange: number) => void) {
  setYearRange(yearRange + 1);
}
export function NextYearButton({ yearRange, setYearRange }: NextYearProp) {
  const theme = useTheme();
  const { t } = useTranslation('pages');
  const primaryColor = theme.palette.primary.main;

  return (
    <Grid container direction={'column'} item xs>
      <Grid item xs>
        <Card
          sx={{
            height: DATE_BOX_HEIGHT,
            width: '6rem',
            mt: DATE_BOX_MARGIN_TOP,
            border: 'none',
            boxShadow: 'none',
            backgroundColor: 'transparent',
          }}
        >
          <CardActionArea onClick={() => nextYearOnClick(yearRange, setYearRange)}>
            <Stack direction={'row'} color={primaryColor}>
              <Typography>{t('patientProfile.visits.nextYearButton')}</Typography>
              <NavigateNext />
            </Stack>
          </CardActionArea>
        </Card>
      </Grid>
    </Grid>
  );
}
