import { useSnackbar } from './useSnackbar';

export default function useWithErrorsInSnackbar<T extends any[], U>(
  func: (...args: T) => Promise<U>,
): (...args: T) => Promise<U> {
  const { showMessage, removeAllMessages } = useSnackbar();

  return async (...args: T) =>
    new Promise((resolve, reject) => {
      func(...args)
        .then((value) => {
          removeAllMessages();
          resolve(value);
        })
        .catch((error) => {
          showMessage(error.message, 'error');
          reject(error);
        });
    });
}
