import {
  convertHeightImperialToMetricUnits,
  convertHeightMetricToImperialUnits,
  convertImperialToMetricUnits,
  convertWeightImperialToMetricUnits,
  convertWeightMetricToImperialUnits,
} from '../../../../utils/units';
import { useSettings, useTriggerOnSettingsChange } from '../../../../hooks/useSettings';
import { Units } from '../../../../context/SettingsProvider';
import { scrollToTop } from '../../../../utils/general';
import { useCallback, useEffect } from 'react';
import { AssessmentFormType } from '../AssessmentForm';
import { AssessmentParameters } from '../../../../types/assessments';

export function useSubmitFunctionWithCorrectUnits(
  form: AssessmentFormType,
  units: Units,
  onSubmit: (props: AssessmentParameters) => void,
) {
  return useCallback(() => {
    let submitHandler;
    switch (units) {
      case Units.Metric:
        submitHandler = form.handleSubmit(onSubmit);
        break;
      case Units.Imperial:
        submitHandler = form.handleSubmit((params) => {
          onSubmit(convertImperialToMetricUnits(params));
        });
    }
    submitHandler().then(scrollToTop);
  }, [form, onSubmit, units]);
}

export function useCorrectFormFieldsForSettings(form: AssessmentFormType) {
  useTriggerOnSettingsChange((newSettings) => {
    switch (newSettings.units) {
      case Units.Metric:
        convertFieldsToMetric(form);
        break;
      case Units.Imperial:
        convertFieldsToImperial(form);
        break;
    }
    form.trigger().then(scrollToTop);
  });

  const { settings } = useSettings();

  //on first mount units are metric so:
  useEffect(() => {
    switch (settings.units) {
      case Units.Metric:
        form.unregister(['feet', 'inches']);
        break;
      case Units.Imperial:
        convertFieldsToImperial(form);
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

function convertFieldsToMetric(form: AssessmentFormType) {
  const values = form.getValues();
  form.setValue('weight', convertWeightImperialToMetricUnits(values['weight']));
  form.setValue('height', convertHeightImperialToMetricUnits(values['feet'], values['inches']));
  form.unregister(['inches', 'feet']);
}

function convertFieldsToImperial(form: AssessmentFormType) {
  const values = form.getValues();
  form.setValue('weight', convertWeightMetricToImperialUnits(values['weight']));
  const { feet, inches } = convertHeightMetricToImperialUnits(values['height']);
  form.setValue('feet', feet);
  form.setValue('inches', inches);
  form.unregister(['height']);
}
