import React, { createContext, PropsWithChildren, useCallback, useContext, useState } from 'react';
import { maximumRegisteredLoadingElements } from '../config/constants';
import { Backdrop, CircularProgress } from '@mui/material';

export function LoadingIndicatorProvider({ children }: PropsWithChildren<{}>) {
  const [numberOfLoaders, setNumberOfLoaders] = useState<number>(0);

  const startLoading = useCallback(() => {
    setNumberOfLoaders((previous) => {
      checkForValidLoadingElements(previous + 1);
      return previous + 1;
    });
  }, []);

  const stopLoading = useCallback(() => {
    setNumberOfLoaders((previous) => {
      checkForValidLoadingElements(previous - 1);
      return previous - 1;
    });
  }, []);

  return (
    <LoadingIndicatorContext.Provider
      value={{ startLoading, stopLoading, loadingElements: numberOfLoaders }}
    >
      <LoadingBackdrop />
      {children}
    </LoadingIndicatorContext.Provider>
  );
}

interface LoadingIndicatorState {
  startLoading: () => void;
  stopLoading: () => void;
  loadingElements: number;
}

export const LoadingIndicatorContext = createContext<LoadingIndicatorState>({
  startLoading: () => {},
  stopLoading: () => {},
  loadingElements: 0,
});

function LoadingBackdrop() {
  const { loadingElements } = useContext(LoadingIndicatorContext);

  return (
    <Backdrop open={loadingElements > 0} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <CircularProgress color={'primary'} />
    </Backdrop>
  );
}

function checkForValidLoadingElements(numberOfLoaders: number) {
  if (numberOfLoaders > maximumRegisteredLoadingElements) {
    // => called startLoading more often than stop Loading,
    // or too many elements load simultaneously
    throw Error(`Number Of Loading Elements is More than ${maximumRegisteredLoadingElements}`);
  } else if (numberOfLoaders < 0) {
    // => called stopLoading more often than startLoading
    throw Error('Number Of Loading Elements is Less than 0');
  }
}
