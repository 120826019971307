/*
Input must be in the format DD.MM.YYYY or MM/DD/YYYY. Date and month can also be only one character long.
 */
export function convertLocalToIsoFormat(date: string): string {
  if (date.includes('.')) {
    const parts = date.split('.');
    return `${parts[2]}-${parts[1].padStart(2, '0')}-${parts[0].padStart(2, '0')}`;
  } else {
    const parts = date.split('/');
    return `${parts[2]}-${parts[0].padStart(2, '0')}-${parts[1].padStart(2, '0')}`;
  }
}

/*
Copied from server/utils/dates.ts, there you can also find test cases.
 */
export function getAgeAtDate(birthday: Date, currentDate: Date): number {
  if (
    currentDate.getMonth() > birthday.getMonth() ||
    (currentDate.getMonth() === birthday.getMonth() && currentDate.getDate() >= birthday.getDate())
  )
    return currentDate.getFullYear() - birthday.getFullYear();
  return currentDate.getFullYear() - birthday.getFullYear() - 1;
}
