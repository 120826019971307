import Grid from '@mui/material/Grid';
import { StyledTextField } from '../../../../core/input-components/StyledTextField';
import { useTranslation } from 'react-i18next';
import { FieldErrors, Path, UseFormRegister } from 'react-hook-form';
import { MinMaxType, ruleHelper } from '../../logic/rule-helper';

interface Props<T> {
  register: UseFormRegister<T>;
  errors: FieldErrors;
}

const WEIGHT_FIELD_NAME = 'weight' as const;
const HEIGHT_FIELD_NAME = 'height' as const;

const ALLOWED_VALUES: MinMaxType<typeof WEIGHT_FIELD_NAME | typeof HEIGHT_FIELD_NAME> = {
  [WEIGHT_FIELD_NAME]: {
    min: 30,
    max: 400,
  },
  [HEIGHT_FIELD_NAME]: {
    min: 100,
    max: 240,
  },
};

export function MetricWeightHeight<T>(props: Props<T>) {
  const { t } = useTranslation('forms');

  return (
    <Grid container columnSpacing={3} rowSpacing={1}>
      <Grid item xs={6}>
        <StyledTextField
          name={WEIGHT_FIELD_NAME}
          type="number"
          label={t('vitals.weight')}
          register={props.register(
            WEIGHT_FIELD_NAME as Path<T>,
            ruleHelper(t, ALLOWED_VALUES, WEIGHT_FIELD_NAME),
          )}
          errors={props.errors}
          adornment="kg"
        />
      </Grid>
      <Grid item xs={6}>
        <StyledTextField
          name={HEIGHT_FIELD_NAME}
          type="number"
          label={t('vitals.height')}
          register={props.register(
            HEIGHT_FIELD_NAME as Path<T>,
            ruleHelper(t, ALLOWED_VALUES, HEIGHT_FIELD_NAME),
          )}
          errors={props.errors}
          adornment="cm"
        />
      </Grid>
    </Grid>
  );
}
