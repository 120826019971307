import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { UseQueryResult } from 'react-query';
import useLoadingIndicator from './useLoadingIndicator';

export default function useQueryWithLoadingIndication<T>(
  queryResult: UseQueryResult<T, AxiosError>,
): {
  isPreviousData: boolean;
  data: T | undefined;
} {
  const [wasLoadingAtLastRender, setWasLoadingAtLastRender] = useState(false);
  const [stopLoading, startLoading] = useLoadingIndicator();

  useEffect(() => {
    if (queryResult.isLoading) {
      if (!wasLoadingAtLastRender) {
        setWasLoadingAtLastRender(true);
        startLoading();
      }
    } else {
      if (wasLoadingAtLastRender) {
        setWasLoadingAtLastRender(false);
        stopLoading();
      }
    }
  }, [queryResult.isLoading, startLoading, stopLoading, wasLoadingAtLastRender]);

  return {
    data: queryResult.data,
    isPreviousData: queryResult.isPreviousData,
  };
}
