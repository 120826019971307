import { useTranslation } from 'react-i18next';
import {
  useNavigateToDownloadReportPage,
  useNavigateToNewWebAssessment,
  useNavigateToPatientList,
} from '../../../config/urlPaths';
import { Button, Stack, Typography } from '@mui/material';
import React from 'react';
import { patientProfileButtonHeight } from '../../../config/constants';
import { AssessmentIdOrLatest } from '../../../api/report';
import { NewAssessmentIcon } from '../../core/customIcons/NewAssessmentIcon';

export function SaveAndCloseButton({
  onSaveAndClose,
  fullWidth = true,
}: {
  onSaveAndClose?: () => void;
  fullWidth?: boolean;
}) {
  const { t } = useTranslation('pages');

  const defaultSaveAndClose = useNavigateToPatientList();
  return (
    <Button
      fullWidth={fullWidth}
      variant="outlined"
      color="primary"
      sx={{ border: '2px solid', height: patientProfileButtonHeight }}
      onClick={() => {
        onSaveAndClose ? onSaveAndClose() : defaultSaveAndClose();
      }}
    >
      <Typography variant={'subtitle2'} color={'action.active'} fontWeight={'bold'}>
        {t('patientProfile.evaluation.saveAndCloseButton')}
      </Typography>
    </Button>
  );
}
export function DownloadReportButton({
  patientId,
  assessmentId,
}: {
  patientId: string;
  assessmentId: AssessmentIdOrLatest;
}) {
  const { t } = useTranslation('pages');

  const toDownload = useNavigateToDownloadReportPage();
  return (
    <Button
      fullWidth={true}
      variant="contained"
      sx={{ boxShadow: 'none', height: patientProfileButtonHeight }}
      color="primary"
      onClick={() => {
        toDownload(patientId, assessmentId);
      }}
    >
      <Typography variant={'subtitle2'} color={'action.active'} fontWeight={'bold'}>
        {t('patientProfile.evaluation.downloadLabel')}
      </Typography>
    </Button>
  );
}

export function CreateNewAssessmentButton({ patientId }: { patientId: number }) {
  const { t } = useTranslation('pages');
  const toNewAssessment = useNavigateToNewWebAssessment();
  return (
    <Button
      variant="contained"
      sx={{ boxShadow: 'none', height: patientProfileButtonHeight }}
      color="primary"
      onClick={() => {
        toNewAssessment(patientId);
      }}
    >
      <Stack direction={'row'}>
        <NewAssessmentIcon color={'action'} />
        <Typography
          variant={'subtitle2'}
          color={'action.active'}
          fontWeight={'bold'}
          sx={{ pt: 1, ml: 1 }}
        >
          {t('patientProfile.visits.createNewAssessmentLabel')}
        </Typography>
      </Stack>
    </Button>
  );
}
