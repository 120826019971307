import React from 'react';
import { DocumentSection, DocumentSectionType } from './document.types';
import {
  DocumentBoldParagraph,
  DocumentImage,
  DocumentLink,
  DocumentNewLine,
  DocumentParagraph,
  DocumentText,
  DocumentTitle,
} from '../DocumentParts';

export function getSectionComponent(section: DocumentSection, key: number) {
  switch (section.type) {
    case DocumentSectionType.title: {
      return <DocumentTitle text={section.content} key={key} />;
    }
    case DocumentSectionType.text: {
      return <DocumentText text={section.content} key={key} />;
    }
    case DocumentSectionType.paragraph: {
      return <DocumentParagraph text={section.content} key={key} />;
    }
    case DocumentSectionType.boldParagraph: {
      return <DocumentBoldParagraph text={section.content} key={key} />;
    }
    case DocumentSectionType.link: {
      return <DocumentLink text={section.content} key={key} />;
    }
    case DocumentSectionType.image: {
      return <DocumentImage link={section.content} key={key} />;
    }
    case DocumentSectionType.newLine: {
      return <DocumentNewLine marginTop={10} />;
    }
  }
}
