import { AssessmentDifference } from '../../../../../types/assessments';
import { useTranslation } from 'react-i18next';
import { getTooltipAssessmentDifference } from './customTooltip.logic';
import { Card, Stack, Tooltip, Typography } from '@mui/material';
import React from 'react';

export function AssessmentDifferenceComponent({
  assessmentDifference,
}: {
  assessmentDifference: AssessmentDifference;
}) {
  const { t } = useTranslation('pages');
  const tooltipAssessmentDifference = getTooltipAssessmentDifference(assessmentDifference, t);

  if (Object.keys(tooltipAssessmentDifference).length === 0) {
    return null;
  }

  return (
    <Card
      sx={{
        padding: 1,
        marginTop: 1,
        maxWidth: '20rem',
        backgroundColor: '#3b3b3b',
      }}
    >
      {Object.entries(tooltipAssessmentDifference).map(([key, value]) => {
        if (value === undefined) {
          return null;
        }
        const keyTooltip = t(`patientProfile.trend.tooltip.key.${key}.tooltip`);

        if (keyTooltip) {
          return (
            <Tooltip title={keyTooltip} placement={'left-start'} arrow key={key}>
              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'flex-start'}
                key={key}
              >
                <Typography color={'secondary.dark'}>
                  {t(`patientProfile.trend.tooltip.key.${key}.label`)}
                </Typography>
                {value}
              </Stack>
            </Tooltip>
          );
        }

        return (
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'flex-start'}
            key={key}
          >
            <Typography color={'secondary.dark'}>
              {t(`patientProfile.trend.tooltip.key.${key}.label`)}
            </Typography>
            {value}
          </Stack>
        );
      })}
    </Card>
  );
}
