import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import React, { PropsWithChildren } from 'react';
import { DialogContent } from '@mui/material';

interface DialogTitleProps {
  title: string;
  onClose: () => void;
}

const DialogTitle = (props: DialogTitleProps) => (
  <MuiDialogTitle
    sx={{
      m: 0,
      p: 2,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontSize: '2.1rem',
    }}
  >
    {props.title}
    {props.onClose ? (
      <IconButton
        aria-label="close"
        sx={{ color: (theme) => theme.palette.grey[500] }}
        onClick={props.onClose}
        size="large"
      >
        <CloseIcon />
      </IconButton>
    ) : null}
  </MuiDialogTitle>
);

interface StyledPopupProps {
  title: string;
  open: boolean;
  handleClose: () => void;
  linkText?: string;
  maxWidthDisabled?: boolean;
}

export function StyledPopup(props: PropsWithChildren<StyledPopupProps>) {
  return (
    <Dialog
      onClose={props.handleClose}
      open={props.open}
      maxWidth={props.maxWidthDisabled ? false : 'md'}
    >
      <DialogTitle onClose={props.handleClose} title={props.title} />
      <DialogContent dividers sx={{ p: 2 }}>
        {props.children}
      </DialogContent>
    </Dialog>
  );
}
