import { adaptV4Theme, createTheme } from '@mui/material';

declare module '@mui/material/styles' {
  interface Palette {
    neutral: Palette['primary'];
  }
  interface PaletteOptions {
    neutral: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    neutral: true;
  }
}

export const theme = createTheme(
  adaptV4Theme({
    palette: {
      primary: {
        main: '#39E991', // Shamrock
        dark: '#32A370',
      },
      secondary: {
        main: '#C20D0F',
        light: '#D9DCDE',
        dark: '#A6AEAB',
      },
      background: {
        default: 'whitesmoke',
      },
      neutral: {
        main: '#000',
        light: '#282828',
      },
      action: {
        active: '#303030', // a random dark grey
        hover: '#f1f1f1',
      },
    },
    typography: {
      fontFamily: '"Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
      h6: {
        fontWeight: 600,
        fontSize: '1.00rem',
        fontFamily: 'Montserrat',
        marginBottom: '15px',
      },
      h5: {
        fontWeight: 600,
        fontSize: '1.25rem',
        fontFamily: 'Montserrat',
      },
      h4: {
        fontWeight: 600,
        fontSize: '1.5rem',
        fontFamily: 'Montserrat',
      },
      h3: {
        fontFamily: 'Montserrat',
        fontSize: '1.75rem',
      },
    },
    overrides: {
      MuiFormLabel: {
        root: {
          '&$focused': {
            color: 'black',
          },
        },
      },
      MuiButton: {
        text: {
          textTransform: 'none',
        },
        contained: {
          fontWeight: 'bold',
        },
      },
    },
  }),
);
