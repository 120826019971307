import React, { useEffect } from 'react';

import MedicalHistoryEditable from './formPartials/medicalHistory/MedicalHistory.editable';
import { MedicationsEditable } from './formPartials/medications/Medications.editable';
import { ConsentSubmit } from './formPartials/confirmation/ConsentSubmit';
import { useResetVitals, vitalKeys } from './formPartials/vitals/vitals.logic';
import { TreatmentEditable } from './formPartials/treatment/Treatment.editable';
import AssessmentCard from './subComponents/AssessmentCard/AssessmentCard';
import { useTranslation } from 'react-i18next';
import { VitalsEditable } from './formPartials/vitals/Vitals.editable';
import { VitalsNonEditable } from './formPartials/vitals/Vitals.nonEditable';
import { useCorrectFormFieldsForSettings } from './logic/correctUnits';
import { useForm, UseFormReturn } from 'react-hook-form';
import { DEFAULT_ASSESSMENT_VALUES_METRIC } from '../../../config/defaultValues';
import MedicalHistoryNonEditable from './formPartials/medicalHistory/MedicalHistory.nonEditable';
import {
  medicalHistoryKeys,
  resetMedicalHistory,
} from './formPartials/medicalHistory/medicalHistory.logic';
import MedicationsNonEditable from './formPartials/medications/Medications.nonEditable';
import { medicationKeys, resetMedication } from './formPartials/medications/medications.logic';
import TreatmentNonEditable from './formPartials/treatment/Treatment.nonEditable';
import { resetTreatment, treatmentKeys } from './formPartials/treatment/treatment.logic';
import { Grid } from '@mui/material';
import { OptionalParametersEditable } from './formPartials/optionalParameters/OptionalParameters.editable';
import { OptionalParametersNonEditable } from './formPartials/optionalParameters/OptionalParameters.nonEditable';
import {
  optionalParameterKeys,
  resetOptionalParameters,
} from './formPartials/optionalParameters/optionalParameters.logic';
import {
  AssessmentParameters,
  ImperialAssessmentParameters,
  OralAnticoagulant,
} from '../../../types/assessments';
import { DateEditable } from './formPartials/date/DateEditable';
import { DateNonEditable } from './formPartials/date/DateNonEditable';
import { useSettings } from '../../../hooks/useSettings';
import { DevMode } from '../../../context/SettingsProvider';

interface AssessmentFormProps {
  onSubmit: (params: AssessmentParameters) => void;
  prefilledValues?: Partial<AssessmentParameters>;
}

export type FormParameter = AssessmentParameters & ImperialAssessmentParameters;
export type AssessmentFormType = UseFormReturn<FormParameter>;

export function AssessmentForm(props: AssessmentFormProps) {
  const form: AssessmentFormType = useForm<FormParameter>({
    mode: 'onBlur',
    defaultValues: { ...DEFAULT_ASSESSMENT_VALUES_METRIC, ...props.prefilledValues },
  });

  const { t } = useTranslation(['pages', 'forms']);
  const { settings } = useSettings();
  const resetVitals = useResetVitals(form, props.prefilledValues);

  useCorrectFormFieldsForSettings(form);

  const OAC = form.watch('oralAnticoagulant');
  const showContraindicationForOAC = OAC === OralAnticoagulant.NONE;
  const showDiabetesMedication = form.watch('diabetes');

  useEffect(() => {
    if (!showContraindicationForOAC) {
      form.setValue(
        'contraindicationForOAC',
        DEFAULT_ASSESSMENT_VALUES_METRIC.contraindicationForOAC,
      );
    }
    if (!showDiabetesMedication) {
      form.setValue('diabetesMedication', DEFAULT_ASSESSMENT_VALUES_METRIC.diabetesMedication);
    }
  }, [form, showContraindicationForOAC, showDiabetesMedication]);

  return (
    <>
      <Grid container direction={'row'} sx={{ width: '100%', pr: '1rem', pb: '1rem' }} spacing={2}>
        {settings.devMode === DevMode.Enabled && (
          <Grid item sm={12} sx={{ width: '100%' }}>
            <AssessmentCard
              title={'Date'}
              notEditableChildren={<DateNonEditable form={form} />}
              editableChildren={<DateEditable form={form} />}
              onAbortEdit={() => {}}
              form={form}
              inputValues={['date']}
              sx={{ height: '100%', width: '100%', pb: 0 }}
            />
          </Grid>
        )}
        <Grid item sm={12} md={5.5} sx={{ width: '100%' }}>
          <AssessmentCard
            title={t('pages:assessment.headings.vitals')}
            editableChildren={<VitalsEditable form={form} />}
            notEditableChildren={<VitalsNonEditable form={form} />}
            onAbortEdit={resetVitals}
            form={form}
            inputValues={vitalKeys}
            sx={{ height: '100%', width: '100%', pb: 0 }}
          />
        </Grid>
        <Grid item sm={12} md={6.5} sx={{ width: '100%' }}>
          <AssessmentCard
            title={t('pages:assessment.headings.optionalParameters')}
            editableChildren={<OptionalParametersEditable form={form} />}
            notEditableChildren={<OptionalParametersNonEditable form={form} />}
            onAbortEdit={() => resetOptionalParameters(form, props.prefilledValues)}
            form={form}
            inputValues={optionalParameterKeys}
            sx={{ height: '100%', width: '100%', pb: 0 }}
          />
        </Grid>
      </Grid>
      <AssessmentCard
        title={t('forms:medicalHistory.heading')}
        editableChildren={<MedicalHistoryEditable form={form} />}
        notEditableChildren={<MedicalHistoryNonEditable form={form} />}
        onAbortEdit={() => resetMedicalHistory(form, props.prefilledValues)}
        form={form}
        inputValues={medicalHistoryKeys}
      />
      <AssessmentCard
        title={t('forms:medications.heading')}
        editableChildren={
          <MedicationsEditable
            form={form}
            showDiabetesMedication={showDiabetesMedication}
            showContraindicationForOAC={showContraindicationForOAC}
          />
        }
        notEditableChildren={
          <MedicationsNonEditable
            form={form}
            showDiabetesMedication={showDiabetesMedication}
            showContraindicationForOAC={showContraindicationForOAC}
          />
        }
        onAbortEdit={() => resetMedication(form, props.prefilledValues)}
        form={form}
        inputValues={medicationKeys}
      />
      <AssessmentCard
        title={t('forms:treatment.heading')}
        editableChildren={<TreatmentEditable form={form} />}
        notEditableChildren={<TreatmentNonEditable form={form} />}
        onAbortEdit={() => resetTreatment(form, props.prefilledValues)}
        form={form}
        inputValues={treatmentKeys}
      />

      <ConsentSubmit form={form} submitHandler={props.onSubmit} />
    </>
  );
}
