export enum OralAnticoagulant {
  NONE = 'none',
  NOAC = 'noac',
  VKA = 'vka',
}

export enum DiabetesMedication {
  NONE = 'none',
  PROVEN_GLP1RA = 'proven_glp1ra',
  OTHER_GLP1RA = 'other_glp1ra',
  SGLT2 = 'sglt2',
}

export enum CCS_SAF_Score {
  Asymptomatic = 0,
  Minimal = 1,
  Minor = 2,
  Moderate = 3,
  Severe = 4,
}

export enum AntiArrhythmicMedication_Class_1or3 {
  NotTried = 'not_tried',
  TriedInPast = 'tried_in_past',
  CurrentlyOnIt = 'currently_on_it',
}

export enum ContraindicationForOAC {
  None = 'none',
  LongTerm = 'long_term',
  Permanent = 'permanent',
}

export type Vitals = {
  weight: number | null;
  height: number | null;
  pulse: number | null;
  systolicBloodPressure: number;
  diastolicBloodPressure: number | null;
};

export type OptionalParameters = {
  LFEjectionFraction: number | null;
  LADiameter: number | null;
  eGFR: number | null;
};

export type MedicalHistory = {
  CCS_SAF_score: CCS_SAF_Score;
  atrialFibrillation: boolean;
  coronaryArteryDisease: boolean;
  heartFailure: boolean;
  carotidArteryDisease: boolean;
  peripheralArteryDisease: boolean;
  historyOfStroke: boolean;
  transientIschemicAttack: boolean;
  thromboembolism: boolean;
  historyOfBleeding: boolean;
  chronicKidneyDisease: boolean | null;
  diabetes: boolean;
  hypertension: boolean;
  currentSmoker: boolean | null;
  dementia: boolean;
};

export type Treatment = {
  rhythmControlDesired: boolean;
};

export type Medication = {
  antiArrhythmicMedication_Class1or3: AntiArrhythmicMedication_Class_1or3;
  contraindicationForOAC: ContraindicationForOAC;
  antiplateletMedication: boolean;
  oralAnticoagulant: OralAnticoagulant;
  diabetesMedication: DiabetesMedication;
};

export interface AssessmentParameters
  extends Vitals,
    OptionalParameters,
    MedicalHistory,
    Treatment,
    Medication {
  date?: string;
}

export interface Assessment extends AssessmentParameters {
  id: number;
  patientId: number;
  createdAt: Date;
}

export interface AssessmentDifference
  extends VitalDifference,
    OptionalParameterDifference,
    MedicalHistoryDifference,
    MedicationDifference,
    TreatmentDifference {
  [key: string]: any;
}

export interface VitalDifference {
  bmi?: number;
  pulse?: number;
  systolicBloodPressure?: number;
  diastolicBloodPressure?: number;
}

export interface MedicalHistoryDifference {
  CCS_SAF_score?: number;
  atrialFibrillation?: boolean;
  coronaryArteryDisease?: boolean;
  heartFailure?: boolean;
  carotidArteryDisease?: boolean;
  peripheralArteryDisease?: boolean;
  historyOfStroke?: boolean;
  transientIschemicAttack?: boolean;
  thromboembolism?: boolean;
  historyOfBleeding?: boolean;
  chronicKidneyDisease?: boolean | null;
  diabetes?: boolean;
  hypertension?: boolean;
  currentSmoker?: boolean | null;
  dementia?: boolean;
}

export interface MedicationDifference {
  antiArrhythmicMedication_Class1or3?: AntiArrhythmicMedication_Class_1or3;
  contraindicationForOAC?: ContraindicationForOAC;
  antiplateletMedication?: boolean;
  oralAnticoagulant?: OralAnticoagulant;
  diabetesMedication?: DiabetesMedication;
}

export interface OptionalParameterDifference {
  LFEjectionFraction?: number;
  LADiameter?: number;
  eGFR?: number;
}

export interface TreatmentDifference {
  rhythmControlDesired?: boolean;
}

export interface ImperialVitals extends Omit<Vitals, 'height'> {
  feet: number | null;
  inches: number | null;
}

export interface ImperialAssessmentParameters
  extends Omit<AssessmentParameters, keyof Vitals>,
    ImperialVitals {}
