import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export function NoTrendData() {
  const { t } = useTranslation('pages');
  return (
    <>
      <Typography variant={'h4'} align={'center'} sx={{ mt: 1 }}>
        {t('patientProfile.trend.noTrendDataLabel.noDataAvailableLabel')}
      </Typography>
      <Typography align={'center'}>
        {t('patientProfile.trend.noTrendDataLabel.patientNeedsMoreThanOneAssessmentLabel')}
      </Typography>
    </>
  );
}
