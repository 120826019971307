import { VisitsProps } from './Visits';
import { Months } from './Months';
import { Grid } from '@mui/material';
import { Years } from './Years';
import { useState } from 'react';
import { NextYearButton } from './NextYearButton';
import useWithLoadingAndErrorIndication from '../../../hooks/loadingIndication/useWithLoadingAndErrorIndication';
import { useAssessments } from '../../../api/patients';
import { EmptyPlaceholderPage } from '../../core/EmptyPlaceholderPage';

export const DATE_BOX_HEIGHT = '2rem';
export const DATE_BOX_MARGIN_TOP = 0.7;
export const DATE_BOX_MARGIN_RIGHT = 0.7;

export function Calendar({ patient }: VisitsProps) {
  const [yearRange, setYearRange] = useState(0);
  const { data: assessments } = useWithLoadingAndErrorIndication(useAssessments(patient.id));
  if (!assessments) {
    return <EmptyPlaceholderPage />;
  }
  return (
    <Grid container sx={{ mt: 1 }}>
      <Months yearRange={yearRange} setYearRange={setYearRange} />
      <Years patient={patient} yearRange={yearRange} assessments={assessments} />
      <NextYearButton yearRange={yearRange} setYearRange={setYearRange} />
    </Grid>
  );
}
