type milliseconds = number;
export const snackbarAutoHideDuration: milliseconds = 6000;

//If more Elements load data simultaneously an Error will be thrown,
//it is used for showing the Loading-Backdrop Animation and
// assuring that on every "startLoading" a "stopLoading" is used appropriately
export const maximumRegisteredLoadingElements = 10;

export const topNavigationHeaderSize = '4rem' as const;
export const navigationHeaderXPadding = '4rem' as const;

export const patientProfileButtonHeight = '45px' as const;

export const slidingRecommendationsTransitionTime: milliseconds = 350;
export const showLoadingIndicationForRiskGraphAfter: milliseconds = 100;

export const sessionStorageKeys = {
  recommendationConfig: 'recommendationConfig',
} as const;
