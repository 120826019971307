import { useContext, useEffect, useRef } from 'react';
import { Settings, SettingsContext, Units } from '../context/SettingsProvider';

const getHelpers = (settings: Settings) => ({
  formatDate(date: Date): string {
    const locale = settings.units === Units.Metric ? 'de-DE' : 'en-US';

    return date.toLocaleDateString(locale);
  },
  formatDateAbbreviated(date: Date): string {
    const locale = settings.units === Units.Metric ? 'de-DE' : 'en-US';
    const separator = settings.units === Units.Metric ? '.' : '/';
    const dateParts = date.toLocaleDateString(locale).split(separator);

    // Get the last two digits of the year
    const year = dateParts[dateParts.length - 1].slice(-2);

    // Replace the full year with the abbreviated year
    dateParts[dateParts.length - 1] = year;

    return dateParts.join(separator);
  },
  formattedDateStringToDate(date: string | undefined): Date | undefined {
    if (!date) {
      return undefined;
    }
    if (settings.units === Units.Metric) {
      const dateParts = date.split('.');
      const dateString = `${dateParts[1]}/${dateParts[0]}/${dateParts[2]}`;
      return new Date(dateString);
    }
    return new Date(date);
  },
});

export function useSettings() {
  const { settings, saveSettings } = useContext(SettingsContext);

  return {
    settings,
    saveSettings,
    helpers: getHelpers(settings),
  };
}

export function useTriggerOnSettingsChange(onSettingsChange?: (newSettings: Settings) => void) {
  const triggeredSettingsEffect = useRef(false);
  const { settings } = useSettings();

  useEffect(() => {
    if (triggeredSettingsEffect.current && onSettingsChange) {
      onSettingsChange(settings);
    } else if (!triggeredSettingsEffect.current) {
      triggeredSettingsEffect.current = true;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);
}
