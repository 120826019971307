import { Grid } from '@mui/material';
import { Options } from '../Options/Options';
import { RiskDifference } from './RiskDifference';
import { useOptions } from '../Options/useOptions';
import { TrendParameter } from '../Options/optionTypes';

export function DifferenceView({ patientId }: { patientId: number }) {
  const { optionSetter } = useOptions();
  optionSetter.setTrendParameter(TrendParameter.RiskDevelopment);
  return (
    <Grid container>
      <Grid item xs={8}>
        <RiskDifference patientId={patientId} />
      </Grid>
      <Grid item xs={4}>
        <Options
          patientId={patientId}
          showDifferenceOptions={true}
          showMostRecent={false}
          showTimespan={false}
          showTrendParameterDropdown={false}
        />
      </Grid>
    </Grid>
  );
}
