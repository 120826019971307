import { Grid, Typography } from '@mui/material';
import { useOptions } from './useOptions';
import { TrendParameter } from './optionTypes';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAssessments, useDiagnosisData } from '../../../../../api/patients';
import useWithLoadingAndErrorIndication from '../../../../../hooks/loadingIndication/useWithLoadingAndErrorIndication';
import { EmptyPlaceholderPage } from '../../../../core/EmptyPlaceholderPage';
import { MostRecentSwitch } from './OptionComponents/MostRecentSwitch';
import { DifferenceSwitch } from './OptionComponents/DifferenceSwitch';
import { TimespanDropdownMenu } from './OptionComponents/TimespanDropdownMenu';
import { TrendParameterDropdown } from './OptionComponents/TrendParameterDropdown';
import { HorizontalDivider } from './OptionComponents/HorizontalDivider';
import { RisksCheckboxes } from './OptionComponents/RiskCheckboxes';
import { DiagnosisCheckboxes } from './OptionComponents/DiagnosisCheckboxes';

interface OptionProps {
  patientId: number;
  showParameterChoice?: boolean;
  showMostRecent?: boolean;
  showTimespan?: boolean;
  showDifferenceOptions?: boolean;
  showTrendParameterDropdown?: boolean;
}

export function Options({
  patientId,
  showParameterChoice = true,
  showTimespan = true,
  showMostRecent = true,
  showDifferenceOptions = false,
  showTrendParameterDropdown = true,
}: OptionProps) {
  const { optionSetter, optionValues } = useOptions();
  const { t } = useTranslation('pages');
  const { data: diagnosisData } = useWithLoadingAndErrorIndication(
    useDiagnosisData(patientId, optionValues.timespan),
  );
  const { data: assessments } = useWithLoadingAndErrorIndication(useAssessments(patientId));

  if (!diagnosisData || !assessments) {
    return <EmptyPlaceholderPage />;
  }

  return (
    <Grid container direction={'column'} spacing={2}>
      {showMostRecent && (
        <Grid item xs={12}>
          <MostRecentSwitch
            setMostRecentLeft={optionSetter.setMostRecentLeft}
            mostRecentLeft={optionValues.mostRecentLeft}
            translator={t}
          />
          <HorizontalDivider marginY={2} />
        </Grid>
      )}
      {showDifferenceOptions && (
        <Grid item xs={12}>
          <DifferenceSwitch
            absolute={optionValues.absolute}
            setAbsolute={optionSetter.setAbsolute}
            translator={t}
          />
          <HorizontalDivider marginY={2} />
        </Grid>
      )}
      {showTimespan && (
        <>
          <Grid item xs={12}>
            <Typography>
              {t('patientProfile.trend.options.timespan.chooseTimespanLabel')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TimespanDropdownMenu
              timespan={optionValues.timespan}
              setTimespan={optionSetter.setTimespan}
              translator={t}
              assessments={assessments}
            />
            <HorizontalDivider marginY={3} />
          </Grid>
        </>
      )}
      {showParameterChoice && (
        <>
          <Grid item xs={12}>
            <Typography>
              {t('patientProfile.trend.options.trendParameter.chooseParameterLabel')}
            </Typography>
          </Grid>
          {showTrendParameterDropdown && (
            <Grid item xs={12}>
              <TrendParameterDropdown
                trendParameter={optionValues.trendParameter}
                setTrendParameter={optionSetter.setTrendParameter}
                translator={t}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            {optionValues.trendParameter === TrendParameter.RiskDevelopment ? (
              <RisksCheckboxes
                optionValues={optionValues}
                optionSetter={optionSetter}
                translator={t}
              />
            ) : (
              <DiagnosisCheckboxes
                optionValues={optionValues}
                optionSetter={optionSetter}
                diagnosisData={diagnosisData}
                translator={t}
              />
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
}
