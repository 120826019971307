import { Grid, List, ListItem } from '@mui/material';
import React from 'react';
import { CheckboxWithLabelAndTooltip } from '../../../../core/input-components/CheckboxWithLabelAndTooltip';
import { useTranslation } from 'react-i18next';
import { StyledRadioGroup } from '../../../../core/input-components/StyledRadioGroup';
import { AssessmentFormType } from '../../AssessmentForm';
import { disabledMedicalHistoryKeys, tickableMedicalHistoryKeys } from './medicalHistory.logic';
import LabelWithTooltip from '../../../../core/input-components/LabelWithTooltip/LabelWithTooltip';
import { CCS_SAF_Score } from '../../../../../types/assessments';

export default function MedicalHistoryEditable({ form }: { form: AssessmentFormType }) {
  return (
    <div style={{ pageBreakInside: 'avoid' }}>
      <Medication form={form} />
      <CCSSAFScoreInput form={form} />
    </div>
  );
}

function Medication({ form }: { form: AssessmentFormType }) {
  const { t } = useTranslation('forms');

  return (
    <List disablePadding={true} sx={{ columnCount: 2 }} dense>
      {disabledMedicalHistoryKeys.map((field) => (
        <ListItem disableGutters={true} key={field}>
          <CheckboxWithLabelAndTooltip
            name={field}
            label={t(`medicalHistory.${field}.label`)}
            tooltip={t(`medicalHistory.${field}.tooltip`)}
            control={form.control}
            disabled
          />
        </ListItem>
      ))}

      {tickableMedicalHistoryKeys.map((field) => (
        <ListItem disableGutters={true} key={field}>
          <CheckboxWithLabelAndTooltip
            name={field}
            label={t(`medicalHistory.${field}.label`)}
            tooltip={t(`medicalHistory.${field}.tooltip`)}
            control={form.control}
          />
        </ListItem>
      ))}
    </List>
  );
}

function CCSSAFScoreInput({ form }: { form: AssessmentFormType }) {
  const { t } = useTranslation('forms');

  return (
    <Grid container sx={{ mt: '20px' }}>
      <Grid item xs={12}>
        <StyledRadioGroup
          name={'CCS_SAF_score'}
          label={
            <LabelWithTooltip
              label={t('medicalHistory.CCS_SAF_score.label')}
              tooltipText={t('medicalHistory.CCS_SAF_score.tooltip')}
            />
          }
          options={Object.keys(CCS_SAF_Score)
            .filter((key) => {
              return !isNaN(Number(key)); // typescript is compiling enums with integer repr. in both ways, filter one out
            })
            .map((key) => ({
              ...t('medicalHistory.CCS_SAF_score.values.' + key, { returnObjects: true }),
              value: key,
            }))}
          control={form.control}
          errors={form.formState.errors}
        />
      </Grid>
    </Grid>
  );
}
