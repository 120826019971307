import React, { useEffect, useState } from 'react';
import { Box, Divider, Grid, Paper, Typography } from '@mui/material';
import Recommendations from './recommendations/Recommendations';
import {
  EvaluationResult,
  useAdjustedRisksOfAssessment,
  useEvaluationResultOfAssessment,
} from '../../../api/patients';
import { useTranslation } from 'react-i18next';
import { useSettings } from '../../../hooks/useSettings';
import { InformationTextPopup } from '../../core/InformationText/InformationTextPopup';
import useWithLoadingAndErrorIndication from '../../../hooks/loadingIndication/useWithLoadingAndErrorIndication';
import { LoadingIndicator } from '../../core/LoadingIndicator';
import { DownloadReportButton, SaveAndCloseButton } from './buttons';
import { AssessmentIdOrLatest } from '../../../api/report';
import {
  belongsToCorrectAssessment,
  useRecommendationsSessionStorage,
} from '../../../state/recommendations';
import { isSameRecommendation } from '../../../utils/recommendations';
import { showLoadingIndicationForRiskGraphAfter } from '../../../config/constants';
import { Risks as RisksType } from '../../../types/risk';
import Risks from './risks/Risks';
import { isRedoxPage } from '../../../utils/isRedoxPage';

type SaveAndCloseFunction = (evalData: EvaluationResult) => void;
interface EvaluationProps {
  patientId: string;
  saveAndClose?: SaveAndCloseFunction;
  assessmentId?: AssessmentIdOrLatest;
}

const LEFT_COLUMN_WIDTH = 5.2;
const RIGHT_COLUMN_WIDTH = 6.8;

export function Evaluation({ patientId, saveAndClose, assessmentId = 'latest' }: EvaluationProps) {
  const { data: evaluationResult } = useWithLoadingAndErrorIndication(
    useEvaluationResultOfAssessment(patientId, assessmentId.toString()),
  );

  const [
    savedRecommendationsConfig,
    setSavedRecommendationsConfig,
  ] = useRecommendationsSessionStorage(
    {
      assessment: assessmentId,
      patientId: Number.parseInt(patientId),
      recommendations: evaluationResult?.recommendations ?? [],
    },
    true,
  );

  const { data: adjustedRisks, isLoading: isLoadingAdjustedRisks } = useAdjustedRisksOfAssessment(
    patientId,
    assessmentId.toString(),
    savedRecommendationsConfig.recommendations,
    Boolean(evaluationResult),
  );

  const [isRiskDataLoading, setIsRiskDataLoading] = useState<boolean>(true);
  useEffect(() => {
    if (isLoadingAdjustedRisks) {
      setTimeout(() => {
        setIsRiskDataLoading(true);
      }, showLoadingIndicationForRiskGraphAfter);
    } else {
      setIsRiskDataLoading(false);
    }
  }, [isLoadingAdjustedRisks, setIsRiskDataLoading, isRiskDataLoading]);

  useEffect(() => {
    if (evaluationResult) {
      setSavedRecommendationsConfig({
        assessment: assessmentId,
        patientId: Number.parseInt(patientId),
        recommendations: !belongsToCorrectAssessment(savedRecommendationsConfig, {
          assessment: assessmentId,
          patientId: Number.parseInt(patientId),
        })
          ? evaluationResult.recommendations
          : evaluationResult.recommendations.map((re) => {
              // preserve "applied" ticks
              const oldRecommendation = savedRecommendationsConfig.recommendations.find((oldRe) =>
                isSameRecommendation(oldRe, re),
              );
              return oldRecommendation ?? re;
            }),
      });
    }
    // do not add savedRecommendationsConfig
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientId, assessmentId, evaluationResult, setSavedRecommendationsConfig]);

  return (
    <Box sx={{ p: 0 }}>
      {evaluationResult && (
        <Paper elevation={1} sx={{ padding: [0, 8, 0, 8] }}>
          <Grid container spacing={10}>
            <Grid item xs={LEFT_COLUMN_WIDTH}>
              <RisksHeaderBlock
                cha2ds2vascScore={evaluationResult.currentRisks.cha2ds2vascScore}
                evaluationDate={evaluationResult.date}
              />
              {isRiskDataLoading ? (
                <LoadingIndicator relativeVerticalPosition={100} />
              ) : (
                <Risks
                  riskHistory={{
                    before: evaluationResult.currentRisks,
                    after: adjustedRisks ? adjustedRisks : evaluationResult.adjustedRisks,
                  }}
                />
              )}
            </Grid>
            <Grid item xs={RIGHT_COLUMN_WIDTH}>
              <RecommendationHeaderBlock />
              <Recommendations
                recommendations={savedRecommendationsConfig.recommendations}
                onChange={(recommendations) =>
                  setSavedRecommendationsConfig({
                    assessment: assessmentId,
                    recommendations,
                    patientId: Number.parseInt(patientId),
                  })
                }
              />
              <Grid
                container
                justifyContent="flex-end"
                spacing={2}
                sx={{ marginBottom: 6, marginTop: 1, px: '1.5rem' }}
              >
                <Grid item xs={6}>
                  <SaveAndCloseButton
                    onSaveAndClose={saveAndClose ? () => saveAndClose(evaluationResult) : undefined}
                  />
                </Grid>
                {!isRedoxPage() && (
                  <Grid item xs={6}>
                    <DownloadReportButton patientId={patientId} assessmentId={assessmentId} />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      )}
    </Box>
  );
}

function RisksHeaderBlock({
  cha2ds2vascScore,
  evaluationDate,
}: {
  cha2ds2vascScore: RisksType['cha2ds2vascScore'];
  evaluationDate: Date;
}) {
  const { helpers } = useSettings();
  const { t } = useTranslation('pages');
  return (
    <>
      <Typography variant="body2" fontWeight="bold" sx={{ mb: 2 }}>
        {t('patientProfile.evaluation.dateLabel', {
          date: helpers.formatDate(evaluationDate),
        })}
      </Typography>
      <Typography variant="h5" fontWeight="bold" sx={{ mb: 3 }}>
        {t('patientProfile.evaluation.risks.cha2ds2vasc', {
          score: cha2ds2vascScore,
        })}
      </Typography>
      <Divider />
    </>
  );
}

function RecommendationHeaderBlock() {
  const { t } = useTranslation('pages');
  return (
    <>
      <Typography variant="h3" fontWeight="bold" sx={{ mb: 1 }}>
        {t('patientProfile.evaluation.recommendations.heading')}
      </Typography>
      <Typography>
        {t('patientProfile.evaluation.recommendations.helperText')}
        <InformationTextPopup
          document={{
            title: t('patientProfile.evaluation.recommendations.detailedHelpTextTitle'),
            sections: [
              {
                paragraphs: [t('patientProfile.evaluation.recommendations.detailedHelpText')],
              },
            ],
          }}
        />
      </Typography>
    </>
  );
}
