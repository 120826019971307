import React from 'react';
import { Typography } from '@mui/material';

interface StyledLabelWithNoteProps {
  label: string;
  tooltip: string;
}
export function StyledLabelWithNote({ label, tooltip }: StyledLabelWithNoteProps) {
  return (
    <Typography variant="body1" component="div">
      {label} &nbsp;
      <Typography
        variant="subtitle2"
        component="div"
        sx={{ color: 'text.secondary', display: 'inline' }}
      >
        ({tooltip})
      </Typography>
    </Typography>
  );
}
