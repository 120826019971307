import { AssessmentFormType } from '../../AssessmentForm';
import { DEFAULT_ASSESSMENT_VALUES_METRIC } from '../../../../../config/defaultValues';
import { AssessmentParameters, Medication } from '../../../../../types/assessments';
import {
  DataRepresentations,
  getAllKeys,
  getBooleanValueKeys,
  getEnumValueKeys,
  RepresentationMapping,
} from '../../logic/DataRepresentations';

const medicationDataRepresentationMapping: RepresentationMapping<Medication> = {
  antiplateletMedication: DataRepresentations.tickableBoolean,
  oralAnticoagulant: DataRepresentations.enumValue,
  contraindicationForOAC: DataRepresentations.enumValue,
  antiArrhythmicMedication_Class1or3: DataRepresentations.enumValue,
  diabetesMedication: DataRepresentations.enumValue,
};

export const medicationKeysBooleanValues = getBooleanValueKeys(medicationDataRepresentationMapping);
export const medicationKeysEnumValues = getEnumValueKeys(medicationDataRepresentationMapping);

export const medicationKeys = getAllKeys(medicationDataRepresentationMapping);

export function resetMedication(
  form: AssessmentFormType,
  prefilledValues?: Partial<AssessmentParameters>,
) {
  form.reset({
    ...form.getValues(),
    ...Object.fromEntries(
      medicationKeys.map((key) => [
        key,
        prefilledValues?.[key] ?? DEFAULT_ASSESSMENT_VALUES_METRIC[key],
      ]),
    ),
  });
}
