import { Avatar } from '@mui/material';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { theme } from '../../../../../theme';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';

interface AssessmentCardIconsProps {
  onClick: () => void;
}

export function EditAssessmentCardIcon(props: AssessmentCardIconsProps) {
  return (
    <Avatar
      sx={{ backgroundColor: 'primary.main', margin: 0.5 }}
      onClick={props.onClick}
      onMouseEnter={(e) => {
        e.currentTarget.style.backgroundColor = theme.palette.primary.dark;
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.backgroundColor = theme.palette.primary.main;
      }}
    >
      <ModeEditOutlineOutlinedIcon fontSize={'medium'} color={'action'} />
    </Avatar>
  );
}

export function AbortEditAssessmentCardIcon(props: AssessmentCardIconsProps) {
  return (
    <Avatar
      sx={{ backgroundColor: 'secondary.light', margin: 0.5 }}
      onClick={props.onClick}
      onMouseEnter={(e) => {
        e.currentTarget.style.backgroundColor = theme.palette.secondary.dark;
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.backgroundColor = theme.palette.secondary.light;
      }}
    >
      <ClearIcon fontSize={'large'} color={'action'} />
    </Avatar>
  );
}

export function SaveEditAssessmentCardIcon(props: AssessmentCardIconsProps) {
  return (
    <Avatar
      sx={{ backgroundColor: 'primary.main', margin: 0.5 }}
      onClick={props.onClick}
      onMouseEnter={(e) => {
        e.currentTarget.style.backgroundColor = theme.palette.primary.dark;
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.backgroundColor = theme.palette.primary.main;
      }}
    >
      <DoneIcon fontSize={'large'} color={'action'} />
    </Avatar>
  );
}
