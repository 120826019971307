import { useNavigateToWebRiskEvaluation } from '../../../../config/urlPaths';
import useWithLoadingAndErrorIndication from '../../../../hooks/loadingIndication/useWithLoadingAndErrorIndication';
import { assessmentsApi, useLatestWebAssessment } from '../../../../api/assessments';
import { useWithLoadingIndication } from '../../../../hooks/loadingIndication/useWithLoadingIndication';
import React from 'react';
import { AssessmentForm } from '../../assessmentForm/AssessmentForm';
import { EmptyPlaceholderPage } from '../../../core/EmptyPlaceholderPage';
import { scrollToTop } from '../../../../utils/general';
import { AssessmentParameters } from '../../../../types/assessments';

export function PrefilledLatestWebAssessment({ patientId }: { patientId: string }) {
  const goToEvaluationPage = useNavigateToWebRiskEvaluation(patientId);
  const { data: assessment, isLoading, isFetching } = useWithLoadingAndErrorIndication(
    useLatestWebAssessment(patientId),
  );

  const onUpdate = useWithLoadingIndication(async (newParams: AssessmentParameters) => {
    if (assessment === undefined) throw Error('No Assessment Data Found');
    await assessmentsApi.update({ ...assessment, ...newParams });
    scrollToTop();
    goToEvaluationPage();
  });

  if (isLoading || isFetching) {
    return <EmptyPlaceholderPage />;
  } else {
    return <AssessmentForm onSubmit={onUpdate} prefilledValues={assessment} />;
  }
}
