import React, { ReactNode, useEffect, useState } from 'react';
import { Divider, Grid, Paper, Stack, SxProps, Theme } from '@mui/material';
import AssessmentCardHeading from './AssessmentCardHeading';
import {
  AbortEditAssessmentCardIcon,
  EditAssessmentCardIcon,
  SaveEditAssessmentCardIcon,
} from './AssessmentCardIcons';
import { AssessmentFormType, FormParameter } from '../../AssessmentForm';

interface AssessmentCardProps {
  sx?: SxProps<Theme>;
  title: string;

  notEditableChildren: ReactNode;
  editableChildren: ReactNode;
  onAbortEdit: () => void;
  form: AssessmentFormType;
  inputValues: Array<keyof Partial<FormParameter>>;
  editable?: boolean;
}

export default function AssessmentCard(props: AssessmentCardProps) {
  props = {
    editable: true,
    ...props,
  };

  const [inEditMode, setEditMode] = useState(false);
  const abortEdit = () => {
    props.onAbortEdit();
    setEditMode(false);
  };
  const saveEdit = () => {
    props.form.trigger().then(() => {
      const hasAnError = errorDeps.reduce((previous, current) => {
        return current ? current : previous;
      }, undefined);
      if (!hasAnError) {
        setEditMode(false);
      }
    });
  };
  const startEdit = () => setEditMode(true);

  const errorDeps = props.inputValues.map((key) => props.form.formState.errors[key]);

  useEffect(() => {
    errorDeps.forEach((error) => {
      if (error) startEdit();
    });
    for (const element of props.inputValues) {
      if (props.form.formState.errors[element]) {
      }
    }
  }, [errorDeps, props.form.formState.errors, props.inputValues]);

  return (
    <Paper elevation={2} sx={{ m: '1rem', p: '2rem', ...props.sx }}>
      <Grid container direction={'row'} justifyContent={'space-between'} sx={{ pb: 1 }}>
        <AssessmentCardHeading text={props.title} />
        {props.editable ? (
          inEditMode ? (
            <Stack direction={'row'}>
              <AbortEditAssessmentCardIcon onClick={abortEdit} />
              <SaveEditAssessmentCardIcon onClick={saveEdit} />
            </Stack>
          ) : (
            <EditAssessmentCardIcon onClick={startEdit} />
          )
        ) : null}
      </Grid>
      {inEditMode && <Divider sx={{ mb: 1.5 }} />}
      {/*The Children Have to be rendered at all times to let
      the form elements stay registered, which get triggered on validation*/}
      <div style={{ display: inEditMode ? undefined : 'none' }}>{props.editableChildren}</div>
      <div style={{ display: inEditMode ? 'none' : undefined }}>{props.notEditableChildren}</div>
    </Paper>
  );
}
