import useLoadingIndicator from './useLoadingIndicator';

// Wraps around a function and creates the props for a LoadingBackdrop so that
// a Loading Backdrop will be shown while the function is executing

export function useWithLoadingIndication<T extends any[], U>(
  func: (...args: T) => Promise<U>,
): (...args: T) => Promise<U> {
  const [stopLoading, startLoading] = useLoadingIndicator();

  return async (...args: T) => {
    startLoading();
    return new Promise((resolve, reject) => {
      func(...args)
        .then((value) => {
          stopLoading();
          resolve(value);
        })
        .catch((reason) => {
          stopLoading();
          reject(reason);
        });
    });
  };
}
