import { Patient } from '../../../api/patients';
import { Container } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import { AssessmentIdOrLatest } from '../../../api/report';
import { WebHeader } from '../../patient-profile/WebHeader';

interface AssessmentFrameProps {
  patient: Patient;
  assessmentId?: AssessmentIdOrLatest;
  showAssessmentButton?: boolean;
  showEvaluationButton?: boolean;
  showDateDropdown?: boolean;
  id: string;
}

export function AssessmentFormFrame(props: PropsWithChildren<AssessmentFrameProps>) {
  return (
    <Container maxWidth="md" sx={{ mt: '2rem' }}>
      <WebHeader
        patient={props.patient}
        assessmentId={props.assessmentId}
        showAssessmentButton={props.showAssessmentButton}
        showEvaluationButton={props.showEvaluationButton}
        showDateDropdown={props.showDateDropdown}
      />
      {props.children}
    </Container>
  );
}
