import { AssessmentParameters, ImperialAssessmentParameters } from '../types/assessments';

const KG_PER_POUND = 0.45359237;
const CM_PER_FOOT = 30.48;
const CM_PER_INCH = 2.54;
const INCHES_PER_FOOT = 12;

export function convertImperialToMetricUnits({
  feet,
  inches,
  weight: imperialWeight,
  ...params
}: ImperialAssessmentParameters): AssessmentParameters {
  return {
    ...params,
    weight: convertWeightImperialToMetricUnits(imperialWeight),
    height: convertHeightImperialToMetricUnits(feet, inches),
  };
}
export function convertMetricToImperialUnits({
  weight: metricWeight,
  height: metricHeight,
  ...params
}: AssessmentParameters): ImperialAssessmentParameters {
  const { inches, feet } = convertHeightMetricToImperialUnits(metricHeight);

  return {
    ...params,
    weight: convertWeightMetricToImperialUnits(metricWeight),
    feet,
    inches,
  };
}

export function convertWeightMetricToImperialUnits(weight?: number | null) {
  return weight ? Math.round(weight / KG_PER_POUND) : null;
}

export function convertWeightImperialToMetricUnits(weight?: number | null) {
  return weight ? Math.round(weight * KG_PER_POUND) : null;
}

export function convertHeightImperialToMetricUnits(
  feet?: number | null,
  inches?: number | null,
): number | null {
  if (!feet && !inches) {
    return null;
  }
  let height = 0;
  if (feet) height += feet * CM_PER_FOOT;
  if (inches) height += inches * CM_PER_INCH;
  return Math.round(height);
}

export function convertHeightMetricToImperialUnits(
  height: number | null,
): {
  feet: number | null;
  inches: number | null;
} {
  if (height === null) {
    return { feet: null, inches: null };
  }

  let inches = Math.round(height / CM_PER_INCH);
  const feet = Math.floor(inches / INCHES_PER_FOOT);
  inches = inches % INCHES_PER_FOOT;

  return { inches, feet };
}
