import { useTranslation } from 'react-i18next';
import { useSettings } from '../../../../../hooks/useSettings';
import { Stack } from '@mui/material';
import { isImperial, isMetric } from '../../../../../context/SettingsProvider';
import React from 'react';
import { NumericalParameterLine } from '../../subComponents/NumericalParameterLine';
import { AssessmentFormType } from '../../AssessmentForm';

export function VitalsNonEditable({ form }: { form: AssessmentFormType }) {
  const { t } = useTranslation('forms');
  const { settings } = useSettings();
  const values = form.getValues();

  return (
    <Stack>
      {isMetric(settings.units) && (
        <>
          <NumericalParameterLine
            title={t('vitals.weight')}
            value={values.weight}
            unitLabel={'kg'}
          />
          <NumericalParameterLine
            title={t('vitals.height')}
            value={values.height}
            unitLabel={'cm'}
          />
        </>
      )}
      {isImperial(settings.units) && (
        <>
          <NumericalParameterLine
            title={t('vitals.weight')}
            value={values.weight}
            unitLabel={'lb'}
          />
          <NumericalParameterLine
            title={t('vitals.height')}
            value={(values.feet?.toString() ?? '--') + ' / ' + (values.inches?.toString() ?? '--')}
            unitLabel={'ft / in'}
          />
        </>
      )}
      <NumericalParameterLine title={t('vitals.pulse')} value={values.pulse} unitLabel={'bpm'} />
      <NumericalParameterLine
        title={t('vitals.systolicBloodPressure') + ' / ' + t('vitals.diastolicBloodPressure')}
        value={
          (values.systolicBloodPressure?.toString() ?? '--') +
          ' / ' +
          (values.diastolicBloodPressure?.toString() ?? '--')
        }
        unitLabel={'mmHg'}
        withDivider={false}
      />
    </Stack>
  );
}
