import { AssessmentComponent } from '../../../../../../api/patients';
import React, { useState } from 'react';
import { Button, Menu, MenuItem, Typography } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

export function TimespanDropdownMenu({
  timespan,
  setTimespan,
  translator,
  assessments,
}: {
  timespan: number;
  setTimespan: (timespan: number) => void;
  translator: (key: string) => string;
  assessments: AssessmentComponent[];
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const timespanOptions = getYearsInMonthsFromDate(assessments[assessments.length - 1].date).filter(
    (option) => option !== timespan,
  );
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        onClick={handleClick}
        size={'large'}
        endIcon={anchorEl == null ? <ExpandMore /> : <ExpandLess />}
        sx={{
          color: 'neutral.main',
          backgroundColor: 'secondary.light',
          width: '100%',
          justifyContent: 'space-between',
          '&:hover': {
            background: 'rgba(48, 48, 48, 0.04)',
          },
        }}
      >
        <Typography variant={'h6'} display={'inline'} sx={{ mb: 0, ml: 1 }}>
          {getTimespanMenuItemLabel(timespan, translator)}
        </Typography>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={anchorEl !== null}
        onClose={closeMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={{
          width: '100%',
          '& .MuiPaper-root': {
            color: (theme) => theme.palette.primary.main,
            backgroundColor: (theme) => theme.palette.secondary.light,
            '& .MuiMenuItem-root': {
              '&:hover': {
                background: 'action.active',
              },
            },
          },
        }}
      >
        {timespanOptions.map((timespan) => (
          <MenuItem
            onClick={() => {
              setTimespan(timespan);
            }}
            sx={{ color: 'neutral.main' }}
            key={timespan}
          >
            <Typography>{getTimespanMenuItemLabel(timespan, translator)}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

function getTimespanMenuItemLabel(timespan: number, translator: (key: string) => string) {
  switch (timespan) {
    case 6:
      return `${translator('patientProfile.trend.options.timespan.lastLabel')} 6 ${translator(
        'patientProfile.trend.options.timespan.monthsLabel',
      )}`;
    case 12:
      return `${translator('patientProfile.trend.options.timespan.lastLabel')} ${translator(
        'patientProfile.trend.options.timespan.yearLabel',
      )}`;
    default:
      return `${translator('patientProfile.trend.options.timespan.lastLabel')} ${
        timespan / 12
      } ${translator('patientProfile.trend.options.timespan.yearsLabel')}`;
  }
}

function getYearsInMonthsFromDate(date: Date): number[] {
  const currentDate = new Date();
  const pastDate = new Date(date);

  // Calculate the difference in months
  let months =
    currentDate.getMonth() -
    pastDate.getMonth() +
    12 * (currentDate.getFullYear() - pastDate.getFullYear());

  // Round up to the nearest whole year
  months = Math.ceil(months / 12) * 12;

  // Generate an array of years in months
  let yearsInMonths = [6];
  for (let i = 12; i <= months; i += 12) {
    yearsInMonths.push(i);
  }

  return yearsInMonths;
}
