import React from 'react';
import { redoxApi, useRedoxPrefilledAssessment } from '../../../../api/redox';
import { convertImperialToMetricUnits } from '../../../../utils/units';
import { useSearchParams } from 'react-router-dom';
import { useWithLoadingIndication } from '../../../../hooks/loadingIndication/useWithLoadingIndication';
import useWithLoadingAndErrorIndication from '../../../../hooks/loadingIndication/useWithLoadingAndErrorIndication';
import { useNavigateToRedoxRiskEvaluation } from '../../../../config/urlPaths';
import { AssessmentForm } from '../../assessmentForm/AssessmentForm';
import { EmptyPlaceholderPage } from '../../../core/EmptyPlaceholderPage';
import { DEFAULT_ASSESSMENT_VALUES_IMPERIAL } from '../../../../config/defaultValues';
import { AssessmentParameters } from '../../../../types/assessments';

interface PrefilledRedoxAssessmentProps {
  sessionId: string;
}

export function PrefilledRedoxAssessment(props: PrefilledRedoxAssessmentProps) {
  const [searchParams] = useSearchParams();
  const goToEvaluation = useNavigateToRedoxRiskEvaluation(searchParams);
  const { data: assessmentData, isLoading, isFetching } = useWithLoadingAndErrorIndication(
    useRedoxPrefilledAssessment(props.sessionId),
  );

  const onCreateAssessment = useWithLoadingIndication(async (params: AssessmentParameters) => {
    await redoxApi.createAssessment(props.sessionId, params);
    goToEvaluation();
  });

  if (isLoading || isFetching) {
    return <EmptyPlaceholderPage />;
  }

  return (
    <AssessmentForm
      onSubmit={onCreateAssessment}
      prefilledValues={
        assessmentData
          ? convertImperialToMetricUnits({
              ...DEFAULT_ASSESSMENT_VALUES_IMPERIAL,
              ...assessmentData.parameters,
            })
          : undefined
      }
    />
  );
}
