import React, { Fragment, PropsWithChildren, useState } from 'react';
import { InfoIcon } from '../customIcons/InfoIcon';
import { StyledPopup } from '../StyledPopup/StyledPopup';
import { IconButton, Link } from '@mui/material';
import { DocumentParagraph, DocumentTitle } from '../DocumentParts';
import { Section, InformationText } from './informationText.types';

interface InformationTextPopupProps {
  document: InformationText;
  linkText?: string;
}

export function InformationTextPopup(props: PropsWithChildren<InformationTextPopupProps>) {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setIsOpen(true);
  };

  return (
    <>
      {props.linkText ? (
        <Link onClick={handleOpen}>{props.linkText}</Link>
      ) : (
        <IconButton
          sx={{ color: (theme) => theme.palette.grey[500], top: -7 }}
          onClick={() => setIsOpen(true)}
        >
          <InfoIcon fontSize="small" />
        </IconButton>
      )}
      <StyledPopup title={props.document.title} open={isOpen} handleClose={() => setIsOpen(false)}>
        <Sections sections={props.document.sections} />
      </StyledPopup>
    </>
  );
}

function Sections({ sections }: { sections: Section[] }) {
  return (
    <div>
      {sections.map((section, index) => {
        return (
          <Fragment key={index}>
            {section.title && <DocumentTitle text={section.title} key={index} />}
            {section.paragraphs.map((paragraph, paragraphIndex) => (
              <DocumentParagraph text={paragraph} key={paragraphIndex} />
            ))}
          </Fragment>
        );
      })}
    </div>
  );
}
