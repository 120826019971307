import { useSettings } from '../../../../../hooks/useSettings';
import { isImperial, isMetric } from '../../../../../context/SettingsProvider';
import { MetricWeightHeight } from './MetricWeightHeight';
import { ImperialWeightHeight } from './ImperialWeightHeight';
import { PulseBloodPressure } from './PulseBloodPressure';
import React from 'react';
import { AssessmentFormType } from '../../AssessmentForm';

export function VitalsEditable({ form }: { form: AssessmentFormType }) {
  const disableInchesField = form.watch('feet') === null;

  const { settings } = useSettings();

  return (
    <>
      {isMetric(settings.units) && (
        <MetricWeightHeight register={form.register} errors={form.formState.errors} />
      )}
      {isImperial(settings.units) && (
        <ImperialWeightHeight
          register={form.register}
          errors={form.formState.errors}
          disableInchesField={disableInchesField}
        />
      )}
      <PulseBloodPressure form={form} />
    </>
  );
}
