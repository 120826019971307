import { useTranslation } from 'react-i18next';
import { useNavigateToVisitPage } from '../../../config/urlPaths';
import { useTheme } from '@mui/material';
import React from 'react';
import { ToVisitIcon } from '../customIcons/ToVisitsIcon';
import { useLocation } from 'react-router-dom';
import { ActivatedButton, isActivated } from './NavigationComponents/ActivatedButton';
import { UnactivatedButton } from './NavigationComponents/UnactivatedButton';

export function VisitsButton({ patientId }: { patientId: number }) {
  const { t } = useTranslation('header');
  const location = useLocation();
  const theme = useTheme();

  const toVisitPage = useNavigateToVisitPage();
  const label = t('visitsLabel');
  return isActivated(location.pathname, 'visits') ? (
    <ActivatedButton
      label={label}
      onClick={() => toVisitPage(patientId)}
      icon={<ToVisitIcon color={theme.palette.primary.main} fontSize={'large'} />}
    />
  ) : (
    <UnactivatedButton
      onClick={() => toVisitPage(patientId)}
      icon={<ToVisitIcon color={theme.palette.secondary.light} fontSize={'large'} />}
      label={label}
    />
  );
}
