import { AssessmentParameters, OralAnticoagulant } from '../../../types/assessments';
import { useForm } from 'react-hook-form';
import { DEFAULT_ASSESSMENT_VALUES_METRIC } from '../../../config/defaultValues';
import { useTranslation } from 'react-i18next';
import { useResetVitals, vitalKeys } from './formPartials/vitals/vitals.logic';
import { useCorrectFormFieldsForSettings } from './logic/correctUnits';
import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import AssessmentCard from './subComponents/AssessmentCard/AssessmentCard';
import { VitalsEditable } from './formPartials/vitals/Vitals.editable';
import { VitalsNonEditable } from './formPartials/vitals/Vitals.nonEditable';
import { OptionalParametersEditable } from './formPartials/optionalParameters/OptionalParameters.editable';
import { OptionalParametersNonEditable } from './formPartials/optionalParameters/OptionalParameters.nonEditable';
import {
  optionalParameterKeys,
  resetOptionalParameters,
} from './formPartials/optionalParameters/optionalParameters.logic';
import MedicalHistoryEditable from './formPartials/medicalHistory/MedicalHistory.editable';
import MedicalHistoryNonEditable from './formPartials/medicalHistory/MedicalHistory.nonEditable';
import {
  medicalHistoryKeys,
  resetMedicalHistory,
} from './formPartials/medicalHistory/medicalHistory.logic';
import { MedicationsEditable } from './formPartials/medications/Medications.editable';
import MedicationsNonEditable from './formPartials/medications/Medications.nonEditable';
import { medicationKeys, resetMedication } from './formPartials/medications/medications.logic';
import { TreatmentEditable } from './formPartials/treatment/Treatment.editable';
import TreatmentNonEditable from './formPartials/treatment/Treatment.nonEditable';
import { resetTreatment, treatmentKeys } from './formPartials/treatment/treatment.logic';
import { AssessmentFormType, FormParameter } from './AssessmentForm';

export function NonEditableAssessmentForm({
  prefilledValues,
}: {
  prefilledValues: Partial<AssessmentParameters>;
}) {
  const form: AssessmentFormType = useForm<FormParameter>({
    mode: 'onBlur',
    defaultValues: { ...DEFAULT_ASSESSMENT_VALUES_METRIC, ...prefilledValues },
  });

  const { t } = useTranslation(['pages', 'forms']);
  const resetVitals = useResetVitals(form, prefilledValues);

  useCorrectFormFieldsForSettings(form);

  const OAC = form.watch('oralAnticoagulant');
  const showContraindicationForOAC = OAC === OralAnticoagulant.NONE;
  const showDiabetesMedication = form.watch('diabetes');

  useEffect(() => {
    if (!showContraindicationForOAC) {
      form.setValue(
        'contraindicationForOAC',
        DEFAULT_ASSESSMENT_VALUES_METRIC.contraindicationForOAC,
      );
    }
    if (!showDiabetesMedication) {
      form.setValue('diabetesMedication', DEFAULT_ASSESSMENT_VALUES_METRIC.diabetesMedication);
    }
  }, [form, showContraindicationForOAC, showDiabetesMedication]);

  return (
    <>
      <Grid container direction={'row'} sx={{ width: '100%', pr: '1rem', pb: '1rem' }} spacing={2}>
        <Grid item sm={12} md={5.5} sx={{ width: '100%' }}>
          <AssessmentCard
            title={t('pages:assessment.headings.vitals')}
            editableChildren={<VitalsEditable form={form} />}
            notEditableChildren={<VitalsNonEditable form={form} />}
            onAbortEdit={resetVitals}
            form={form}
            inputValues={vitalKeys}
            editable={false}
            sx={{ height: '100%', width: '100%', pb: 0 }}
          />
        </Grid>
        <Grid item sm={12} md={6.5} sx={{ width: '100%' }}>
          <AssessmentCard
            title={t('pages:assessment.headings.optionalParameters')}
            editableChildren={<OptionalParametersEditable form={form} />}
            notEditableChildren={<OptionalParametersNonEditable form={form} />}
            onAbortEdit={() => resetOptionalParameters(form, prefilledValues)}
            form={form}
            inputValues={optionalParameterKeys}
            editable={false}
            sx={{ height: '100%', width: '100%', pb: 0 }}
          />
        </Grid>
      </Grid>
      <AssessmentCard
        title={t('forms:medicalHistory.heading')}
        editableChildren={<MedicalHistoryEditable form={form} />}
        notEditableChildren={<MedicalHistoryNonEditable form={form} />}
        onAbortEdit={() => resetMedicalHistory(form, prefilledValues)}
        form={form}
        editable={false}
        inputValues={medicalHistoryKeys}
      />
      <AssessmentCard
        title={t('forms:medications.heading')}
        editableChildren={
          <MedicationsEditable
            form={form}
            showDiabetesMedication={showDiabetesMedication}
            showContraindicationForOAC={showContraindicationForOAC}
          />
        }
        notEditableChildren={
          <MedicationsNonEditable
            form={form}
            showDiabetesMedication={showDiabetesMedication}
            showContraindicationForOAC={showContraindicationForOAC}
          />
        }
        onAbortEdit={() => resetMedication(form, prefilledValues)}
        form={form}
        inputValues={medicationKeys}
        editable={false}
      />
      <AssessmentCard
        title={t('forms:treatment.heading')}
        editableChildren={<TreatmentEditable form={form} />}
        notEditableChildren={<TreatmentNonEditable form={form} />}
        onAbortEdit={() => resetTreatment(form, prefilledValues)}
        form={form}
        inputValues={treatmentKeys}
        editable={false}
      />
    </>
  );
}
