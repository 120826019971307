import React, { Suspense, useState } from 'react';
import { Patient, PatientParameters, patientsApi } from '../../../api/patients';
import { Avatar, Box, Button, ButtonBase, Divider, Grid, Paper, Typography } from '@mui/material';
import { DemographicDataForm } from './DemographicDataForm';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useSettings } from '../../../hooks/useSettings';
import { getAgeAtDate } from '../../../utils/dates';
import { useWithLoadingIndication } from '../../../hooks/loadingIndication/useWithLoadingIndication';
import useWithErrorsInSnackbar from '../../../hooks/snackbarMessaging/useWithErrorsInSnackbar';
import { NewAssessmentIcon } from '../../core/customIcons/NewAssessmentIcon';
import {
  useNavigatePatientDemographics,
  useNavigateToNewWebAssessment,
} from '../../../config/urlPaths';
import { theme } from '../../../theme';

interface PatientItemProps {
  patient: Patient;
}

export function PatientItem(props: PatientItemProps) {
  const [isExpanded, setIsExpanded] = useState(false);

  const { helpers } = useSettings();
  const { t } = useTranslation(['forms', 'pages']);

  const birthday = new Date(props.patient.birthday);

  const queryClient = useQueryClient();
  const handleSave = useWithLoadingIndication(
    useWithErrorsInSnackbar(async (newParameters: PatientParameters) => {
      await patientsApi.update({ ...props.patient, ...newParameters });
      await queryClient.invalidateQueries('patients');
      setIsExpanded(false);
    }),
  );

  const toDemographics = useNavigatePatientDemographics(props.patient.id);
  const [isAssessmentLinkHovered, setAssessmentLinkHovered] = useState(false);

  const handleDelete = useWithLoadingIndication(
    useWithErrorsInSnackbar(async () => {
      if (
        window.confirm(
          t('pages:patients.deleteMessage') +
            ` ${props.patient.firstName} ${props.patient.lastName}?`,
        )
      ) {
        await patientsApi.deletePatient(props.patient.id);
        await queryClient.invalidateQueries('patients');
        setIsExpanded(false);
      }
    }),
  );
  return (
    <Paper elevation={0} data-testid={`patient-${props.patient.id}`} sx={{ mt: 1 }}>
      <ButtonBase
        sx={{
          width: '100%',
          '&:hover': isAssessmentLinkHovered
            ? {}
            : {
                background: 'rgba(48, 48, 48, 0.04)',
              },
        }}
        onClick={() => toDemographics()}
      >
        <Grid
          container
          justifyContent={'space-between'}
          alignContent={'center'}
          sx={{ px: 2.5, py: 2 }}
        >
          <Grid item xs={10} sx={{ textAlign: 'left', px: '2rem' }}>
            <PatientName patient={props.patient} />
            <PatientBirthdate birthday={birthday} />
          </Grid>

          <Grid item xs={1} sx={{ height: 'auto', width: 'auto' }}>
            <AssessmentLink
              to={props.patient}
              setAssessmentLinkHovered={setAssessmentLinkHovered}
            />
          </Grid>
        </Grid>
        {isExpanded && (
          <Box sx={{ px: 4, pb: 2 }}>
            <Divider sx={{ mb: 4 }} />
            <Suspense fallback={null}>
              <DemographicDataForm
                patientParameters={{ ...props.patient, birthday: helpers.formatDate(birthday) }}
                onSave={handleSave}
                id={props.patient.id.toString()}
                additionalButtons={() => [
                  <Button color="secondary" variant="contained" onClick={handleDelete}>
                    {t('delete', 'Delete')}
                  </Button>,
                ]}
              />
            </Suspense>
          </Box>
        )}
      </ButtonBase>
    </Paper>
  );
}

function PatientName({ patient }: { patient: Patient }) {
  return (
    <Typography variant="h5" component="h2">
      {patient.firstName + ' ' + patient.lastName}
    </Typography>
  );
}

function PatientBirthdate({ birthday }: { birthday: Date }) {
  const { helpers } = useSettings();
  const { t } = useTranslation('pages');
  return (
    <Typography variant="subtitle1">{`${helpers.formatDate(birthday)} (${getAgeAtDate(
      birthday,
      new Date(),
    )} ${t('patients.years')})`}</Typography>
  );
}

function AssessmentLink({
  to,
  setAssessmentLinkHovered,
}: {
  to: Patient;
  setAssessmentLinkHovered: (isAssessmentLinkHovered: boolean) => void;
}) {
  const toAssessment = useNavigateToNewWebAssessment();
  return (
    <Avatar
      sx={{
        backgroundColor: 'primary.main',
        m: 0.5,
        height: '3rem',
        width: '3rem',
        alignContent: 'center',
        justifyContent: 'center',
      }}
      onClick={(e) => {
        e.stopPropagation();
        toAssessment(to.id);
      }}
      onMouseEnter={(e) => {
        setAssessmentLinkHovered(true);
        e.currentTarget.style.backgroundColor = theme.palette.primary.dark;
      }}
      onMouseLeave={(e) => {
        setAssessmentLinkHovered(false);
        e.currentTarget.style.backgroundColor = theme.palette.primary.main;
      }}
    >
      <NewAssessmentIcon color={'action'} />
    </Avatar>
  );
}
