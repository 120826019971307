export interface Section {
  title?: string;
  paragraphs: string[];
}

export interface InformationText {
  title: string;
  sections: Section[];
}

export const TITLE_KEYWORD = 'title';
export const SECTIONS_KEYWORD = 'sections';
