import { Recommendation } from '../../../../api/patients';
import { RecommendationCategory } from '../../../../api/report';

export const orderedUsedRecommendationCategories = [
  RecommendationCategory.recommendation,
  RecommendationCategory.consideration,
] as const;

export function recommendationSortFunction(a: Recommendation, b: Recommendation): number {
  if (a.disabled && !b.disabled) {
    return 1;
  }
  if (b.disabled && !a.disabled) {
    return -1;
  }
  return a.title.localeCompare(b.title);
}
