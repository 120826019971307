import { Card, Grid, Typography, useTheme } from '@mui/material';
import { DateBox } from './DateBox';
import { AssessmentComponent } from '../../../api/patients';
import { VisitsProps } from './Visits';
import React from 'react';
import { DATE_BOX_HEIGHT, DATE_BOX_MARGIN_RIGHT, DATE_BOX_MARGIN_TOP } from './Calendar';

function getLatestAssessmentOfMonth(
  assessments: AssessmentComponent[],
  year: number,
  month: number,
): AssessmentComponent | null {
  const sortedAssessments = assessments.sort((a, b) => b.date.getTime() - a.date.getTime());

  for (let assessment of sortedAssessments) {
    if (assessment.date.getFullYear() === year && assessment.date.getMonth() === month - 1) {
      return assessment;
    }
  }
  return null;
}

function getYearRange(yearRange: number): number[] {
  const currentYear = new Date().getFullYear() + yearRange;
  return [currentYear - 4, currentYear - 3, currentYear - 2, currentYear - 1, currentYear];
}
function getMonthsInNumbers(): number[] {
  return Array.from({ length: 12 }, (_, i) => i + 1);
}

export interface YearProp extends VisitsProps {
  yearRange: number;
  assessments: AssessmentComponent[];
}
export function Years({ patient, yearRange, assessments }: YearProp) {
  const years = getYearRange(yearRange);
  const theme = useTheme();
  const secondaryColor = theme.palette.secondary.dark;

  const months = getMonthsInNumbers();

  return (
    <>
      {years.map((year) => (
        <Grid container direction={'column'} item xs key={year}>
          <Card
            sx={{
              height: DATE_BOX_HEIGHT,
              width: '9rem',
              mt: DATE_BOX_MARGIN_TOP,
              mr: DATE_BOX_MARGIN_RIGHT,
              border: 'none',
              boxShadow: 'none',
              pl: 2,
              backgroundColor: 'transparent',
            }}
          >
            <Typography color={secondaryColor}>{year}</Typography>
          </Card>
          {months.map((month) => (
            <DateBox
              assessmentComponent={getLatestAssessmentOfMonth(assessments, year, month)}
              patientId={patient.id}
              key={month}
            />
          ))}
        </Grid>
      ))}
    </>
  );
}
