import { ClickAwayListener, IconButton, Tooltip } from '@mui/material';
import React, { ReactFragment } from 'react';
import { InfoIcon } from '../../customIcons/InfoIcon';

interface InfoTooltipProps {
  text: string | ReactFragment;
}

export default function InfoTooltip(props: InfoTooltipProps) {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={props.text}
      >
        <sup>
          <IconButton onClick={handleTooltipOpen} size={'small'}>
            <InfoIcon style={{ fontSize: 16 }} color={'primary'} />
          </IconButton>
        </sup>
      </Tooltip>
    </ClickAwayListener>
  );
}
