import { Grid, IconButton, Link, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import { NavigateBefore, Print } from '@mui/icons-material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { isRedoxPage } from '../../utils/isRedoxPage';
import { NavLink } from '../core/NavLink';

const PREFIX = 'Header';

const classes = {
  header: `${PREFIX}-header`,
  name: `${PREFIX}-name`,
  headerLinks: `${PREFIX}-headerLinks`,
  activeHeaderLink: `${PREFIX}-activeHeaderLink`,
  printButton: `${PREFIX}-printButton`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.header}`]: {
    backgroundColor: 'black',
  },

  [`& .${classes.name}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: '1rem 3rem',
    margin: 0,
  },

  [`& .${classes.headerLinks}`]: {
    margin: '0 1.5rem',
    color: 'white',
  },

  [`& .${classes.activeHeaderLink}`]: {
    color: theme.palette.primary.main,
  },

  [`& .${classes.printButton}`]: {
    marginLeft: 'auto',
    marginRight: '1rem',
  },
}));

interface HeaderProps {
  name: string;
  id: string;
}

function getLinks(id: string) {
  if (isRedoxPage()) {
    return {
      back: `/redox/assessment/latest?session=${id}`,
      risk: `/redox/evaluation/risk?session=${id}`,
      trend: `/redox/evaluation/trend?session=${id}`,
    };
  }
  return {
    back: `/patient/${id}/assessment/latest`,
    risk: `/patient/${id}/evaluation/risk`,
    trend: `/patient/${id}/evaluation/trend`,
  };
}

export function Header(props: HeaderProps) {
  const { t } = useTranslation('pages');
  const links = getLinks(props.id);

  return (
    <StyledGrid
      container
      justifyContent="flex-start"
      alignItems="center"
      className={classes.header}
    >
      <Link component={RouterLink} to={links.back} variant="h6" className={classes.name}>
        <Grid container>
          <NavigateBefore />
          <div> {props.name} </div>
        </Grid>
      </Link>
      <Link
        component={NavLink}
        to={links.risk}
        variant="h6"
        className={classes.headerLinks}
        activeClassName={classes.activeHeaderLink}
        underline="hover"
      >
        {t('patientProfile.linkHeadings.assessment', 'Risk assessment')}
      </Link>
      <PrintButton />
    </StyledGrid>
  );
}

export function PrintButton() {
  const { t } = useTranslation('pages');

  return (
    <Tooltip title={t<string>('patientProfile.printNote')} classes={{ popper: 'noprint' }}>
      <IconButton
        color="primary"
        onClick={window.print}
        className={classes.printButton}
        size="large"
      >
        <Print />
      </IconButton>
    </Tooltip>
  );
}
