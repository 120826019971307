import { Container, Grid, Paper, Typography } from '@mui/material';
import React from 'react';
import { Patient } from '../../../api/patients';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { SaveAndCloseButton } from '../evaluation/buttons';
import { defaultDownloadChoices } from './report.config';
import { DownloadFormChoicesForm } from './report.types';
import { AssessmentIdOrLatest } from '../../../api/report';
import { AddCommentGridItem } from './AddCommentGridItem';
import { DownloadReportButton } from './buttons/DownloadReportButton';
import { ReturnToRecommendationsButton } from './buttons/ReturnToRecommendationsButton';
import { PdfContentChoicesGridItem } from './PdfContentChoicesGridItem';

interface CreatePdfReportPageProps {
  patient: Patient;
  assessmentId: AssessmentIdOrLatest;
}

export default function CreatePdfReportPage({ patient, assessmentId }: CreatePdfReportPageProps) {
  const form: DownloadFormChoicesForm = useForm({
    defaultValues: defaultDownloadChoices,
    mode: 'all',
  });

  return (
    <Container style={{ paddingTop: '2rem' }}>
      <Paper elevation={1} sx={{ p: 3, px: 7 }}>
        <Title />
        <ConfigurationOptions form={form} />
      </Paper>
      <ActionButtons form={form} patient={patient} assessmentId={assessmentId} />
    </Container>
  );
}

function ConfigurationOptions({ form }: { form: DownloadFormChoicesForm }) {
  return (
    <Grid container spacing={10} justifyContent={'center'}>
      <PdfContentChoicesGridItem form={form} />
      <AddCommentGridItem form={form} />
    </Grid>
  );
}

function ActionButtons({
  form,
  patient,
  assessmentId,
}: {
  form: DownloadFormChoicesForm;
  patient: Patient;
  assessmentId: AssessmentIdOrLatest;
}) {
  return (
    <Grid container spacing={3} justifyContent={'center'} sx={{ py: 7 }}>
      <Grid item xs={12} md={4}>
        <ReturnToRecommendationsButton patientId={patient.id} assessmentId={assessmentId} />
      </Grid>
      <Grid item xs={12} md={3}>
        <SaveAndCloseButton />
      </Grid>
      <Grid item xs={12} md={4}>
        <DownloadReportButton patient={patient} form={form} assessmentId={assessmentId} />
      </Grid>
    </Grid>
  );
}

function Title() {
  const { t } = useTranslation('downloadPage');

  return (
    <Typography variant={'h4'} fontWeight={'bold'} sx={{ m: 2, mb: 7 }}>
      {t('title')}
    </Typography>
  );
}
