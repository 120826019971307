import 'react-app-polyfill/ie11';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import { CssBaseline } from '@mui/material';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import { theme } from './theme';
import './fonts/rounded_elegance/rounded-elegance.css';
import './fonts/open_sans/open-sans.css';
import './fonts/montserrat/montserrat.css';
import './i18n';
import { QueryClient, QueryClientProvider } from 'react-query';
import { SettingsProvider } from './context/SettingsProvider';
import { BrowserRouter } from 'react-router-dom';
import { LoadingIndicatorProvider } from './context/LoadingIndicatorProvider';
import { SnackbarMessageProvider } from './context/SnackbarMessageProvider';
import { isStartPage } from './utils/isRedoxPage';

const queryClient = new QueryClient();

// Uncomment these lines to mock network requests during development

// if (process.env.NODE_ENV === 'development') {
//   const { worker } = require('./mocks/browser');
//   worker.start();
// }

ReactDOM.render(
  <BrowserRouter>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <CssBaseline />
          <Suspense fallback={null}>
            <LoadingIndicatorProvider>
              <SnackbarMessageProvider>
                {isStartPage() ? (
                  <App />
                ) : (
                  <SettingsProvider>
                    <App />
                  </SettingsProvider>
                )}
              </SnackbarMessageProvider>
            </LoadingIndicatorProvider>
          </Suspense>
        </QueryClientProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </BrowserRouter>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
