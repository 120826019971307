import { Recommendation } from '../api/patients';

export function isSameRecommendation(recom1: Recommendation, recom2: Recommendation): boolean {
  const compareFunctions: {
    [key in keyof Recommendation]: (
      oldValue: Recommendation[key],
      newValue: Recommendation[key],
    ) => boolean;
  } = {
    key: (oldValue, newValue) => oldValue === newValue,
    title: (oldValue, newValue) => oldValue === newValue,
    information: (oldValue, newValue) => JSON.stringify(oldValue) === JSON.stringify(newValue), //could be changed
    text: (oldValue, newValue) => oldValue === newValue,
    disabled: (oldValue, newValue) => oldValue === newValue,
    applied: () => true, //because it does not matter
    recommendedParameters: (oldValue, newValue) =>
      JSON.stringify(oldValue) === JSON.stringify(newValue), //could be changed
  };
  return Object.entries(recom1)
    .filter(([key]) => key !== 'applied')
    .map(([key, value]) => {
      const compareFunc = compareFunctions[key as keyof typeof recom1];
      const oldValue = recom2[key as keyof typeof recom1];
      // @ts-ignore
      return compareFunc ? compareFunc(oldValue, value) : false;
    })
    .reduce((previousValue, currentValue) => previousValue && currentValue, true);
}
