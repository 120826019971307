import { styled } from '@mui/material/styles';
import React, { FunctionComponent } from 'react';
import { Paper } from '@mui/material';

const PREFIX = 'StyledPaper';

const classes = {
  paper: `${PREFIX}-paper`,
};

const SP = styled(Paper)({
  [`& .${classes.paper}`]: {
    padding: '30px',
    marginTop: '20px',
  },
});

interface StyledPaperProps {
  className?: string;
}

export const StyledPaper: FunctionComponent<StyledPaperProps> = (props) => {
  return (
    <SP elevation={0} className={props.className || classes.paper}>
      {props.children}
    </SP>
  );
};
