import { useRedoxSessionId } from '../../../hooks/queries';
import useWithLoadingAndErrorIndication from '../../../hooks/loadingIndication/useWithLoadingAndErrorIndication';
import { usePatientBySessionId } from '../../../api/patients';
import { useParams } from 'react-router-dom';
import { AssessmentFormFrame } from '../assessmentForm/AssessmentFormFrame';
import { PrefilledLatestRedoxAssessment } from './redox/PrefilledLatestRedoxAssessment';
import { PrefilledRedoxAssessment } from './redox/PrefilledRedoxAssessment';
import React from 'react';
import { EmptyPlaceholderPage } from '../../core/EmptyPlaceholderPage';

export function RedoxInputAssessmentPage() {
  const sessionId = useRedoxSessionId();
  const { data: patient } = useWithLoadingAndErrorIndication(usePatientBySessionId(sessionId));
  const { assessmentId } = useParams();

  if (patient === undefined) {
    return <EmptyPlaceholderPage />;
  }

  const useLatestAssessment = assessmentId === 'latest';

  return (
    <AssessmentFormFrame patient={patient} id={sessionId}>
      {useLatestAssessment ? (
        <PrefilledLatestRedoxAssessment sessionId={sessionId} />
      ) : (
        <PrefilledRedoxAssessment sessionId={sessionId} />
      )}
    </AssessmentFormFrame>
  );
}
