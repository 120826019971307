import { useTranslation } from 'react-i18next';
import { InformationText, Section, SECTIONS_KEYWORD, TITLE_KEYWORD } from './informationText.types';
import { InformationTexts } from '../../../config/InformationTexts';

export default function useInformationText(
  informationText: typeof InformationTexts[number],
): InformationText {
  const { t } = useTranslation(informationText);
  return {
    title: t(TITLE_KEYWORD),
    sections: t(SECTIONS_KEYWORD, { returnObjects: true }) as Section[],
  };
}
