import { AxiosError } from 'axios';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { UseQueryResult } from 'react-query';
import useQueryWithLoadingIndication from './useQueryWithLoadingIndication';
import { useSnackbar } from '../snackbarMessaging/useSnackbar';

export default function useWithLoadingAndErrorIndication<T>(
  queryResult: UseQueryResult<T, AxiosError>,
): {
  isPreviousData: boolean;
  data: T | undefined;
  isLoading: boolean;
  isFetching: boolean;
} {
  const { t } = useTranslation('pages');
  const { isPreviousData, data } = useQueryWithLoadingIndication(queryResult);
  const { showMessage, removeAllMessages } = useSnackbar();

  useEffect(() => {
    if (queryResult.isError || !(data || queryResult.isLoading || queryResult.isFetching)) {
      showMessage(queryResult.error?.message ?? t('general.unknownFetchingError'), 'error');
    } else {
      removeAllMessages();
    }
  }, [
    queryResult.isError,
    queryResult.error?.message,
    data,
    queryResult.isFetching,
    queryResult.isLoading,
    showMessage,
    t,
    removeAllMessages,
  ]);

  return {
    data,
    isPreviousData,
    isLoading: queryResult.isLoading,
    isFetching: queryResult.isFetching,
  };
}
