import { Units } from '../../../../../context/SettingsProvider';
import { useSettings } from '../../../../../hooks/useSettings';
import {
  convertHeightMetricToImperialUnits,
  convertWeightMetricToImperialUnits,
} from '../../../../../utils/units';
import { DEFAULT_ASSESSMENT_VALUES_METRIC } from '../../../../../config/defaultValues';
import { AssessmentFormType } from '../../AssessmentForm';
import { AssessmentParameters, ImperialVitals, Vitals } from '../../../../../types/assessments';
import {
  DataRepresentations,
  getAllKeys,
  RepresentationMapping,
} from '../../logic/DataRepresentations';

const vitalsDataRepresentationMapping: RepresentationMapping<Vitals & ImperialVitals> = {
  feet: DataRepresentations.number,
  inches: DataRepresentations.number,

  height: DataRepresentations.number,
  weight: DataRepresentations.number,

  diastolicBloodPressure: DataRepresentations.number,
  pulse: DataRepresentations.number,
  systolicBloodPressure: DataRepresentations.number,
};

export const vitalKeys = getAllKeys(vitalsDataRepresentationMapping);

export function useResetVitals(
  form: AssessmentFormType,
  prefilledValues?: Partial<AssessmentParameters>,
) {
  const { settings } = useSettings();

  const resettedUnitIndependentValues = {
    pulse: prefilledValues?.pulse ?? DEFAULT_ASSESSMENT_VALUES_METRIC.pulse,
    systolicBloodPressure:
      prefilledValues?.systolicBloodPressure ??
      DEFAULT_ASSESSMENT_VALUES_METRIC.systolicBloodPressure ??
      null,
    diastolicBloodPressure:
      prefilledValues?.diastolicBloodPressure ??
      DEFAULT_ASSESSMENT_VALUES_METRIC.diastolicBloodPressure,
  };

  switch (settings.units) {
    case Units.Imperial:
      const { feet, inches } = convertHeightMetricToImperialUnits(
        prefilledValues?.height ?? DEFAULT_ASSESSMENT_VALUES_METRIC.height,
      );
      const weight = convertWeightMetricToImperialUnits(
        prefilledValues?.weight ?? DEFAULT_ASSESSMENT_VALUES_METRIC.weight,
      );

      return () => {
        form.reset({ ...form.getValues(), weight, inches, feet, ...resettedUnitIndependentValues });
      };
    case Units.Metric:
      return () => {
        form.reset({
          ...form.getValues(),
          weight: prefilledValues?.weight ?? DEFAULT_ASSESSMENT_VALUES_METRIC.weight,
          height: prefilledValues?.height ?? DEFAULT_ASSESSMENT_VALUES_METRIC.height,
          ...resettedUnitIndependentValues,
        });
      };
  }
}
