import { AssessmentFormType } from '../../AssessmentForm';
import { DEFAULT_ASSESSMENT_VALUES_METRIC } from '../../../../../config/defaultValues';
import { AssessmentParameters, Treatment } from '../../../../../types/assessments';
import {
  DataRepresentations,
  getAllKeys,
  RepresentationMapping,
} from '../../logic/DataRepresentations';

const treatmentDataRepresentationMapping: RepresentationMapping<Treatment> = {
  rhythmControlDesired: DataRepresentations.tickableBoolean,
};

export const treatmentKeys = getAllKeys(treatmentDataRepresentationMapping);

export function resetTreatment(
  form: AssessmentFormType,
  prefilledValues?: Partial<AssessmentParameters>,
) {
  form.reset({
    ...form.getValues(),
    ...Object.fromEntries(
      treatmentKeys.map((key) => [
        key,
        prefilledValues?.[key] ?? DEFAULT_ASSESSMENT_VALUES_METRIC[key],
      ]),
    ),
  });
}
