import React from 'react';
import { FieldErrors, FieldValues, Path, UseFormRegister } from 'react-hook-form';
import { StyledTextField } from './StyledTextField';
import { useTranslation } from 'react-i18next';
import { useSettings } from '../../../hooks/useSettings';
import { Units } from '../../../context/SettingsProvider';
import { getAgeAtDate } from '../../../utils/dates';

interface DateInputProps<T extends FieldValues> {
  name: Path<T>;
  label: string;
  register: UseFormRegister<T>;
  errors: FieldErrors;
}

export const FORMATS = {
  'dd.mm.yyyy': {
    placeholder: 'DD.MM.YYYY',
    pattern: /\d{1,2}\.\d{1,2}\.\d{4}/,
  },
  'mm/dd/yyyy': {
    placeholder: 'MM/DD/YYYY',
    pattern: /\d{1,2}\/\d{1,2}\/\d{4}/,
  },
};

export function getComponentsFromMetricDate(dateString: string) {
  const parts = dateString.split('.');
  return {
    year: parseInt(parts[2]),
    month: parseInt(parts[1]),
    day: parseInt(parts[0]),
  };
}

export function getComponentsFromImperialDate(dateString: string) {
  const parts = dateString.split('/');
  return {
    year: parseInt(parts[2]),
    month: parseInt(parts[0]),
    day: parseInt(parts[1]),
  };
}

export function DateInput<T>(props: DateInputProps<T>) {
  const { t } = useTranslation('forms');
  const { settings } = useSettings();

  const format = settings.units === Units.Metric ? FORMATS['dd.mm.yyyy'] : FORMATS['mm/dd/yyyy'];
  const register = props.register(props.name, {
    required: t<string>('errors.required'),
    pattern: {
      value: format.pattern,
      message: `Must be in the format ${format.placeholder}`,
    },
    validate: (value) => {
      const dateString = value as string;
      const components =
        settings.units === Units.Metric
          ? getComponentsFromMetricDate(dateString)
          : getComponentsFromImperialDate(dateString);
      const date = new Date(components.year, components.month - 1, components.day);
      const isValidDate =
        date.getFullYear() === components.year &&
        date.getMonth() + 1 === components.month &&
        date.getDate() === components.day;
      if (!isValidDate) return 'Invalid date';

      const age = getAgeAtDate(date, new Date());
      if (18 > age || age > 95) return 'Age must be between 18 and 95';

      return true;
    },
  });

  return (
    <StyledTextField
      name={props.name}
      placeholder={format.placeholder}
      label={props.label}
      errors={props.errors}
      register={register}
    />
  );
}
