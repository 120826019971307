import { Patient } from '../../api/patients';
import SubHeader from '../core/Header/SubHeader/SubHeader';
import { Grid, Stack } from '@mui/material';
import React from 'react';
import { AssessmentButton } from './NavigationComponents/AssessmentButton';
import { AssessmentIdOrLatest } from '../../api/report';
import { EvaluationButton } from './NavigationComponents/EvaluationButton';
import { AssessmentDateDropdown } from './NavigationComponents/AssessmentDateDropdown';
import { TimeseriesButton } from './NavigationComponents/TimeseriesButton';
import { DifferenceButton } from './NavigationComponents/DifferenceButton';

interface WebHeaderProps {
  patient: Patient;
  showTrendButtons?: boolean;
  showPrint?: boolean;
  showEvaluationButton?: boolean;
  showAssessmentButton?: boolean;
  showDateDropdown?: boolean;
  assessmentId?: AssessmentIdOrLatest;
}
export function WebHeader({
  patient,
  showAssessmentButton = false,
  showEvaluationButton = false,
  showDateDropdown = false,
  assessmentId,
  showTrendButtons = false,
}: WebHeaderProps) {
  if (!assessmentId) {
    assessmentId = 'latest';
  }
  return (
    <SubHeader>
      <Grid container alignContent={'baseline'}>
        <Grid item sx={{ mt: 2, pb: 0, mb: 0 }} justifyContent={'center'} xs={10}>
          {showAssessmentButton && (
            <AssessmentButton patientId={patient.id} assessmentId={assessmentId} />
          )}
          {showEvaluationButton && (
            <EvaluationButton patientId={patient.id.toString()} assessmentId={assessmentId} />
          )}
          {showTrendButtons && <TimeseriesButton patientId={patient.id} />}
          {showTrendButtons && <DifferenceButton patientId={patient.id} />}
        </Grid>
        <Grid item sx={{ pt: 1 }} xs={2}>
          <Stack direction={'row'} spacing={3} justifyContent={'flex-end'}>
            {showDateDropdown && (
              <AssessmentDateDropdown
                patientId={patient.id.toString()}
                assessmentId={assessmentId}
              />
            )}
          </Stack>
        </Grid>
      </Grid>
    </SubHeader>
  );
}
