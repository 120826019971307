export const SERVER_URL = process.env.REACT_APP_SERVER_URL || 'http://localhost:3000/api/';
export const PATIENTS_URL = `${SERVER_URL}patients`;
export const SETTINGS_URL = `${SERVER_URL}settings`;
export const ASSESSMENTS_URL = `${SERVER_URL}assessments`;
export const RISK_URL = `${SERVER_URL}calculations/risk`;
export const REDOX_URL = `${SERVER_URL}redox`;
export const REPORT_URL = `${SERVER_URL}report`;

export const DEV_MODE = process.env.REACT_APP_DEV_MODE
  ? process.env.REACT_APP_DEV_MODE === 'true'
  : true;
export const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL || 'http://localhost:3000/';
export const LOGOUT_URL = `${FRONTEND_URL}oauth/logout?redirect=${FRONTEND_URL}`;
export const PATIENT_URL = `${FRONTEND_URL}patient/`;

export const HOMEPAGE_URL = 'https://www.tenac.io/';
export const OPTICOR_PRODUCT_PAGE_URL =
  'https://tenac.io/digital-personalized-cardiovascular-care/';

export const HOMEPAGE_IMPRESSUM_URL = 'https://www.tenac.io/legal-notice/';
export const HOMEPAGE_PRIVACY_URL = 'https://www.tenac.io/privacy-policy-2/';
