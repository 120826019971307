import { AssessmentFormType } from '../../AssessmentForm';
import { NonEditableAssessmentListItem } from '../../subComponents/NonEditableAssessmentListItem';
import React from 'react';
import { List } from '@mui/material';
import { treatmentKeys } from './treatment.logic';
import { useTranslation } from 'react-i18next';

export default function TreatmentNonEditable({ form }: { form: AssessmentFormType }) {
  const { t } = useTranslation('forms');
  const values = form.getValues();

  return (
    <List disablePadding={true} dense>
      {treatmentKeys.map((key) => (
        <NonEditableAssessmentListItem
          isChecked={values[key]}
          withDivider={false}
          label={t(`treatment.${key}.label`)}
          tooltip={t(`treatment.${key}.tooltip`)}
          key={key}
        />
      ))}
    </List>
  );
}
