import Grid from '@mui/material/Grid';
import { StyledTextField } from '../../../../core/input-components/StyledTextField';
import { useTranslation } from 'react-i18next';
import { FieldErrors, Path, UseFormRegister } from 'react-hook-form';
import { MinMaxType, ruleHelper } from '../../logic/rule-helper';

interface Props<T> {
  register: UseFormRegister<T>;
  errors: FieldErrors;
  disableInchesField: boolean;
}

const WEIGHT_FIELD_NAME = 'weight' as const;
const FEET_FIELD_NAME = 'feet' as const;
const INCHES_FIELD_NAME = 'inches' as const;

const ALLOWED_VALUES: MinMaxType<
  typeof WEIGHT_FIELD_NAME | typeof FEET_FIELD_NAME | typeof INCHES_FIELD_NAME
> = {
  weight: {
    min: 60,
    max: 900,
  },
  feet: {
    min: 2,
    max: 9,
  },
  inches: {
    min: 0,
    max: 11,
  },
};

export function ImperialWeightHeight<T>(props: Props<T>) {
  const { t } = useTranslation('forms');

  return (
    <Grid container columnSpacing={3} rowSpacing={1}>
      <Grid item xs={12} sm={4} md={12}>
        <StyledTextField
          name={WEIGHT_FIELD_NAME}
          type="number"
          label={t('vitals.weight')}
          register={props.register(
            WEIGHT_FIELD_NAME as Path<T>,
            ruleHelper(t, ALLOWED_VALUES, WEIGHT_FIELD_NAME),
          )}
          errors={props.errors}
          adornment="lb"
        />
      </Grid>
      <Grid item xs={6} sm={4} md={6}>
        <StyledTextField
          name={FEET_FIELD_NAME}
          type="number"
          label={t('vitals.height') + ' (feet)'}
          register={props.register(
            FEET_FIELD_NAME as Path<T>,
            ruleHelper(t, ALLOWED_VALUES, FEET_FIELD_NAME),
          )}
          errors={props.errors}
          adornment="ft"
        />
      </Grid>
      <Grid item xs={6} sm={4} md={6}>
        <StyledTextField
          name={INCHES_FIELD_NAME}
          type="number"
          label={t('vitals.height') + ' (inches)'}
          register={props.register(
            INCHES_FIELD_NAME as Path<T>,
            ruleHelper(t, ALLOWED_VALUES, INCHES_FIELD_NAME, true, props.disableInchesField),
          )}
          errors={props.errors}
          adornment="in"
          disabled={props.disableInchesField}
        />
      </Grid>
    </Grid>
  );
}
