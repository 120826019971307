import { Stack, Switch, Typography } from '@mui/material';
import React from 'react';

export function DifferenceSwitch({
  absolute,
  setAbsolute,
  translator,
}: {
  absolute: boolean;
  setAbsolute: (absolute: boolean) => void;
  translator: (key: string) => string;
}) {
  const handleChange = (event: any) => {
    setAbsolute(!event.target.checked);
  };

  return (
    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
      <Typography sx={{ margin: 0 }}>
        {translator('patientProfile.trend.options.difference.differenceLabel')}
      </Typography>
      <Stack direction={'row'} spacing={1} alignItems={'center'}>
        <Typography
          variant={'h6'}
          color={absolute ? 'primary.main' : 'neutral.main'}
          sx={{ margin: 0 }}
        >
          {translator('patientProfile.trend.options.difference.leftLabel')}
        </Typography>
        <Switch
          checked={!absolute}
          onChange={handleChange}
          size={'small'}
          sx={{
            margin: 0,
            '& .MuiSwitch-switchBase': {
              color: 'secondary.dark',
              '&.Mui-checked': {
                color: 'secondary.dark',
                '&:hover': {
                  backgroundColor: `primary.main`,
                },
                '& + .MuiSwitch-track': {
                  backgroundColor: 'secondary.dark',
                },
              },
              '&:hover': {
                backgroundColor: `primary.main`,
              },
            },
            '& .MuiSwitch-thumb': {
              color: 'primary.main',
            },
            '& .MuiSwitch-track': {
              backgroundColor: 'secondary.dark',
            },
          }}
        />
        <Typography
          variant={'h6'}
          color={absolute ? 'neutral.main' : 'primary.main'}
          sx={{ margin: 0 }}
        >
          {translator('patientProfile.trend.options.difference.rightLabel')}
        </Typography>
      </Stack>
    </Stack>
  );
}
