import { Checkbox, FormControlLabel, ListItem, Typography } from '@mui/material';
import React from 'react';
import { DocumentPopup } from '../../../core/DocumentPopup/DocumentPopup';
import { Recommendation } from '../../../../api/patients';
import ListAltIcon from '@mui/icons-material/ListAlt';

interface RecommendationItemProps {
  recommendation: Recommendation;
  onToggle: (r: Recommendation) => void;
}

export default function RecommendationItem(props: RecommendationItemProps) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onToggle({ ...props.recommendation, applied: event.target.checked });
  };

  const hasRecommendedParameters =
    Object.keys(props.recommendation.recommendedParameters).length !== 0;

  return (
    <ListItem>
      <FormControlLabel
        sx={{
          alignItems: 'start',
          cursor: hasRecommendedParameters ? undefined : 'default',
        }}
        control={
          <Checkbox
            disabled={props.recommendation.disabled}
            icon={
              props.recommendation.disabled || !hasRecommendedParameters ? (
                <ListAltIcon />
              ) : undefined
            }
            checked={hasRecommendedParameters ? props.recommendation.applied : false}
            name={props.recommendation.key}
            onChange={handleChange}
            sx={{
              pointerEvents: hasRecommendedParameters ? undefined : 'none',
            }}
            color={'primary'}
          />
        }
        label={<RecommendationLabel recommendation={props.recommendation} />}
      />
    </ListItem>
  );
}

function RecommendationLabel({ recommendation }: { recommendation: Recommendation }) {
  return (
    <React.Fragment>
      <Typography fontWeight={'bold'} sx={{ pt: 0.75 }}>
        {recommendation.title}
        <DocumentPopup
          document={{
            title: recommendation.title,
            sections: recommendation.information,
          }}
        />
      </Typography>
      <div style={{ whiteSpace: 'pre-wrap' }}>{recommendation.text}</div>
    </React.Fragment>
  );
}
