import { useQuery } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { EvaluationResult, Patient } from './patients';
import { REDOX_URL } from '../config/urlConfiguration';
import {
  Assessment,
  AssessmentParameters,
  ImperialAssessmentParameters,
} from '../types/assessments';

interface RedoxPatientInfo {
  patient: Patient;
  parameters: Partial<ImperialAssessmentParameters>;
}

async function getRedoxPrefilledAssessment(sessionId: string): Promise<RedoxPatientInfo> {
  return (await axios.get(`${REDOX_URL}/prefilledAssessment?session=${sessionId}`)).data;
}

export function useRedoxPrefilledAssessment(sessionId: string) {
  return useQuery<RedoxPatientInfo, AxiosError>(['redoxPrefilledAssessment', sessionId], () =>
    getRedoxPrefilledAssessment(sessionId),
  );
}

export async function getRedoxPatient(sessionId: string): Promise<Patient> {
  return (await axios.get(`${REDOX_URL}/patient?session=${sessionId}`)).data;
}

export async function getRedoxEvaluationResult(sessionId: string): Promise<EvaluationResult> {
  const result = (await axios.get(`${REDOX_URL}/evaluate?session=${sessionId}`)).data;
  return {
    ...result,
    date: new Date(result.date),
  };
}

export async function getRedoxAdjustedRisks(sessionId: string, appliedRecommendations: string[]) {
  return (
    await axios.get(`${REDOX_URL}/adjustedRisks`, {
      params: {
        session: sessionId,
        appliedRecommendations,
      },
    })
  ).data;
}

export async function getRedoxLatestAssessment(sessionId: string): Promise<Assessment> {
  return (await axios.get(`${REDOX_URL}/latestAssessment?session=${sessionId}`)).data;
}

async function createAssessment(sessionId: string, assessment: AssessmentParameters) {
  return (await axios.post(`${REDOX_URL}/assessment?session=${sessionId}`, assessment)).data;
}

async function updateAssessment(sessionId: string, assessment: Assessment): Promise<Assessment> {
  return (
    await axios.put(`${REDOX_URL}/assessment/${assessment.id}?session=${sessionId}`, assessment)
  ).data;
}

export async function saveToRedox(sessionId: string, formData: FormData): Promise<AxiosResponse> {
  return await axios.post(`${REDOX_URL}/saveToRedox?session=${sessionId}`, formData);
}

export const redoxApi = {
  createAssessment,
  updateAssessment,
};
