import { Button, Typography } from '@mui/material';
import { NavigationButtonProps } from './ActivatedButton';

export function UnactivatedButton({
  onClick,
  icon,
  label,
  color = 'secondary.light',
}: NavigationButtonProps) {
  return (
    <Button
      onClick={onClick}
      size="large"
      startIcon={icon}
      sx={{
        '&:hover': {
          border: 'primary.dark',
        },
        mr: 2,
      }}
    >
      <Typography sx={{ color: color, mb: 0, ml: 1 }} variant={'h6'} display={'inline'}>
        {label}
      </Typography>
    </Button>
  );
}
