import React from 'react';
import { Line } from 'react-chartjs-2';
import { Patient, useTrend } from '../../../api/patients';
import { Container, Divider, Grid, Typography, useTheme } from '@mui/material';
import { RiskComponents } from '../../../types/risk';
import { useTranslation } from 'react-i18next';
import { useSettings } from '../../../hooks/useSettings';
import {
  CategoryScale,
  Chart,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { LabelOptions } from 'chartjs-plugin-datalabels/types/options';
import { WebHeader } from '../WebHeader';

Chart.register(
  ChartDataLabels,
  Tooltip,
  Legend,
  LinearScale,
  CategoryScale,
  PointElement,
  LineElement,
);

interface TrendProps {
  patient: Patient;
}

const CHART_HEIGHT = 50;
const MAX_RISK_VALUE = 7;

function getChartOptions(maxValue: number) {
  const dataLabels: LabelOptions = {
    align: 'top',
    font: {
      weight: 'bold',
    },
  };
  return {
    layout: {
      padding: 20,
    },
    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },
      datalabels: dataLabels,
    },
    scales: {
      yAxes: {
        display: false,
        min: 0,
        max: maxValue,
      },
      xAxes: {
        display: false,
      },
    },
  };
}

export function Trend({ patient }: TrendProps) {
  const theme = useTheme();
  const { helpers } = useSettings();
  const primaryColor = theme.palette.primary.main;

  const { t } = useTranslation('pages');

  const { isLoading, isError, data, error } = useTrend(patient.id);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError || !data) {
    return <div>Error: {error?.message}</div>;
  }

  if (data.length <= 1)
    return (
      <>
        <Typography variant={'h4'} align={'center'} sx={{ mt: 1 }}>
          {t('patientProfile.trend.noTrendDataLabel.noDataAvailableLabel')}
        </Typography>
        <Typography align={'center'}>
          {t('patientProfile.trend.noTrendDataLabel.patientNeedsMoreThanOneAssessmentLabel')}
        </Typography>
      </>
    );

  const getChartData = (key: keyof RiskComponents) => ({
    labels: data.map((tc) => helpers.formatDate(tc.date)),
    datasets: [
      {
        label: key,
        data: data.map((tc) => tc.risks[key]),
        ...{
          fill: false,
          pointBorderColor: primaryColor,
          pointBackgroundColor: 'white',
          pointBorderWidth: 3,
          pointRadius: 4,
          borderColor: primaryColor,
        },
      },
    ],
  });

  const riskValues = data.reduce(
    (a, c) => [...a, c.risks.bleeding, c.risks.mortality, c.risks.stroke],
    [] as number[],
  );
  const maxRiskValue = Math.max(...riskValues, MAX_RISK_VALUE);

  const riskComponentKeys: Array<keyof RiskComponents> = ['mortality', 'stroke', 'bleeding'];

  return (
    <>
      <WebHeader patient={patient} />
      <Container style={{ backgroundColor: '', padding: '2rem' }}>
        <Grid
          container
          sx={{
            fontWeight: 'bold',
            padding: '1rem 2rem',
          }}
        >
          <Grid item xs={2} />
          <Grid item container xs={10} justifyContent="space-between">
            {data.map((tc) => (
              <Grid item key={tc.date.toISOString()}>
                {helpers.formatDate(tc.date)}
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Grid container sx={{ padding: '1rem 2rem' }}>
          {riskComponentKeys.map((key) => (
            <React.Fragment key={key}>
              <Grid item container xs={2} alignItems="center" justifyContent={'space-between'}>
                <span style={{ fontWeight: 'bold' }}>
                  {t(`patientProfile.trend.chartLabels.${key}`)}
                </span>
                <Divider orientation={'vertical'} />
              </Grid>
              <Grid item xs={10}>
                <Line
                  data={getChartData(key)}
                  options={getChartOptions(maxRiskValue)}
                  height={CHART_HEIGHT}
                />
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </Container>
    </>
  );
}
