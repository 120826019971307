import useWithLoadingAndErrorIndication from '../../../../hooks/loadingIndication/useWithLoadingAndErrorIndication';
import { useWebAssessment } from '../../../../api/assessments';
import React from 'react';
import { AssessmentIdOrLatest } from '../../../../api/report';
import { NonEditableAssessmentForm } from '../../assessmentForm/NonEditableAssessmentForm';
import { LoadingIndicator } from '../../../core/LoadingIndicator';

export function PrefilledWebAssessment({
  patientId,
  assessmentId,
}: {
  patientId: string;
  assessmentId: AssessmentIdOrLatest;
}) {
  const { data: assessment, isLoading, isFetching } = useWithLoadingAndErrorIndication(
    useWebAssessment(patientId, assessmentId),
  );

  if (isLoading || isFetching || !assessment) {
    return <LoadingIndicator relativeVerticalPosition={100} />;
  } else {
    return <NonEditableAssessmentForm prefilledValues={assessment} />;
  }
}
