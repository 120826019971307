import axios, { AxiosError } from 'axios';
import { SETTINGS_URL } from '../config/urlConfiguration';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Settings } from '../context/SettingsProvider';

async function get(): Promise<Settings> {
  return (await axios.get(SETTINGS_URL)).data;
}

async function save(settings: Settings): Promise<Settings> {
  return (await axios.post(SETTINGS_URL, settings)).data;
}

export function useSavedSettings() {
  return useQuery<Settings, AxiosError>(['settings'], get);
}

export function useMutateSettings() {
  const queryClient = useQueryClient();
  return useMutation<Settings, AxiosError, Settings>(save, {
    onSuccess: (data) => {
      queryClient.setQueryData(['settings'], data);
    },
  });
}
