import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material';
import { useNavigateToTrendTimeseriesPage } from '../../../config/urlPaths';
import { ActivatedButton, isActivated } from './NavigationComponents/ActivatedButton';
import { UnactivatedButton } from './NavigationComponents/UnactivatedButton';
import React from 'react';
import { ToTrendIcon } from '../customIcons/ToTrendIcon';

export function RisksAndTrendsButton({ patientId }: { patientId: number }) {
  const { t } = useTranslation('header');
  const location = useLocation();
  const theme = useTheme();

  const toTrendsPage = useNavigateToTrendTimeseriesPage();
  const label = t('risksAndTrendsButton');
  return isActivated(location.pathname, 'trend') ? (
    <ActivatedButton
      label={label}
      icon={<ToTrendIcon color={theme.palette.primary.main} fontSize={'large'} />}
    />
  ) : (
    <UnactivatedButton
      onClick={() => toTrendsPage(patientId)}
      icon={<ToTrendIcon color={theme.palette.secondary.light} fontSize={'large'} />}
      label={label}
    />
  );
}
