import { TFunction } from 'i18next';
import { RegisterOptions } from 'react-hook-form';

export type MinMaxType<T extends string | number | symbol> = {
  [key in T]: {
    min: number;
    max: number;
  };
};

export function ruleHelper<T extends MinMaxType<keyof T>>(
  t: TFunction,
  allowedValues: T,
  key: keyof T,
  required: boolean = false,
  disabled?: boolean,
): RegisterOptions {
  const req = required ? { required: t('errors.required') } : {};
  return {
    ...req,
    min: {
      value: allowedValues[key].min,
      message: t('errors.between', { min: allowedValues[key].min, max: allowedValues[key].max }),
    },
    max: {
      value: allowedValues[key].max,
      message: t('errors.between', { min: allowedValues[key].min, max: allowedValues[key].max }),
    },
    disabled: disabled,
    pattern: {
      value: /^[\d*]+$/,
      message: t('errors.integer'),
    },
  };
}
