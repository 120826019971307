import React, { useEffect, useState } from 'react';
import { Patient, PatientParameters, patientsApi, usePatientsSearch } from '../../api/patients';
import { CreatePatientItem } from './patientItems/CreatePatientItem';
import { useQueryClient } from 'react-query';
import { PatientSearchField } from './patientSearch/PatientSearchField';
import { classes, StyledContainer } from './PatientsListPage.style';
import { PatientItem } from './patientItems/PatientItem';
import { PatientsListHeading } from './PatientsListHeading';
import { useWithLoadingIndication } from '../../hooks/loadingIndication/useWithLoadingIndication';
import useWithErrorsInSnackbar from '../../hooks/snackbarMessaging/useWithErrorsInSnackbar';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '../../hooks/snackbarMessaging/useSnackbar';
import { LoadingIndicator } from '../core/LoadingIndicator';
import { useNavigateToNewWebAssessment } from '../../config/urlPaths';
import { deleteRecommendationsSessionStorage } from '../../state/recommendations';

export function PatientsListPage() {
  const { t } = useTranslation('pages');
  const navigateToNewAssessment = useNavigateToNewWebAssessment();
  const queryClient = useQueryClient();
  const { showMessage, removeAllMessages } = useSnackbar();

  const [patients, setPatients] = useState<Patient[]>([]);
  const [searchTerm, updateSearchTerm] = useState('');

  const {
    data: patientData,
    isPreviousData,
    isError,
    error,
    isLoading: isLoadingPatients,
    isFetching: isFetchingPatients,
  } = usePatientsSearch(searchTerm);

  useEffect(() => {
    deleteRecommendationsSessionStorage();
  }, []);

  useEffect(() => {
    if (!patientData && (isLoadingPatients || isFetchingPatients)) {
      return;
    }
    if (!patientData || patientData.length === 0) {
      showMessage(t('patients.noPatientsFound'), 'info');
      setPatients([]);
      return;
    }
    if (isError) {
      showMessage(error?.message ?? t('general.unknownFetchingError'), 'error');
    }
    if (!isPreviousData) {
      removeAllMessages();
      setPatients(patientData);
    }
  }, [
    patientData,
    setPatients,
    isPreviousData,
    t,
    showMessage,
    isError,
    error,
    isLoadingPatients,
    isFetchingPatients,
    removeAllMessages,
  ]);

  const handleCreate = useWithLoadingIndication(
    useWithErrorsInSnackbar(async (parameters: PatientParameters) => {
      const patient = await patientsApi.createPatient(parameters);
      await queryClient.invalidateQueries('patients');
      navigateToNewAssessment(patient.id);
    }),
  );

  const renderPatients = () =>
    patients
      .sort(patientListOrderFunction)
      .map((patient) => <PatientItem key={patient.id} patient={patient} />);

  return (
    <StyledContainer maxWidth="md" className={classes.container}>
      <PatientsListHeading />
      <PatientSearchField onNewSearchTerm={updateSearchTerm} />
      <CreatePatientItem onCreate={handleCreate} />
      {isLoadingPatients ? <LoadingIndicator relativeVerticalPosition={100} /> : renderPatients()}
    </StyledContainer>
  );
}

function patientListOrderFunction(a: Patient, b: Patient): number {
  if (a.lastName !== b.lastName) {
    return a.lastName.localeCompare(b.lastName);
  }
  if (a.firstName !== b.firstName) {
    return a.firstName.localeCompare(b.firstName);
  }
  return a.birthday.localeCompare(b.birthday);
}
