import React from 'react';
import { Container } from '@mui/material';
import { useParams, useSearchParams } from 'react-router-dom';
import { usePatientById } from '../../api/patients';
import { Evaluation } from './evaluation/Evaluation';
import useWithLoadingAndErrorIndication from '../../hooks/loadingIndication/useWithLoadingAndErrorIndication';
import { WebHeader } from './WebHeader';
import CreatePdfReportPage from './report/CreatePdfReportPage';
import { PATIENT_PROFILE_SUBPAGES } from '../../config/urlPaths';
import { Trend } from './trend/Trend';
import { parseAssessmentId } from './utils';

interface RouteParams {
  patientId: string;
  subpage: string;
}

export function WebPatientProfile() {
  const { patientId, subpage } = useParams() as RouteParams;
  const [searchParams] = useSearchParams({ assessmentId: 'latest' });
  const assessmentId = searchParams.get('assessmentId');

  const { data: patient } = useWithLoadingAndErrorIndication(usePatientById(patientId));

  if (!patient) {
    return <div />;
  }

  switch (subpage) {
    case PATIENT_PROFILE_SUBPAGES.TREND:
      return <Trend patient={patient} />;
    case PATIENT_PROFILE_SUBPAGES.DOWNLOAD:
      return (
        <>
          <WebHeader patient={patient} />
          <CreatePdfReportPage patient={patient} assessmentId={parseAssessmentId(assessmentId)} />
        </>
      );
    default:
      return (
        <Container maxWidth="lg" sx={{ paddingTop: '2rem', paddingBottom: '3rem' }}>
          {
            <>
              <WebHeader patient={patient} showTrendButtons />
              <Evaluation patientId={patientId} />
            </>
          }
        </Container>
      );
  }
}
