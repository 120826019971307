import Grid from '@mui/material/Grid';
import { Divider, Typography } from '@mui/material';
import React from 'react';

export function NumericalParameterLine({
  title,
  value,
  unitLabel,
  withDivider = true,
}: {
  title: string;
  value: string | number | null | undefined;
  unitLabel?: string;
  withDivider?: boolean;
}) {
  return (
    <>
      <Grid container direction={'row'}>
        <Grid item xs={6}>
          <Typography variant={'h6'} sx={{ m: 0, p: 0 }} fontWeight={'normal'}>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant={'h6'} sx={{ fontWeight: 'bold', m: 0, p: 0, textAlign: 'center' }}>
            {value ?? '--'}
          </Typography>
        </Grid>
        {unitLabel && (
          <Grid item xs={3}>
            <Typography variant={'h6'} sx={{ fontWeight: 'lighter', m: 0, p: 0 }}>
              {unitLabel}
            </Typography>
          </Grid>
        )}
      </Grid>
      {withDivider && <Divider sx={{ mb: 1, mt: 1, p: 0 }} />}
    </>
  );
}
