import { Patient, usePatientById } from '../../../api/patients';
import { Box, Grid, Typography } from '@mui/material';
import { CreateNewAssessmentButton } from '../evaluation/buttons';
import { Calendar } from './Calendar';
import { useParams } from 'react-router-dom';
import useWithLoadingAndErrorIndication from '../../../hooks/loadingIndication/useWithLoadingAndErrorIndication';
import { EmptyPlaceholderPage } from '../../core/EmptyPlaceholderPage';
import Container from '@mui/material/Container';
export interface VisitsProps {
  patient: Patient;
}

interface PatientProps {
  patientId: string;
}

export function Visits() {
  const { patientId } = useParams() as PatientProps;
  const { data: patient } = useWithLoadingAndErrorIndication(usePatientById(patientId));

  if (!patient) {
    return <EmptyPlaceholderPage />;
  }
  return (
    <Container maxWidth="lg" sx={{ padding: '2rem' }}>
      <Box sx={{ p: 0 }}>
        <Grid container justifyContent={'space-between'}>
          <Grid item>
            <Typography variant={'h5'}>
              Assessments of {patient.firstName} {patient.lastName}
            </Typography>
          </Grid>
          <Grid item>
            <CreateNewAssessmentButton patientId={patient.id} />
          </Grid>
        </Grid>
        <Calendar patient={patient} />
      </Box>
    </Container>
  );
}
