import { useAssessmentDifferenceForDifferenceTooltip } from './customTooltip.logic';
import { LoadingIndicator } from '../../../../core/LoadingIndicator';
import React from 'react';
import { Card } from '@mui/material';
import { AssessmentDifferenceComponent } from './AssessmentDifferenceComponent';

export function RiskDifferenceTooltip({ active, payload, setClickable }: any) {
  const { isLoading, isError, data, error } = useAssessmentDifferenceForDifferenceTooltip(
    active,
    payload,
  );

  if (isLoading) {
    return <LoadingIndicator relativeVerticalPosition={100} />;
  }

  if (isError || !data) {
    return <div>Error: {error?.message}</div>;
  }

  if (active && payload && payload.length) {
    return (
      <Card
        onMouseEnter={() => setClickable(true)}
        onMouseLeave={() => setClickable(false)}
        sx={{
          padding: 2,
          backgroundColor: 'neutral.light',
          pointerEvents: 'auto',
          width: '20rem',
        }}
      >
        <AssessmentDifferenceComponent assessmentDifference={data} />
      </Card>
    );
  }

  return null;
}
