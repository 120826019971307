import useWithLoadingAndErrorIndication from '../../../hooks/loadingIndication/useWithLoadingAndErrorIndication';
import { usePatientById } from '../../../api/patients';
import { EmptyPlaceholderPage } from '../../core/EmptyPlaceholderPage';
import React from 'react';
import { AssessmentFormFrame } from '../assessmentForm/AssessmentFormFrame';
import { PrefilledWebAssessment } from './web/PrefilledWebAssessment';
import { parseAssessmentId } from '../../patient-profile/utils';
import { useParams } from 'react-router';

export function ViewOnlyWebAssessmentPage() {
  const { patientId, assessmentId } = useParams();
  const assessmentIdOrLatest = parseAssessmentId(assessmentId);

  if (patientId === undefined) {
    throw Error('PatientId Not Found');
  }

  const { data: patient } = useWithLoadingAndErrorIndication(usePatientById(patientId));

  if (patient === undefined) {
    return <EmptyPlaceholderPage />;
  }

  return (
    <AssessmentFormFrame
      patient={patient}
      id={patientId}
      assessmentId={assessmentIdOrLatest}
      showAssessmentButton
      showEvaluationButton
      showDateDropdown
    >
      <PrefilledWebAssessment
        patientId={patientId}
        assessmentId={assessmentIdOrLatest}
      ></PrefilledWebAssessment>
    </AssessmentFormFrame>
  );
}
