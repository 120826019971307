import { AssessmentFormType } from '../../AssessmentForm';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LiteralParameterLine } from '../../subComponents/LiteralParameterLine';
import { medicationKeysBooleanValues, medicationKeysEnumValues } from './medications.logic';

export default function MedicationsNonEditable({
  form,
  showDiabetesMedication,
  showContraindicationForOAC,
}: {
  form: AssessmentFormType;
  showDiabetesMedication: boolean;
  showContraindicationForOAC: boolean;
}) {
  const { t } = useTranslation('forms');
  const values = form.getValues();

  return (
    <Stack>
      {medicationKeysBooleanValues.map((key) => (
        <LiteralParameterLine
          title={t(`medications.${key}.label`)}
          tooltip={t(`medications.${key}.tooltip`)}
          value={values[key]}
          key={key}
        />
      ))}
      {medicationKeysEnumValues
        .filter(
          (key) =>
            (showDiabetesMedication || key !== 'diabetesMedication') &&
            (showContraindicationForOAC || key !== 'contraindicationForOAC'),
        )
        .map((key, index, array) => (
          <LiteralParameterLine
            title={t(`medications.${key}.label`)}
            value={t(`medications.${key}.values.${values[key]}.label`).toString()}
            withDivider={index !== array.length - 1}
            key={key}
            tooltip={t(`medications.${key}.tooltip`)}
          />
        ))}
    </Stack>
  );
}
