import { AssessmentIdOrLatest } from '../../api/report';

export function parseAssessmentId(assessmentId?: string | 'latest' | null): AssessmentIdOrLatest {
  if (!assessmentId || assessmentId === 'latest') {
    return 'latest';
  }
  const id = Number.parseInt(assessmentId);

  if (isNaN(id)) {
    return 'latest';
  } else {
    return id;
  }
}
