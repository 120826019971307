import Grid from '@mui/material/Grid';
import { Divider, Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import LabelWithTooltip from '../../../core/input-components/LabelWithTooltip/LabelWithTooltip';

export function LiteralParameterLine({
  title,
  value,
  withDivider = true,
  tooltip,
}: {
  title: string;
  value: string | number | null | undefined | boolean;
  withDivider?: boolean;
  tooltip?: string;
}) {
  const { t } = useTranslation('pages');
  const getValue = () => {
    if (typeof value === 'boolean') {
      return value ? t('general.yes') : t('general.no');
    }
    return value ?? '--';
  };
  return (
    <>
      <Grid container direction={'row'}>
        <Grid item xs={6}>
          {tooltip && tooltip !== '' ? (
            <Stack direction={'row'}>
              <LabelWithTooltip label={<LineTitleLabel text={title} />} tooltipText={tooltip} />
            </Stack>
          ) : (
            <LineTitleLabel text={title} />
          )}
        </Grid>
        <Grid item xs={6} justifyContent={'center'}>
          <Typography variant={'h6'} sx={{ fontWeight: 'bold', m: 0, p: 0, textAlign: 'center' }}>
            {getValue()}
          </Typography>
        </Grid>
      </Grid>
      {withDivider && <Divider sx={{ mb: 1, mt: 1, p: 0 }} />}
    </>
  );
}

function LineTitleLabel({ text }: { text: string }) {
  return (
    <Typography variant={'h6'} sx={{ m: 0, p: 0, fontWeight: 'lighter' }}>
      {text}
    </Typography>
  );
}
