import { orderedUsedRecommendationCategories } from './recommendationItem.logic';
import { useTranslation } from 'react-i18next';
import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { RecommendationCategory } from '../../../../api/report';

export function RecommendationCategorySelection({
  currentValue,
  onChange,
}: {
  currentValue: RecommendationCategory;
  onChange: (newCategory: RecommendationCategory) => void;
}) {
  const { t } = useTranslation('pages');

  return (
    <ToggleButtonGroup
      size={'large'}
      color={'primary'}
      value={currentValue}
      exclusive
      onChange={(event, newCategory: RecommendationCategory | null) => {
        if (newCategory === null) {
          return;
        } //make sure one is always selected
        onChange(newCategory);
      }}
      sx={{ justifyContent: 'center', padding: '0 1.5rem 0 1.5rem' }}
      fullWidth={true}
    >
      {orderedUsedRecommendationCategories.map((category, index) => (
        <ToggleButton
          value={category}
          key={index}
          sx={{
            ...(category === currentValue
              ? {
                  borderWidth: '0 0 3.5px 0 ',
                  borderStyle: 'solid',
                  borderColor: 'primary.main',
                }
              : {}),
          }}
        >
          <Typography
            variant="h6"
            sx={(theme) => ({
              textTransform: 'none',
              margin: 0.3,
              color: theme.palette.action.active,
            })}
          >
            {t(`patientProfile.evaluation.recommendationCategories.${category}`)}
          </Typography>
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}
