import { PatientParameters, Recommendation } from './patients';
import axios from 'axios';
import { REPORT_URL } from '../config/urlConfiguration';

export enum RecommendationCategory {
  recommendation = 'recommendation',
  consideration = 'consideration',
}

export type RecommendationCategoryReportConfiguration = {
  [RecommendationCategory.recommendation]: 'all' | 'none' | 'applied' | 'notApplied';
  [RecommendationCategory.consideration]: 'all' | 'none';
};

export interface PDFReportConfiguration {
  include: {
    personalData: boolean;
    vitals: boolean;
    optionalParameters: boolean;
    medicalHistory: boolean;
    medications: boolean;
    riskProfile: boolean;
    recommendations: RecommendationCategoryReportConfiguration;
  };
}

export type AssessmentIdOrLatest = number | 'latest';

export interface PDFReportRequest {
  configuration: PDFReportConfiguration;
  data: {
    patient: PatientParameters;
    patientId: number;
    recommendations?: Recommendation[];
    assessment?: AssessmentIdOrLatest;
  };
  attendingPhysicians: Array<{ firstName: string; lastName: string }>;
  comment?: string | null;
}

export type Base64String = string;

export interface PDFReport {
  report: Base64String;
}

export async function getPDFReport(from: PDFReportRequest): Promise<PDFReport> {
  return (await axios.post(`${REPORT_URL}/pdfFormat`, from)).data;
}

export function getRecommendationCategory(recommendation: Recommendation): RecommendationCategory {
  const hasRecommendedParameters = Object.keys(recommendation.recommendedParameters).length !== 0;

  if (hasRecommendedParameters) {
    return RecommendationCategory.recommendation;
  }

  return RecommendationCategory.consideration;
}
