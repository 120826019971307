import useWithLoadingAndErrorIndication from '../../../hooks/loadingIndication/useWithLoadingAndErrorIndication';
import { usePatientById } from '../../../api/patients';
import { UnactivatedButton } from './NavigationComponents/UnactivatedButton';
import { useNavigateToPatientList } from '../../../config/urlPaths';
import { NavigateBefore } from '@mui/icons-material';

export function ToPatientsButton({ patientId }: { patientId: number }) {
  const { data: patient } = useWithLoadingAndErrorIndication(usePatientById(patientId.toString()));
  const toPatients = useNavigateToPatientList();
  const label = patient ? `${patient.firstName} ${patient.lastName}` : '';
  return (
    <UnactivatedButton
      onClick={toPatients}
      icon={<NavigateBefore sx={{ color: 'secondary.light' }} />}
      label={label}
    />
  );
}
