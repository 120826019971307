import { Grid, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ExitToApp } from '@mui/icons-material';
import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import logout from '../../../api/logout';
import { Settings } from '../../Settings';
import { isStartPage } from '../../../utils/isRedoxPage';
import HelpMenu from './HelpMenu';
import { navigationHeaderXPadding, topNavigationHeaderSize } from '../../../config/constants';
import FeedbackButton from './FeedbackButton';
import OptiCorLogo from './OptiCorLogo';
import HeaderDivider from './HeaderDivider';
import { useLocation } from 'react-router-dom';
import { VisitsButton } from './VisitsButton';
import { RisksAndTrendsButton } from './RisksAndTrendsButton';
import { ToPatientsButton } from './ToPatientsButton';

const PREFIX = 'Header';

const classes = {
  appBar: `${PREFIX}-appBar`,
  toolBar: `${PREFIX}-toolBar`,
};

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  [`&.${classes.appBar}`]: {
    background: theme.palette.action.active,
    height: topNavigationHeaderSize,
  },

  [`& .${classes.toolBar}`]: {
    justifyContent: 'space-between',
    paddingLeft: 0,
    paddingRight: navigationHeaderXPadding,
  },
}));

export default function Header() {
  const handleLogoutClick = () => {
    logout();
  };
  const location = useLocation();
  const patientId = getPatientIdFromPath(location.pathname);

  return (
    <StyledAppBar position="fixed" className={classes.appBar} elevation={0}>
      <Toolbar variant={'dense'} className={classes.toolBar}>
        <Grid container style={{ width: '100%', height: topNavigationHeaderSize }}>
          <Grid item sx={{ backgroundColor: '#52576c', pl: navigationHeaderXPadding, pt: 1.3 }}>
            <OptiCorLogo />
          </Grid>
          <Grid item xs />

          {patientId != null && <ToPatientsButton patientId={patientId} />}
          <HeaderDivider />
          {patientId != null && <VisitsButton patientId={patientId} />}
          {patientId != null && <RisksAndTrendsButton patientId={patientId} />}
          <HeaderDivider />

          <FeedbackButton />
          <HelpMenu />
          {!isStartPage() && <Settings />}
          <IconButton
            onClick={handleLogoutClick}
            color={'primary'}
            sx={{ ml: 3, p: 0 }}
            size="large"
          >
            <ExitToApp />
          </IconButton>
        </Grid>
      </Toolbar>
    </StyledAppBar>
  );
}

function getPatientIdFromPath(path: string): number | null {
  const regex = /\/patient\/(\d+)/;
  const match = path.match(regex);

  if (match && match[1]) {
    return parseInt(match[1], 10);
  }

  return null;
}
