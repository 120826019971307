import { OPTICOR_PRODUCT_PAGE_URL } from '../../../config/urlConfiguration';
import React from 'react';

export default function OptiCorLogo() {
  return (
    <>
      <a
        href={OPTICOR_PRODUCT_PAGE_URL}
        style={{ height: 'fit-content', width: 'fit-content' }}
        target={'_blank'}
        rel={'noreferrer'}
      >
        <img
          src={'/OptiCorLogo_grey.png'}
          alt={'Logo'}
          style={{
            height: '40px',
            width: 'auto',
            paddingRight: 8,
          }}
        />
      </a>
    </>
  );
}
