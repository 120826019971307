import {
  AntiArrhythmicMedication_Class_1or3,
  AssessmentParameters,
  CCS_SAF_Score,
  ContraindicationForOAC,
  DiabetesMedication,
  OralAnticoagulant,
} from '../types/assessments';

export const usePrefilledValuesForJamesLieAndJohnPotter = true;
export const PrefilledValuesForJamesLieAndJohnPotter: AssessmentParameters = {
  LADiameter: 49,
  LFEjectionFraction: 40,
  eGFR: 60,
  CCS_SAF_score: CCS_SAF_Score.Moderate,
  antiArrhythmicMedication_Class1or3: AntiArrhythmicMedication_Class_1or3.NotTried,
  antiplateletMedication: false,
  atrialFibrillation: true,
  carotidArteryDisease: false,
  chronicKidneyDisease: false,
  contraindicationForOAC: ContraindicationForOAC.None,
  coronaryArteryDisease: false,
  currentSmoker: true,
  dementia: false,
  diabetes: false,
  diabetesMedication: DiabetesMedication.NONE,
  diastolicBloodPressure: 85,
  heartFailure: false,
  height: 165,
  historyOfBleeding: false,
  historyOfStroke: false,
  hypertension: true,
  oralAnticoagulant: OralAnticoagulant.VKA,
  peripheralArteryDisease: false,
  pulse: 98,
  rhythmControlDesired: true,
  systolicBloodPressure: 140,
  thromboembolism: false,
  transientIschemicAttack: false,
  weight: 66,
};
