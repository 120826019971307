import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import React from 'react';
import { riskColorScheme } from '../../TimeSeriesView/ColorSchemes';
import { RiskComponents } from '../../../../../../types/risk';
import { OptionSetter, OptionValues } from '../OptionsProvider';
import { CheckboxProps, DisplayOptionCheckbox } from './CheckboxHelper';
import { HorizontalDivider } from './HorizontalDivider';

export function RisksCheckboxes({ optionValues, optionSetter, translator }: CheckboxProps) {
  const checkboxes = getRiskCheckboxProps({ optionValues, optionSetter });
  const riskEntries = Object.entries(checkboxes);

  return (
    <FormGroup>
      {riskEntries.map(([risk, { checked, setChecked }], index) => (
        <React.Fragment key={risk}>
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={(event) => setChecked(event.target.checked)}
                sx={{
                  color: riskColorScheme[risk as keyof RiskComponents],
                  '&.Mui-checked': {
                    color: riskColorScheme[risk as keyof RiskComponents],
                  },
                  marginY: 0,
                }}
              />
            }
            label={translator(`patientProfile.trend.options.riskParameter.${risk}`)}
          />
          {index < riskEntries.length - 1 && <HorizontalDivider />}
        </React.Fragment>
      ))}
    </FormGroup>
  );
}

function getRiskCheckboxProps({
  optionValues,
  optionSetter,
}: {
  optionValues: OptionValues;
  optionSetter: OptionSetter;
}): DisplayOptionCheckbox {
  return {
    mortality: {
      checked: optionValues.mortality,
      setChecked: optionSetter.setMortality,
    },
    bleeding: {
      checked: optionValues.bleeding,
      setChecked: optionSetter.setBleeding,
    },
    stroke: {
      checked: optionValues.stroke,
      setChecked: optionSetter.setStroke,
    },
  };
}
