import { AssessmentComponent, useAssessments } from '../../../api/patients';
import React, { useState } from 'react';
import { AssessmentProps } from './AssessmentButton';
import { Button, Menu, MenuItem, Typography } from '@mui/material';
import { useSettings } from '../../../hooks/useSettings';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useWebAssessment } from '../../../api/assessments';
import useWithLoadingAndErrorIndication from '../../../hooks/loadingIndication/useWithLoadingAndErrorIndication';
import { AssessmentIdOrLatest } from '../../../api/report';
import { useNavigateToExistingWebAssessment } from '../../../config/urlPaths';
import { EmptyPlaceholderPage } from '../../core/EmptyPlaceholderPage';

export function AssessmentDateDropdown({ patientId, assessmentId }: AssessmentProps) {
  const { helpers } = useSettings();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { data: assessmentList } = useWithLoadingAndErrorIndication(useAssessments(patientId));
  const { data: assessment } = useWithLoadingAndErrorIndication(
    useWebAssessment(patientId.toString(), assessmentId),
  );
  const toAssessment = useNavigateToExistingWebAssessment();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };

  if (!assessmentList) {
    return <EmptyPlaceholderPage />;
  }

  const assessmentsInDropdown = filterOutByAssessmentId(assessmentList, assessmentId);

  return (
    <>
      <Button
        onClick={handleClick}
        size={'large'}
        endIcon={anchorEl == null ? <ExpandMore /> : <ExpandLess />}
        sx={{ color: 'neutral.main' }}
      >
        <Typography variant={'h6'} display={'inline'} sx={{ mb: 0, ml: 1 }}>
          {assessment && helpers.formatDate(assessment.createdAt)}
        </Typography>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={anchorEl !== null}
        onClose={closeMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{
          '& .MuiPaper-root': {
            color: (theme) => theme.palette.primary.main,
            backgroundColor: (theme) => theme.palette.secondary.light,
            '& .MuiMenuItem-root': {
              '&:hover': {
                background: 'secondary.main',
              },
            },
          },
        }}
      >
        {assessmentsInDropdown.map((assessmentComponent) => (
          <MenuItem
            onClick={() => {
              toAssessment(patientId, assessmentComponent.assessmentId);
              closeMenu();
            }}
            sx={{ color: 'neutral.main' }}
            key={assessmentComponent.assessmentId}
          >
            <Typography>{helpers.formatDate(assessmentComponent.date)}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export function filterOutByAssessmentId(
  assessments: AssessmentComponent[],
  currentAssessmentId: AssessmentIdOrLatest,
): AssessmentComponent[] {
  return assessments.filter((assessment) => assessment.assessmentId !== currentAssessmentId);
}
