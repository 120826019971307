import { ReactNode } from 'react';
import { Button, Typography, useTheme } from '@mui/material';

export interface NavigationButtonProps {
  label: string;
  icon: ReactNode;
  onClick?: () => void;
  color?: string;
}

export function ActivatedButton({ label, icon, onClick = () => {} }: NavigationButtonProps) {
  const theme = useTheme();
  return (
    <Button
      size="large"
      startIcon={icon}
      onClick={onClick}
      sx={{
        '&:hover': {
          border: 'primary',
        },
        '&::after': {
          content: '""',
          position: 'absolute',
          bottom: 0,
          left: '10%', // Adjust this to change the starting position
          width: '80%', // This makes the border 50% of the button's width
          borderBottom: `3px solid ${theme.palette.primary.main}`,
        },
        mr: 2,
        color: 'primary',
      }}
    >
      <Typography variant="h6" sx={{ mb: 0, ml: 1 }} display={'inline'}>
        {label}
      </Typography>
    </Button>
  );
}

export function isActivated(path: string, ...pathVariables: string[]): boolean {
  return pathVariables.some((pathVariable) => path.includes(pathVariable));
}
