import { useParams } from 'react-router-dom';
import useWithLoadingAndErrorIndication from '../../../hooks/loadingIndication/useWithLoadingAndErrorIndication';
import { Patient, usePatientById } from '../../../api/patients';
import { AssessmentFormFrame } from '../assessmentForm/AssessmentFormFrame';
import { PrefilledLatestWebAssessment } from './web/PrefilledLatestWebAssessment';
import { WebAssessment } from './web/WebAssessment';
import React from 'react';
import { EmptyPlaceholderPage } from '../../core/EmptyPlaceholderPage';
import { AssessmentForm } from '../assessmentForm/AssessmentForm';
import { useNavigateToEvaluation } from '../../../config/urlPaths';
import { useWithLoadingIndication } from '../../../hooks/loadingIndication/useWithLoadingIndication';
import { assessmentsApi } from '../../../api/assessments';
import { scrollToTop } from '../../../utils/general';
import {
  PrefilledValuesForJamesLieAndJohnPotter,
  usePrefilledValuesForJamesLieAndJohnPotter,
} from '../../../config/featureConfiguration';
import { AssessmentParameters } from '../../../types/assessments';
import { useSettings } from '../../../hooks/useSettings';
import { AssessmentIdOrLatest } from '../../../api/report';
import { DEV_MODE } from '../../../config/urlConfiguration';

export function WebInputAssessmentPage() {
  const { patientId, assessmentId } = useParams();

  if (patientId === undefined) {
    throw Error('PatientId Not Found');
  }

  const { data: patient } = useWithLoadingAndErrorIndication(usePatientById(patientId));

  if (patient === undefined) {
    return <EmptyPlaceholderPage />;
  }

  const useLatestAssessment = assessmentId === 'latest';

  if (usePrefilledValuesForJamesLieAndJohnPotter && DEV_MODE) {
    if (
      (patient.firstName === 'James' && patient.lastName === 'Lie') ||
      (patient.firstName === 'John' && patient.lastName === 'Potter')
    ) {
      return <SpecialWebAssessment patient={patient} patientId={patientId} />;
    }
  }

  return (
    <AssessmentFormFrame patient={patient} id={patientId} showAssessmentButton>
      {useLatestAssessment ? (
        <PrefilledLatestWebAssessment patientId={patientId} />
      ) : (
        <WebAssessment patient={patient} patientId={patientId} />
      )}
    </AssessmentFormFrame>
  );
}

function SpecialWebAssessment({ patient, patientId }: { patient: Patient; patientId: string }) {
  const goToRiskPage = useNavigateToEvaluation();
  const assessmentId: AssessmentIdOrLatest = 'latest';
  const { helpers } = useSettings();

  const onCreateAssessment = useWithLoadingIndication(async (params: AssessmentParameters) => {
    await assessmentsApi.create(params, patient.id, helpers.formattedDateStringToDate(params.date));
    scrollToTop();
    goToRiskPage(patientId, assessmentId);
  });

  return (
    <AssessmentFormFrame patient={patient} id={patientId}>
      <AssessmentForm
        onSubmit={onCreateAssessment}
        prefilledValues={PrefilledValuesForJamesLieAndJohnPotter}
      />
      ;
    </AssessmentFormFrame>
  );
}
