import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useNavigateToExistingWebAssessment } from '../../../config/urlPaths';
import {
  ActivatedButton,
  isActivated,
} from '../../core/Header/NavigationComponents/ActivatedButton';
import { UnactivatedButton } from '../../core/Header/NavigationComponents/UnactivatedButton';
import { AssessmentIdOrLatest } from '../../../api/report';

export interface AssessmentProps {
  patientId: string | number;
  assessmentId: AssessmentIdOrLatest;
}

export function AssessmentButton({ patientId, assessmentId }: AssessmentProps) {
  const { t } = useTranslation('header');
  const location = useLocation();

  const toAssessment = useNavigateToExistingWebAssessment();
  const label = t('assessmentButton');

  return isActivated(location.pathname, 'view', 'new') ? (
    <ActivatedButton label={label} icon={null} />
  ) : (
    <UnactivatedButton
      label={label}
      icon={null}
      onClick={() => toAssessment(patientId, assessmentId)}
      color={'neutral.main'}
    />
  );
}
