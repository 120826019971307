import { AssessmentFormType } from '../../AssessmentForm';
import { DEFAULT_ASSESSMENT_VALUES_METRIC } from '../../../../../config/defaultValues';
import { AssessmentParameters, MedicalHistory } from '../../../../../types/assessments';
import {
  DataRepresentations,
  getAllKeys,
  getBooleanValueKeys,
  getKeysForCertainRepresentation,
  RepresentationMapping,
} from '../../logic/DataRepresentations';

const medicalHistoryDataRepresentationMapping: RepresentationMapping<MedicalHistory> = {
  coronaryArteryDisease: DataRepresentations.tickableBoolean,
  heartFailure: DataRepresentations.tickableBoolean,
  carotidArteryDisease: DataRepresentations.tickableBoolean,
  peripheralArteryDisease: DataRepresentations.tickableBoolean,
  historyOfStroke: DataRepresentations.tickableBoolean,
  transientIschemicAttack: DataRepresentations.tickableBoolean,
  thromboembolism: DataRepresentations.tickableBoolean,
  historyOfBleeding: DataRepresentations.tickableBoolean,
  chronicKidneyDisease: DataRepresentations.tickableBoolean,
  diabetes: DataRepresentations.tickableBoolean,
  hypertension: DataRepresentations.tickableBoolean,
  currentSmoker: DataRepresentations.tickableBoolean,
  dementia: DataRepresentations.tickableBoolean,
  atrialFibrillation: DataRepresentations.disabled,
  CCS_SAF_score: DataRepresentations.other,
} as const;

export const disabledMedicalHistoryKeys = getKeysForCertainRepresentation(
  medicalHistoryDataRepresentationMapping,
  DataRepresentations.disabled,
);

export const tickableMedicalHistoryKeys = getBooleanValueKeys(
  medicalHistoryDataRepresentationMapping,
);

export const medicalHistoryKeys = getAllKeys(medicalHistoryDataRepresentationMapping);

export function resetMedicalHistory(
  form: AssessmentFormType,
  prefilledValues?: Partial<AssessmentParameters>,
) {
  form.reset({
    ...form.getValues(),
    ...Object.fromEntries(
      medicalHistoryKeys.map((key) => [
        key,
        prefilledValues?.[key] ?? DEFAULT_ASSESSMENT_VALUES_METRIC[key],
      ]),
    ),
  });
}
