import { Divider } from '@mui/material';
import React from 'react';

export function HorizontalDivider({ marginY }: { marginY?: number }) {
  return marginY ? (
    <Divider
      orientation={'horizontal'}
      flexItem
      sx={{ color: 'secondary.dark', marginY: marginY }}
    />
  ) : (
    <Divider orientation={'horizontal'} flexItem sx={{ color: 'secondary.dark', marginY: 1 }} />
  );
}
