import React from 'react';
import { Container, Paper } from '@mui/material';
import { useParams } from 'react-router-dom';
import { usePatientBySessionId } from '../../api/patients';
import { Header } from './Header';
import { useURLQuery } from '../../hooks/queries';
import { RedoxEvaluation } from './evaluation/RedoxEvaluation';
import useWithLoadingAndErrorIndication from '../../hooks/loadingIndication/useWithLoadingAndErrorIndication';
import { Trend } from './trend/Trend';

interface RouteParams {
  id: string;
  subpage: string;
}

export function RedoxPatientProfile() {
  const { subpage } = useParams() as RouteParams;
  const query = useURLQuery();
  const sessionId = query.get('session') ?? '';
  const { data: patient } = useWithLoadingAndErrorIndication(usePatientBySessionId(sessionId));

  if (!patient) {
    return <div />;
  }

  return (
    <Container maxWidth="lg" sx={{ paddingTop: '2rem', paddingBottom: '3rem' }}>
      <Paper elevation={0}>
        {subpage === 'trend' ? (
          <Trend patient={patient} />
        ) : sessionId === '' ? (
          <div>NO SESSION ID SPECIFIED</div>
        ) : (
          <>
            <Header name={`${patient.firstName} ${patient.lastName}`} id={sessionId} />
            <RedoxEvaluation id={sessionId} patient={patient} />
          </>
        )}
      </Paper>
    </Container>
  );
}
