import { CircularProgress, Grid } from '@mui/material';
import React from 'react';

export function LoadingIndicator({
  relativeVerticalPosition,
}: {
  relativeVerticalPosition: number;
}) {
  return (
    <Grid container justifyContent="center">
      <Grid item>
        <CircularProgress
          sx={{ position: 'relative', top: relativeVerticalPosition.toString() + '%' }}
          color={'primary'}
        />
      </Grid>
    </Grid>
  );
}
