import { DownloadFormChoicesForm } from '../report.types';
import { Patient } from '../../../../api/patients';
import { AssessmentIdOrLatest } from '../../../../api/report';
import React, { useState } from 'react';
import { useSnackbar } from '../../../../hooks/snackbarMessaging/useSnackbar';
import { useTranslation } from 'react-i18next';
import {
  belongsToCorrectAssessment,
  useRecommendationsSessionStorage,
} from '../../../../state/recommendations';
import { Button, CircularProgress, Typography } from '@mui/material';
import { patientProfileButtonHeight } from '../../../../config/constants';
import { downloadPdfReport } from '../../../../utils/downloadPDFReport';
import { createPdfRequest } from '../report.logic';

export function DownloadReportButton({
  form,
  patient,
  assessmentId,
}: {
  form: DownloadFormChoicesForm;
  patient: Patient;
  assessmentId: AssessmentIdOrLatest;
}) {
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const { showMessage } = useSnackbar();

  const { t } = useTranslation('downloadPage');

  const [savedRecommendationsConfig] = useRecommendationsSessionStorage({
    assessment: assessmentId,
    recommendations: [],
    patientId: patient.id,
  });

  const recommendations = belongsToCorrectAssessment(savedRecommendationsConfig, {
    assessment: assessmentId,
    patientId: patient.id,
  })
    ? savedRecommendationsConfig.recommendations
    : [];

  return (
    <Button
      fullWidth={true}
      variant="contained"
      sx={{ boxShadow: 'none', height: patientProfileButtonHeight }}
      color="primary"
      onClick={() => {
        setIsDownloading(true);
        return downloadPdfReport(
          createPdfRequest(form.getValues(), patient, patient.id, assessmentId, recommendations),
        )
          .then(() => {
            setIsDownloading(false);
          })
          .catch(() => {
            setIsDownloading(false);
            showMessage(t('errors.downloadError'), 'error');
          });
      }}
    >
      {isDownloading ? (
        <CircularProgress color={'inherit'} />
      ) : (
        <Typography variant={'subtitle2'} color={'action.active'} fontWeight={'bold'}>
          {t('buttons.download')}
        </Typography>
      )}
    </Button>
  );
}
