import { AssessmentFormType } from '../../AssessmentForm';
import { StyledTextField } from '../../../../core/input-components/StyledTextField';
import { useSettings } from '../../../../../hooks/useSettings';
import { Units } from '../../../../../context/SettingsProvider';
import {
  FORMATS,
  getComponentsFromImperialDate,
  getComponentsFromMetricDate,
} from '../../../../core/input-components/DateInput';

export function DateEditable({ form }: { form: AssessmentFormType }) {
  const { settings } = useSettings();

  const format = settings.units === Units.Metric ? FORMATS['dd.mm.yyyy'] : FORMATS['mm/dd/yyyy'];
  const register = form.register('date', {
    pattern: {
      value: format.pattern,
      message: `Must be in the format ${format.placeholder}`,
    },
    validate: (value) => {
      const dateString = value as string;
      if (!dateString) {
        return true;
      }
      const components =
        settings.units === Units.Metric
          ? getComponentsFromMetricDate(dateString)
          : getComponentsFromImperialDate(dateString);
      const date = new Date(components.year, components.month - 1, components.day);
      const isValidDate =
        date.getFullYear() === components.year &&
        date.getMonth() + 1 === components.month &&
        date.getDate() === components.day;
      if (!isValidDate) return 'Invalid date';
      return true;
    },
  });

  return (
    <StyledTextField
      name={'date'}
      label={'Date'}
      placeholder={format.placeholder}
      register={register}
      errors={form.formState.errors}
    />
  );
}
