import { useParams } from 'react-router-dom';
import useWithLoadingAndErrorIndication from '../../hooks/loadingIndication/useWithLoadingAndErrorIndication';
import { PatientParameters, patientsApi, usePatientById } from '../../api/patients';
import { EmptyPlaceholderPage } from '../core/EmptyPlaceholderPage';
import { DemographicDataForm } from '../PatientListPage/patientItems/DemographicDataForm';
import { useSettings } from '../../hooks/useSettings';
import React from 'react';
import { Button, Container, Paper, Typography } from '@mui/material';
import { useWithLoadingIndication } from '../../hooks/loadingIndication/useWithLoadingIndication';
import useWithErrorsInSnackbar from '../../hooks/snackbarMessaging/useWithErrorsInSnackbar';
import { useQueryClient } from 'react-query';
import { useNavigateToPatientList, useNavigateToVisitPage } from '../../config/urlPaths';
import { useTranslation } from 'react-i18next';

export function DemographicDataPage() {
  const { patientId } = useParams();
  const { helpers } = useSettings();
  const { t } = useTranslation(['forms', 'pages']);

  if (patientId === undefined) {
    throw Error('PatientId not found');
  }
  const { data: patient } = useWithLoadingAndErrorIndication(usePatientById(patientId));
  const toVisitPage = useNavigateToVisitPage();
  const queryClient = useQueryClient();
  const toPatientList = useNavigateToPatientList();

  const handleSave = useWithLoadingIndication(
    useWithErrorsInSnackbar(async (newParameters: PatientParameters) => {
      if (patient) {
        await patientsApi.update({ ...patient, ...newParameters });
        await queryClient.invalidateQueries('patients');
        toVisitPage(patient.id);
      }
    }),
  );

  const handleDelete = useWithLoadingIndication(
    useWithErrorsInSnackbar(async () => {
      if (patient) {
        if (
          window.confirm(
            t('pages:patients.deleteMessage') + ` ${patient.firstName} ${patient.lastName}?`,
          )
        ) {
          await patientsApi.deletePatient(patient.id);
          await queryClient.invalidateQueries('patients');
          toPatientList();
        }
      }
    }),
  );

  if (patient === undefined) {
    return <EmptyPlaceholderPage />;
  }

  return (
    <Container maxWidth={'lg'} sx={{ paddingTop: '2rem', paddingBottom: '3rem' }}>
      <Paper elevation={0} sx={{ mt: 1, px: '2rem', py: '2rem' }}>
        <Typography
          variant={'h4'}
          sx={{ mb: '2rem' }}
        >{`Demographics ${patient.firstName} ${patient.lastName}`}</Typography>
        <DemographicDataForm
          onSave={handleSave}
          id={patientId}
          patientParameters={{
            ...patient,
            birthday: helpers.formatDate(new Date(patient.birthday)),
          }}
          additionalButtons={() => [
            <Button color="secondary" variant="contained" onClick={handleDelete}>
              {t('delete', 'Delete')}
            </Button>,
          ]}
        />
      </Paper>
    </Container>
  );
}
