import { RiskTimeSeries } from './RiskTimeSeries';
import { DiagnosisTimeSeries } from './DiagnosisTimeSeries';
import { Grid } from '@mui/material';
import { useOptions } from '../Options/useOptions';
import { TrendParameter } from '../Options/optionTypes';
import { Options } from '../Options/Options';

interface TimeSeriesProps {
  patientId: number;
}
export function TimeSeriesView({ patientId }: TimeSeriesProps) {
  const { optionValues } = useOptions();
  switch (optionValues.trendParameter) {
    case TrendParameter.RiskDevelopment: {
      return (
        <Grid container>
          <Grid item xs={9}>
            <RiskTimeSeries patientId={patientId} />
          </Grid>
          <Grid item xs={3}>
            <Options patientId={patientId} />
          </Grid>
        </Grid>
      );
    }
    case TrendParameter.Diagnosis: {
      return (
        <Grid container>
          <Grid item xs={8}>
            <DiagnosisTimeSeries patientId={patientId} />
          </Grid>
          <Grid item xs={4}>
            <Options patientId={patientId} />
          </Grid>
        </Grid>
      );
    }
    default: {
      return (
        <Grid container>
          <Grid item xs={9}>
            <RiskTimeSeries patientId={patientId} />
          </Grid>
          <Grid item xs={3}>
            <Options patientId={patientId} />
          </Grid>
        </Grid>
      );
    }
  }
}
