import { TrendData } from '../TimeSeriesView/RiskTimeSeries';
import { Card, Fade, Popper, Stack, Typography } from '@mui/material';
import React from 'react';
import { useNavigateToEvaluation } from '../../../../../config/urlPaths';
import { useAssessmentDifferenceForTimeSeriesTooltip } from './customTooltip.logic';
import { LoadingIndicator } from '../../../../core/LoadingIndicator';
import { useOptions } from '../Options/useOptions';
import { riskColorScheme } from '../TimeSeriesView/ColorSchemes';
import { AssessmentDifferenceComponent } from './AssessmentDifferenceComponent';
import ReactDOM from 'react-dom';

export function TimeSeriesTooltip({ active, payload, chartRef }: any) {
  const goToEvaluationPage = useNavigateToEvaluation();
  const { optionValues } = useOptions();
  const { isLoading, isError, data, error } = useAssessmentDifferenceForTimeSeriesTooltip(
    active,
    payload,
  );

  if (isLoading) {
    return <LoadingIndicator relativeVerticalPosition={100} />;
  }

  if (isError || !data) {
    return <div>Error: {error?.message}</div>;
  }

  if (active && payload && payload.length) {
    const trendData = payload[0].payload as TrendData;
    return (
      <>
        <Popper
          open={true}
          anchorEl={chartRef.current}
          placement={'right-start'}
          modifiers={[
            {
              name: 'offset',
              options: {
                offset: [-40, -10],
              },
            },
          ]}
        >
          <Fade in={true} timeout={2000}>
            <Card
              sx={{
                padding: 2,
                backgroundColor: 'neutral.light',
                pointerEvents: 'auto',
                width: '20rem',
              }}
            >
              <Typography color={'secondary.dark'} variant={'h5'} sx={{ mb: 1 }}>
                {trendData.formattedDate}
              </Typography>
              <Card sx={{ padding: 1, backgroundColor: '#3b3b3b' }}>
                {optionValues.mortality ? (
                  <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'flex-start'}
                    marginBottom={-2}
                  >
                    <Typography color={riskColorScheme.mortality} variant={'h6'}>
                      {'Mortality'}
                    </Typography>
                    <Typography
                      variant={'h6'}
                      color={'white'}
                    >{`${trendData.mortality} %`}</Typography>
                  </Stack>
                ) : null}
                {optionValues.bleeding ? (
                  <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'flex-start'}
                    marginBottom={-2}
                  >
                    <Typography color={riskColorScheme.bleeding} variant={'h6'}>
                      {'Bleeding'}
                    </Typography>
                    <Typography
                      variant={'h6'}
                      color={'white'}
                    >{`${trendData.bleeding} %`}</Typography>
                  </Stack>
                ) : null}
                {optionValues.stroke ? (
                  <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'flex-start'}
                    marginBottom={-2}
                  >
                    <Typography color={riskColorScheme.stroke} variant={'h6'}>
                      {'Stroke'}
                    </Typography>
                    <Typography
                      variant={'h6'}
                      color={'white'}
                    >{`${trendData.stroke} %`}</Typography>
                  </Stack>
                ) : null}
              </Card>
              <AssessmentDifferenceComponent assessmentDifference={data} />
            </Card>
          </Fade>
        </Popper>
        {ReactDOM.createPortal(
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100vw',
              height: '100vh',
            }}
            onClick={() => goToEvaluationPage(trendData.patientId, trendData.assignmentId)}
          />,
          document.body,
        )}
      </>
    );
  }
  return null;
}
