import { Patient } from '../../../../api/patients';
import { useNavigateToEvaluation } from '../../../../config/urlPaths';
import { useWithLoadingIndication } from '../../../../hooks/loadingIndication/useWithLoadingIndication';
import { assessmentsApi } from '../../../../api/assessments';
import { AssessmentForm } from '../../assessmentForm/AssessmentForm';
import React from 'react';
import { scrollToTop } from '../../../../utils/general';
import { AssessmentParameters } from '../../../../types/assessments';
import { AssessmentIdOrLatest } from '../../../../api/report';
import { useSettings } from '../../../../hooks/useSettings';

export function WebAssessment({ patient, patientId }: { patient: Patient; patientId: string }) {
  const assessmentId: AssessmentIdOrLatest = 'latest';
  const { helpers } = useSettings();
  const goToRiskPage = useNavigateToEvaluation();

  const onCreateAssessment = useWithLoadingIndication(async (params: AssessmentParameters) => {
    await assessmentsApi.create(params, patient.id, helpers.formattedDateStringToDate(params.date));
    scrollToTop();
    goToRiskPage(patientId, assessmentId);
  });

  return <AssessmentForm onSubmit={onCreateAssessment} />;
}
