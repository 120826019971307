import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { Search } from '@mui/icons-material';
import { classes } from '../PatientsListPage.style';

export function PatientSearchField({
  onNewSearchTerm,
}: {
  onNewSearchTerm: (newSearchTerm: string) => void;
}) {
  const { t } = useTranslation('pages');
  const [searchEntry, setSearchEntry] = useState('');

  return (
    <TextField
      id="patients-search"
      className={classes.searchField}
      value={searchEntry}
      label={t('patients.searchInputLabel', 'Search for a patient')}
      onChange={(event) => setSearchEntry(event.target.value)}
      onKeyPress={(event) => (event.key === 'Enter' ? onNewSearchTerm(searchEntry) : null)}
      variant="filled"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={() => onNewSearchTerm(searchEntry)} size="large">
              <Search />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
