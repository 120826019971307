import { useSearchParams } from 'react-router-dom';
import { useNavigateToRedoxRiskEvaluation } from '../../../../config/urlPaths';
import useWithLoadingAndErrorIndication from '../../../../hooks/loadingIndication/useWithLoadingAndErrorIndication';
import { useLatestRedoxAssessment } from '../../../../api/assessments';
import { useWithLoadingIndication } from '../../../../hooks/loadingIndication/useWithLoadingIndication';
import { redoxApi } from '../../../../api/redox';
import React from 'react';
import { AssessmentForm } from '../../assessmentForm/AssessmentForm';
import { EmptyPlaceholderPage } from '../../../core/EmptyPlaceholderPage';
import { AssessmentParameters } from '../../../../types/assessments';

export function PrefilledLatestRedoxAssessment({ sessionId }: { sessionId: string }) {
  const [searchParams] = useSearchParams();
  const goToEvaluation = useNavigateToRedoxRiskEvaluation(searchParams);

  const { data: assessment, isLoading, isFetching } = useWithLoadingAndErrorIndication(
    useLatestRedoxAssessment(sessionId),
  );

  const onRedoxUpdate = useWithLoadingIndication(async (newParams: AssessmentParameters) => {
    if (assessment === undefined) throw Error('No Assessment Data Found');
    await redoxApi.updateAssessment(sessionId, { ...assessment, ...newParams });
    goToEvaluation();
  });

  if (isLoading || isFetching) {
    return <EmptyPlaceholderPage />;
  }

  return <AssessmentForm onSubmit={onRedoxUpdate} prefilledValues={assessment} />;
}
