import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useNavigateToTrendTimeseriesPage } from '../../../config/urlPaths';
import {
  ActivatedButton,
  isActivated,
} from '../../core/Header/NavigationComponents/ActivatedButton';
import { UnactivatedButton } from '../../core/Header/NavigationComponents/UnactivatedButton';

export function TimeseriesButton({ patientId }: { patientId: string | number }) {
  const { t } = useTranslation('header');
  const location = useLocation();
  const toTimeseries = useNavigateToTrendTimeseriesPage();
  const label = t('timeseriesButton');

  return isActivated(location.pathname, 'timeseries') ? (
    <ActivatedButton label={label} icon={null} />
  ) : (
    <UnactivatedButton
      label={label}
      icon={null}
      onClick={() => toTimeseries(patientId)}
      color={'neutral.main'}
    />
  );
}
