import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
  ActivatedButton,
  isActivated,
} from '../../core/Header/NavigationComponents/ActivatedButton';
import { UnactivatedButton } from '../../core/Header/NavigationComponents/UnactivatedButton';
import { AssessmentProps } from './AssessmentButton';
import { useNavigateToEvaluation } from '../../../config/urlPaths';

export function EvaluationButton({ patientId, assessmentId }: AssessmentProps) {
  const { t } = useTranslation('header');
  const location = useLocation();

  const toEvaluation = useNavigateToEvaluation();
  const label = t('evaluationButton');

  return isActivated(location.pathname, 'evaluate') ? (
    <ActivatedButton label={label} icon={null} />
  ) : (
    <UnactivatedButton
      label={label}
      icon={null}
      onClick={() => toEvaluation(patientId, assessmentId)}
      color={'neutral.main'}
    />
  );
}
