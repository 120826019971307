import { Typography } from '@mui/material';
import React from 'react';

export const DocumentTitle = ({ text }: { text: string }) => {
  return (
    <Typography variant={'h6'} style={{ marginTop: '20px' }}>
      {text}
    </Typography>
  );
};
export const DocumentText = ({ text }: { text: string }) => {
  return <Typography>{text}</Typography>;
};
export const DocumentParagraph = ({ text }: { text: string }) => {
  return <Typography gutterBottom>{text}</Typography>;
};
export const DocumentBoldParagraph = ({ text }: { text: string }) => {
  return (
    <Typography gutterBottom style={{ fontWeight: 'bold' }}>
      {text}
    </Typography>
  );
};
export const DocumentLink = ({ text }: { text: string }) => {
  return (
    <Typography gutterBottom>
      <a href={text} target="_blank" rel="noopener noreferrer" style={{ marginBottom: '20px' }}>
        {text}
      </a>
    </Typography>
  );
};

export const DocumentImage = ({ link }: { link: string }) => {
  return (
    <Typography gutterBottom>
      <img style={{ width: '100%' }} src={link} alt={link} />
    </Typography>
  );
};

export const DocumentNewLine = ({ marginTop }: { marginTop: number }) => {
  return <div style={{ marginTop: `${marginTop}px` }} />;
};
