import {
  AntiArrhythmicMedication_Class_1or3,
  AssessmentParameters,
  CCS_SAF_Score,
  ContraindicationForOAC,
  DiabetesMedication,
  ImperialAssessmentParameters,
  OralAnticoagulant,
} from '../types/assessments';

export type DefaultAssessmentParameterBrand<K> = K & { __brand: 'Default Values' };

export type DefaultMetricAssessmentParameters = DefaultAssessmentParameterBrand<
  AssessmentParameters
>;
export type DefaultImperialAssessmentParameters = DefaultAssessmentParameterBrand<
  ImperialAssessmentParameters
>;

export const DEFAULT_ASSESSMENT_VALUES_METRIC: DefaultMetricAssessmentParameters = {
  CCS_SAF_score: CCS_SAF_Score.Asymptomatic,
  antiArrhythmicMedication_Class1or3: AntiArrhythmicMedication_Class_1or3.NotTried,
  contraindicationForOAC: ContraindicationForOAC.None,
  rhythmControlDesired: false,
  height: null,
  weight: null,
  pulse: null,
  diastolicBloodPressure: null,

  LFEjectionFraction: null,
  LADiameter: null,
  eGFR: null,

  atrialFibrillation: true,
  historyOfBleeding: false,
  coronaryArteryDisease: false,
  carotidArteryDisease: false,
  chronicKidneyDisease: false,
  dementia: false,
  diabetes: false,
  hypertension: false,
  heartFailure: false,
  peripheralArteryDisease: false,
  currentSmoker: false,
  historyOfStroke: false,
  transientIschemicAttack: false,
  thromboembolism: false,

  antiplateletMedication: false,
  oralAnticoagulant: OralAnticoagulant.NONE,
  diabetesMedication: DiabetesMedication.NONE,
} as DefaultMetricAssessmentParameters;

const {
  height,
  ..._DEFAULT_ASSESSMENT_VALUES_IMPERIAL
}: { height: null | number } & DefaultImperialAssessmentParameters = {
  ...DEFAULT_ASSESSMENT_VALUES_METRIC,

  feet: null,
  inches: null,
};

export const DEFAULT_ASSESSMENT_VALUES_IMPERIAL = _DEFAULT_ASSESSMENT_VALUES_IMPERIAL;
