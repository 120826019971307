import { PropsWithChildren } from 'react';
import { AppBar, useTheme } from '@mui/material';
import { navigationHeaderXPadding, topNavigationHeaderSize } from '../../../../config/constants';

interface SubHeaderProps {}

const subHeaderHeight = '3.5rem' as const;

export default function SubHeader(props: PropsWithChildren<SubHeaderProps>) {
  const theme = useTheme();

  return (
    <div style={{ height: subHeaderHeight }}>
      <div
        style={{
          color: 'transparent',
          position: 'absolute',
          top: topNavigationHeaderSize,
          left: 0,
          height: subHeaderHeight,
        }}
      >
        <AppBar
          sx={{
            top: topNavigationHeaderSize,
            height: subHeaderHeight,
            px: navigationHeaderXPadding,
            backgroundColor: theme.palette.secondary.light,
          }}
        >
          {props.children}
        </AppBar>
      </div>
    </div>
  );
}
