import { SvgIcon } from '@mui/material';

export function ToTrendIcon({
  color,
  fontSize,
}: {
  color: string;
  fontSize: 'small' | 'large' | 'inherit' | 'medium';
}) {
  return (
    <SvgIcon htmlColor={color} fontSize={fontSize}>
      <CustomDoctorBoard />
    </SvgIcon>
  );
}
function CustomDoctorBoard() {
  return (
    <g transform={'scale(0.9)'}>
      <g transform={'translate(1.7, 2)'}>
        <path
          d={
            'M 19 2 h -4.18 C 14.4 0.84 13.3 0 12 0 c -1.3 0 -2.4 0.84 -2.82 2 H 5 c -1.1 0 -2 0.9 -2 2 v 16 c 0 1.1 0.9 2 2 2 h 14 c 1.1 0 2 -0.9 2 -2 V 4 c 0 -1.1 -0.9 -2 -2 -2 z m -7 0 c 0.55 0 1 0.45 1 1 s -0.45 1 -1 1 s -1 -0.45 -1 -1 s 0.45 -1 1 -1 z m 7 18 H 5 V 4 h 2 v 3 h 10 V 4 h 2 v 16 z M 7 16 L 17 16 L 17 17 L 7 17 L 7 16 M 7 14 L 8 15 L 10.954 12.715 L 13.927 14.423 L 17.101 11.43 L 16.157 10.405 L 13.706 12.756 L 10.954 11.088 L 7.018 14.001'
          }
        />
      </g>
    </g>
  );
}
