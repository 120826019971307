import { Typography } from '@mui/material';
import React from 'react';

export default function AssessmentCardHeading({ text }: { text: string }) {
  return (
    <Typography variant={'h5'} sx={{ mt: 1 }}>
      {text}
    </Typography>
  );
}
