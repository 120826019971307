import { Card, CardActionArea, Typography, useTheme } from '@mui/material';
import { useSettings } from '../../../hooks/useSettings';
import { AssessmentComponent } from '../../../api/patients';
import { useNavigateToEvaluation } from '../../../config/urlPaths';
import { DATE_BOX_HEIGHT, DATE_BOX_MARGIN_RIGHT, DATE_BOX_MARGIN_TOP } from './Calendar';
const WIDTH = '9rem';
function isToday(date: Date): boolean {
  const today = new Date();

  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
}

export function DateBox({
  assessmentComponent,
  patientId,
}: {
  assessmentComponent: AssessmentComponent | null;
  patientId: number;
}) {
  const theme = useTheme();
  const { helpers } = useSettings();
  const primaryColor = theme.palette.primary.main;
  const hoverColor = theme.palette.action.hover;
  const toEvaluation = useNavigateToEvaluation();

  return assessmentComponent && assessmentComponent.date && assessmentComponent.assessmentId ? (
    isToday(assessmentComponent.date) ? (
      <Card
        sx={{
          height: DATE_BOX_HEIGHT,
          width: WIDTH,
          pl: 1,
          mt: DATE_BOX_MARGIN_TOP,
          mr: DATE_BOX_MARGIN_RIGHT,
          border: `3px solid ${primaryColor}`,
          backgroundColor: primaryColor,
        }}
      >
        <CardActionArea onClick={() => toEvaluation(patientId, assessmentComponent.assessmentId)}>
          <Typography variant={'h6'}>{helpers.formatDate(assessmentComponent.date)}</Typography>
        </CardActionArea>
      </Card>
    ) : (
      <Card
        sx={{
          height: DATE_BOX_HEIGHT,
          width: WIDTH,
          mt: DATE_BOX_MARGIN_TOP,
          mr: DATE_BOX_MARGIN_RIGHT,
          border: `3px solid ${primaryColor}`,
          pl: 1,
          ':hover': {
            backgroundColor: hoverColor,
          },
        }}
      >
        <CardActionArea onClick={() => toEvaluation(patientId, assessmentComponent.assessmentId)}>
          <Typography variant={'h6'}>{helpers.formatDate(assessmentComponent.date)}</Typography>
        </CardActionArea>
      </Card>
    )
  ) : (
    <Card
      sx={{
        height: DATE_BOX_HEIGHT,
        width: WIDTH,
        mt: DATE_BOX_MARGIN_TOP,
        mr: DATE_BOX_MARGIN_RIGHT,
        boxShadow: 'none',
      }}
    />
  );
}
