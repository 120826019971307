import { SvgIcon } from '@mui/material';

type IconColor =
  | 'secondary'
  | 'success'
  | 'inherit'
  | 'action'
  | 'warning'
  | 'disabled'
  | 'error'
  | 'primary'
  | 'info';
export function NewAssessmentIcon({ color }: { color: IconColor }) {
  return (
    <SvgIcon color={color ?? 'primary'} fontSize={'large'}>
      <CustomAddSign />
      <CustomDoctorBoard />
    </SvgIcon>
  );
}

function CustomAddSign() {
  return (
    <g transform={'scale(0.6)'}>
      <g transform={'translate(8.2, 10.5)'}>
        <path d={'M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z'} />
      </g>
    </g>
  );
}

function CustomDoctorBoard() {
  return (
    <g transform={'scale(0.9)'}>
      <g transform={'translate(1.3, 2)'}>
        <path
          d={
            'M19 2h-4.18C14.4.84 13.3 0 12 0c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm7 18H5V4h2v3h10V4h2v16z'
          }
        />
      </g>
    </g>
  );
}
