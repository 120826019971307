import { Card, CardActionArea, Stack, Typography, useTheme } from '@mui/material';
import { NavigateBefore } from '@mui/icons-material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DATE_BOX_HEIGHT, DATE_BOX_MARGIN_RIGHT, DATE_BOX_MARGIN_TOP } from './Calendar';

export interface YearProp {
  yearRange: number;
  setYearRange: (year: number) => void;
}

function previousYearOnClick(yearRange: number, setYearRange: (yearRange: number) => void) {
  setYearRange(yearRange - 1);
}
export function PreviousYearButton({ yearRange, setYearRange }: YearProp) {
  const { t } = useTranslation('pages');
  const theme = useTheme();
  const primaryColor = theme.palette.primary.main;
  return (
    <Card
      sx={{
        height: DATE_BOX_HEIGHT,
        width: '9rem',
        mt: DATE_BOX_MARGIN_TOP,
        mr: DATE_BOX_MARGIN_RIGHT,
        border: 'none',
        boxShadow: 'none',
        backgroundColor: 'transparent',
      }}
    >
      <CardActionArea onClick={() => previousYearOnClick(yearRange, setYearRange)}>
        <Stack direction={'row'} justifyContent={'flex-end'} color={primaryColor}>
          <NavigateBefore />
          <Typography>{t('patientProfile.visits.previousYearButton')}</Typography>
        </Stack>
      </CardActionArea>
    </Card>
  );
}
