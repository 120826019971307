import { TrendComponent, useNewTrend } from '../../../../../api/patients';
import React, { useRef } from 'react';
import { Typography, useTheme } from '@mui/material';
import { RiskComponents } from '../../../../../types/risk';
import { NoTrendData } from '../NoTrendData';
import { useSettings } from '../../../../../hooks/useSettings';
import {
  CartesianGrid,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
} from 'recharts';
import { TimeSeriesTooltip } from '../CustomTooltip/TimeSeriesTooltip';
import { AssessmentIdOrLatest } from '../../../../../api/report';
import useWithLoadingAndErrorIndication from '../../../../../hooks/loadingIndication/useWithLoadingAndErrorIndication';
import { EmptyPlaceholderPage } from '../../../../core/EmptyPlaceholderPage';
import { useOptions } from '../Options/useOptions';
import { riskColorScheme } from './ColorSchemes';

export function filterDataByLatestOnSameDate(arr: any[], mostRecentLeft: boolean): any[] {
  const filteredArray: TrendComponent[] = [];

  if (mostRecentLeft) {
    arr.sort((a, b) => a.date.getTime() - b.date.getTime());
  } else {
    arr.sort((a, b) => b.date.getTime() - a.date.getTime());
  }

  for (let i = 0; i < arr.length; i++) {
    const { date } = arr[i];

    const dateIsUnique = !filteredArray.some(
      (item: TrendComponent) =>
        item.date.getDate() === date.getDate() &&
        item.date.getMonth() === date.getMonth() &&
        item.date.getFullYear() === date.getFullYear(),
    );

    if (dateIsUnique) {
      filteredArray.push(arr[i]);
    }
  }

  return filteredArray;
}

export interface TrendData extends RiskComponents {
  assignmentId: AssessmentIdOrLatest;
  patientId: number;
  formattedDate: string;
  time: number;
}

export function RiskTimeSeries({ patientId }: { patientId: number }) {
  const theme = useTheme();
  const { optionValues } = useOptions();
  const { data: trendData } = useWithLoadingAndErrorIndication(
    useNewTrend(patientId, optionValues.timespan),
  );
  const { helpers } = useSettings();
  const chartRef = useRef<HTMLDivElement>(null);
  const primaryColor = theme.palette.primary.main;
  if (!trendData) {
    return <EmptyPlaceholderPage />;
  }

  if (trendData.length <= 1) {
    return <NoTrendData />;
  }
  const filteredData = filterDataByLatestOnSameDate(trendData, optionValues.mostRecentLeft).map(
    (d) => {
      const trendData: TrendData = {
        patientId: patientId,
        assignmentId: d.assignmentId,
        formattedDate: helpers.formatDate(d.date),
        time: optionValues.mostRecentLeft ? d.date.getTime() * -1 : d.date.getTime(), // Multiplication with -1 is a workaround to reverse the order of the assessments
        ...d.risks,
      };
      return trendData;
    },
  );

  const riskValues = trendData.reduce(
    (a, c) => [...a, c.risks.bleeding, c.risks.mortality, c.risks.stroke],
    [] as number[],
  );

  const maxRiskValue = Math.floor(Math.max(...riskValues) + 1);

  return (
    <>
      <Typography sx={{ paddingLeft: 3 }}>Risk in %</Typography>
      <div ref={chartRef} style={{ position: 'relative' }}>
        <ResponsiveContainer width="100%" height={500}>
          <LineChart
            width={500}
            height={300}
            data={filteredData}
            margin={{
              top: 5,
              right: 30,
              left: 0,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="time"
              domain={['auto', 'auto']}
              padding={{ left: 10, right: 10 }}
              tickFormatter={(unixTime) =>
                helpers.formatDateAbbreviated(new Date(Math.abs(unixTime)))
              }
              ticks={filteredData.map((item) => item.time)}
              type={'number'}
            />
            <YAxis domain={[0, maxRiskValue]} />
            <Legend />
            <Line
              type="monotone"
              dataKey="mortality"
              stroke={optionValues.mortality ? riskColorScheme.mortality : 'transparent'}
              strokeWidth={3}
              activeDot={{ r: 6 }}
            />
            <Line
              type="monotone"
              dataKey="bleeding"
              stroke={optionValues.bleeding ? riskColorScheme.bleeding : 'transparent'}
              strokeWidth={3}
              activeDot={{ r: 6 }}
            />
            <Line
              type="monotone"
              dataKey="stroke"
              stroke={optionValues.stroke ? riskColorScheme.stroke : 'transparent'}
              strokeWidth={3}
              activeDot={{ r: 6 }}
            />
            <Tooltip
              content={<TimeSeriesTooltip chartRef={chartRef} />}
              trigger={'hover'}
              cursor={{ stroke: primaryColor, strokeWidth: 2 }}
              filterNull
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </>
  );
}
