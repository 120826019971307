import { useSnackbar } from '../../hooks/snackbarMessaging/useSnackbar';
import { Alert, Snackbar } from '@mui/material';
import { snackbarAutoHideDuration } from '../../config/constants';
import { SeverityType } from '../../context/SnackbarMessageProvider';

export const closedSnackbarState: MessageSnackbarProps = {
  message: '',
  severity: 'error',
  open: false,
};

interface MessageSnackbarProps {
  message: string;
  severity: SeverityType;
  open: boolean;
}

export function MessageSnackbar(props: MessageSnackbarProps) {
  const { removeAllMessages } = useSnackbar();
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={props.open}
      autoHideDuration={snackbarAutoHideDuration}
      onClose={(_, reason) => {
        if (reason !== 'clickaway') {
          removeAllMessages();
        }
      }}
    >
      <Alert variant={'filled'} severity={props.severity}>
        {props.message}
      </Alert>
    </Snackbar>
  );
}
