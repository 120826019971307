import { PatientParameters, Recommendation } from '../../../api/patients';
import { AssessmentIdOrLatest, PDFReportRequest } from '../../../api/report';
import { DownloadFormChoices, getRecommendationsConfig } from './report.types';

export function createPdfRequest(
  downloadFormChoices: DownloadFormChoices,
  patient: PatientParameters,
  patientId: number,
  assessmentId: AssessmentIdOrLatest,
  recommendations: Recommendation[],
): PDFReportRequest {
  return {
    data: {
      patient: {
        // stop sending more data than needed (when using "patient" directly, ehr_id, etc. will get sent too)
        firstName: patient.firstName,
        lastName: patient.lastName,
        gender: patient.gender,
        race: patient.race,
        birthday: patient.birthday,
      },
      patientId,
      assessment: assessmentId,
      recommendations,
    },
    attendingPhysicians: [{ firstName: 'Test', lastName: 'Physician' }], //TODO
    comment: downloadFormChoices.comment,
    configuration: {
      include: {
        personalData: downloadFormChoices.personalData,
        vitals: downloadFormChoices.vitals,
        optionalParameters: downloadFormChoices.optionalParameters,
        medicalHistory: downloadFormChoices.medicalHistory,
        medications: downloadFormChoices.medications,
        riskProfile: downloadFormChoices.riskProfile,
        recommendations: getRecommendationsConfig(downloadFormChoices.recommendationGroupChoices),
      },
    },
  };
}
