import { TrendParameter } from '../optionTypes';
import React, { useState } from 'react';
import { Button, Menu, MenuItem, Typography } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

export function TrendParameterDropdown({
  trendParameter,
  setTrendParameter,
  translator,
}: {
  trendParameter: TrendParameter;
  setTrendParameter: (trendParameter: TrendParameter) => void;
  translator: (key: string) => string;
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const trendParameterOptions = [TrendParameter.RiskDevelopment, TrendParameter.Diagnosis].filter(
    (option) => option !== trendParameter,
  );
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        onClick={handleClick}
        size={'large'}
        endIcon={anchorEl == null ? <ExpandMore /> : <ExpandLess />}
        sx={{
          color: 'neutral.main',
          backgroundColor: 'secondary.light',
          width: '100%',
          justifyContent: 'space-between',
          '&:hover': {
            background: 'rgba(48, 48, 48, 0.04)',
          },
        }}
      >
        <Typography variant={'h6'} display={'inline'} sx={{ mb: 0, ml: 1 }}>
          {getTrendParameterMenuItem(trendParameter, translator)}
        </Typography>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={anchorEl !== null}
        onClose={closeMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={{
          width: '100%',
          '& .MuiPaper-root': {
            color: (theme) => theme.palette.primary.main,
            backgroundColor: (theme) => theme.palette.secondary.light,
            '& .MuiMenuItem-root': {
              '&:hover': {
                background: 'action.active',
              },
            },
          },
        }}
      >
        {trendParameterOptions.map((trendParameter) => (
          <MenuItem
            onClick={() => {
              setTrendParameter(trendParameter);
            }}
            sx={{ color: 'neutral.main' }}
            key={trendParameter}
          >
            <Typography>{getTrendParameterMenuItem(trendParameter, translator)}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

function getTrendParameterMenuItem(
  trendParameter: TrendParameter,
  translator: (key: string) => string,
) {
  switch (trendParameter) {
    case TrendParameter.Diagnosis:
      return translator('patientProfile.trend.options.trendParameter.diagnosisLabel');
    case TrendParameter.RiskDevelopment:
      return translator('patientProfile.trend.options.trendParameter.riskDevelopmentLabel');
  }
}
