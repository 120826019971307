import { DownloadFormChoicesForm } from './report.types';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { StyledTextField } from '../../core/input-components/StyledTextField';
import React from 'react';

export function AddCommentGridItem({ form }: { form: DownloadFormChoicesForm }) {
  const { t } = useTranslation('downloadPage');

  return (
    <Grid item xs={12} md={7}>
      <Typography variant={'h5'} fontWeight={'bold'} sx={{ mb: 5, mt: 2 }}>
        {t('comment.title')}
      </Typography>
      <StyledTextField
        name={'comment'}
        label={t('comment.label')}
        register={form.register('comment')}
        errors={form.formState.errors}
        multiline={true}
      />
    </Grid>
  );
}
