import { createContext, PropsWithChildren, useCallback, useState } from 'react';
import { closedSnackbarState, MessageSnackbar } from '../components/core/MessageSnackbar';

export function SnackbarMessageProvider({ children }: PropsWithChildren<{}>) {
  const [snackbarState, setSnackbarState] = useState(closedSnackbarState);

  const showMessage = useCallback((message: string, severity: SeverityType) => {
    setSnackbarState((prev) => {
      return message !== prev.message || severity !== prev.severity
        ? {
            message,
            severity,
            open: true,
          }
        : prev;
    });
  }, []);

  const removeAllMessages = useCallback(() => {
    setSnackbarState({ message: '', severity: 'info', open: false });
  }, []);

  return (
    <SnackbarMessageContext.Provider
      value={{
        showMessage,
        removeAllMessages,
      }}
    >
      <MessageSnackbar {...snackbarState} />
      {children}
    </SnackbarMessageContext.Provider>
  );
}

export type SeverityType = 'info' | 'error' | 'warning' | 'success';

interface SnackbarMessageState {
  showMessage: (message: string, severity: SeverityType) => void;
  removeAllMessages: () => void;
}

export const SnackbarMessageContext = createContext<SnackbarMessageState>({
  showMessage: () => {},
  removeAllMessages: () => {},
});
