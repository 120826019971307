import React, { PropsWithChildren, useCallback, useState } from 'react';
import { useMutateSettings, useSavedSettings } from '../api/settings';
import { isRedoxPage } from '../utils/isRedoxPage';

export enum Units {
  Metric = 'metric',
  Imperial = 'imperial',
}

export enum DevMode {
  Enabled = 'enabled',
  Disabled = 'disabled',
}

export function isMetric(unit: Units): boolean {
  return unit === Units.Metric;
}

export function isImperial(unit: Units): boolean {
  return unit === Units.Imperial;
}

export interface Settings {
  units: Units;
  devMode: DevMode;
}

const defaultSettings: Settings = {
  units: Units.Imperial,
  devMode: DevMode.Disabled,
};

export const SettingsContext = React.createContext({
  settings: defaultSettings,
  saveSettings: async (s: Settings) => {},
});

export function SettingsProvider({ children }: PropsWithChildren<{}>) {
  const { data } = useSavedSettings();
  const mutation = useMutateSettings();
  const [temporaryRedoxSettings, setTemporaryRedoxSettings] = useState<Settings>(defaultSettings);

  let settings = defaultSettings;
  if (data) {
    settings = data;
  }

  if (isRedoxPage()) {
    settings = temporaryRedoxSettings;
  }
  const saveSettings = useCallback(
    async (settings: Settings) => {
      if (isRedoxPage()) {
        new Promise(() => {
          setTemporaryRedoxSettings(settings);
        });
      } else {
        await mutation.mutate(settings);
      }
    },
    [mutation],
  );
  return (
    <SettingsContext.Provider value={{ settings, saveSettings }}>
      {children}
    </SettingsContext.Provider>
  );
}
