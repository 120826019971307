import { useTranslation } from 'react-i18next';
import { useNavigateToEvaluation } from '../../../../config/urlPaths';
import { isRedoxPage } from '../../../../utils/isRedoxPage';
import { Button, Typography } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import React from 'react';
import { AssessmentIdOrLatest } from '../../../../api/report';

export function ReturnToRecommendationsButton({
  patientId,
  assessmentId,
}: {
  patientId: number | string;
  assessmentId: AssessmentIdOrLatest;
}) {
  const { t } = useTranslation('downloadPage');

  const toWebEval = useNavigateToEvaluation();
  const toEval = () => {
    if (isRedoxPage()) {
      throw Error('Not Implemented For Redox Please contact the developer'); //TODO
    } else {
      toWebEval(patientId, assessmentId);
    }
  };
  return (
    <Button
      onClick={toEval}
      size="large"
      startIcon={<ArrowBackIosIcon color={'action'} fontSize={'large'} />}
      sx={{
        '&:hover': {
          backgroundColor: 'secondary.dark',
        },
      }}
    >
      <Typography variant="h6" sx={{ color: 'action.active', mb: 0, ml: 1 }} display={'inline'}>
        {t('buttons.returnToRecommendations')}
      </Typography>
    </Button>
  );
}
