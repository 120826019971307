import { IconButton, Tooltip, Typography } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { feedbackEmailAddress } from '../../../config/emails';

export default function FeedbackButton() {
  const { t } = useTranslation('header');
  return (
    <Tooltip
      title={
        <Typography variant={'caption'}>
          {`${t('feedback.tooltip')} - ${feedbackEmailAddress}`}
        </Typography>
      }
      placement={'bottom'}
    >
      <IconButton
        color="primary"
        href={`mailto:${feedbackEmailAddress}?subject=Feedback%20On%20OptiCor`}
        size="large"
      >
        <ChatIcon color="primary" />
      </IconButton>
    </Tooltip>
  );
}
