import { Grid } from '@mui/material';
import React from 'react';
import { CheckboxWithLabelAndTooltip } from '../../../../core/input-components/CheckboxWithLabelAndTooltip';
import { useTranslation } from 'react-i18next';
import { StyledRadioGroup } from '../../../../core/input-components/StyledRadioGroup';
import { StyledLabelWithNote } from '../../../../core/input-components/StyledLabelWithNote';
import { AssessmentFormType } from '../../AssessmentForm';
import LabelWithTooltip from '../../../../core/input-components/LabelWithTooltip/LabelWithTooltip';
import {
  AntiArrhythmicMedication_Class_1or3,
  ContraindicationForOAC,
  DiabetesMedication,
  OralAnticoagulant,
} from '../../../../../types/assessments';

export function MedicationsEditable({
  form,
  showDiabetesMedication,
  showContraindicationForOAC,
}: {
  form: AssessmentFormType;
  showDiabetesMedication: boolean;
  showContraindicationForOAC: boolean;
}) {
  const { t } = useTranslation('forms');

  return (
    <div style={{ pageBreakInside: 'avoid' }}>
      <Grid container spacing={3}>
        <Grid item>
          <CheckboxWithLabelAndTooltip
            name={'antiplateletMedication'}
            label={
              <StyledLabelWithNote
                label={t(`medications.antiplateletMedication.label`)}
                tooltip={t(`medications.antiplateletMedication.tooltip`)}
              />
            }
            control={form.control}
          />
        </Grid>
        <Grid item xs={12}>
          <StyledRadioGroup
            isColumn={true}
            name={'oralAnticoagulant'}
            label={t('medications.oralAnticoagulant.label')}
            options={Object.values(OralAnticoagulant).map((v) => ({
              ...t('medications.oralAnticoagulant.values.' + v, { returnObjects: true }),
              value: v,
            }))}
            control={form.control}
            errors={form.formState.errors}
          />
        </Grid>

        <Grid item xs={6}>
          <StyledRadioGroup
            name={'antiArrhythmicMedication_Class1or3'}
            isColumn={true}
            label={
              <LabelWithTooltip
                label={t('medications.antiArrhythmicMedication_Class1or3.label')}
                tooltipText={t('medications.antiArrhythmicMedication_Class1or3.tooltip')}
              />
            }
            options={Object.values(AntiArrhythmicMedication_Class_1or3).map((v) => ({
              ...t('medications.antiArrhythmicMedication_Class1or3.values.' + v, {
                returnObjects: true,
              }),
              value: v,
            }))}
            control={form.control}
            errors={form.formState.errors}
          />
        </Grid>
        <Grid item xs={6}>
          <StyledRadioGroup
            isColumn={true}
            disabled={!showContraindicationForOAC}
            name={'contraindicationForOAC'}
            label={t('medications.contraindicationForOAC.label')}
            options={Object.values(ContraindicationForOAC).map((v) => ({
              ...t('medications.contraindicationForOAC.values.' + v, { returnObjects: true }),
              value: v,
            }))}
            control={form.control}
            errors={form.formState.errors}
          />
        </Grid>
        <Grid item xs={12}>
          <StyledRadioGroup
            isColumn={true}
            disabled={!showDiabetesMedication}
            name={'diabetesMedication'}
            label={t('medications.diabetesMedication.label')}
            options={Object.values(DiabetesMedication).map((v) => ({
              ...t('medications.diabetesMedication.values.' + v, { returnObjects: true }),
              value: v,
            }))}
            control={form.control}
            errors={form.formState.errors}
          />
        </Grid>
      </Grid>
    </div>
  );
}
