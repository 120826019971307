import { useRef, useState } from 'react';
import { Grid, List, Slide, Stack } from '@mui/material';
import RecommendationItem from './RecommendationItem';
import { Recommendation } from '../../../../api/patients';
import { recommendationSortFunction } from './recommendationItem.logic';
import { RecommendationCategorySelection } from './RecommendationCategorySelection';
import { slidingRecommendationsTransitionTime } from '../../../../config/constants';
import { getRecommendationCategory, RecommendationCategory } from '../../../../api/report';

interface RecommendationsProps {
  recommendations: Recommendation[];
  onChange: (recommendations: Recommendation[]) => any;
}

export default function Recommendations({ recommendations, onChange }: RecommendationsProps) {
  const refForSlidingRecommendations = useRef<null | HTMLUListElement>(null);
  const [chosenCategory, setChosenCategory] = useState<RecommendationCategory>(
    RecommendationCategory.recommendation,
  );

  const handleUserClick = (updatedRecommendation: Recommendation) => {
    onChange(
      recommendations.map((r) => (r.key === updatedRecommendation.key ? updatedRecommendation : r)),
    );
  };

  return (
    <Stack sx={{ mt: 3 }}>
      <RecommendationCategorySelection currentValue={chosenCategory} onChange={setChosenCategory} />
      <Grid
        container
        justifyContent={'center'}
        sx={{
          overflowY: 'auto',
          overflowX: 'hidden',
          height: '35rem',
          borderWidth: '3px 0 3px 0',
          borderStyle: 'solid',
          borderColor: 'secondary.light',
          borderRadius: '9px',
        }}
      >
        <List ref={refForSlidingRecommendations}>
          {recommendations.sort(recommendationSortFunction).map((recommendation, key) => {
            const isInChosenCategory = getRecommendationCategory(recommendation) === chosenCategory;
            return (
              <Slide
                direction={'left'}
                in={isInChosenCategory}
                container={refForSlidingRecommendations.current}
                timeout={slidingRecommendationsTransitionTime}
                mountOnEnter
                unmountOnExit
              >
                <Grid
                  item
                  xs={12}
                  sx={{
                    ...(isInChosenCategory ? {} : { display: 'none' }),
                  }}
                  key={key}
                >
                  <RecommendationItem recommendation={recommendation} onToggle={handleUserClick} />
                </Grid>
              </Slide>
            );
          })}
        </List>
      </Grid>
    </Stack>
  );
}
