export enum DataRepresentations {
  tickableBoolean,
  enumValue,
  disabled,
  other,
  number,
}

export type RepresentationMapping<T> = Record<keyof T, DataRepresentations>;

export function getKeysForCertainRepresentation<U>(
  obj: RepresentationMapping<U>,
  filterBy: DataRepresentations,
): Array<keyof U> {
  return (Object.keys(obj) as Array<keyof U>).filter((key) => obj[key] === filterBy);
}

export function getAllKeys<T>(representationMapping: RepresentationMapping<T>) {
  return Object.keys(representationMapping) as Array<keyof typeof representationMapping>;
}

export function getEnumValueKeys<U>(obj: RepresentationMapping<U>) {
  return getKeysForCertainRepresentation(obj, DataRepresentations.enumValue);
}

export function getBooleanValueKeys<U>(obj: RepresentationMapping<U>) {
  return getKeysForCertainRepresentation(obj, DataRepresentations.tickableBoolean);
}
