import { AssessmentFormType } from '../../AssessmentForm';
import { Grid, List, Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { disabledMedicalHistoryKeys, tickableMedicalHistoryKeys } from './medicalHistory.logic';
import { NonEditableAssessmentListItem } from '../../subComponents/NonEditableAssessmentListItem';
import LabelWithTooltip from '../../../../core/input-components/LabelWithTooltip/LabelWithTooltip';
import {
  AssessmentParameters,
  CCS_SAF_Score,
  ImperialAssessmentParameters,
} from '../../../../../types/assessments';

export default function MedicalHistoryNonEditable({ form }: { form: AssessmentFormType }) {
  const values = form.getValues();
  return (
    <div style={{ pageBreakInside: 'avoid' }}>
      <Medication values={values} />
      <CCSSAFScoreInput score={values.CCS_SAF_score} />
    </div>
  );
}

function Medication({ values }: { values: AssessmentParameters & ImperialAssessmentParameters }) {
  const shouldHaveDivider = (index: number, array: Array<any>) =>
    index !== array.length - 1 && index !== Math.ceil(array.length / 2) - 2;

  const { t } = useTranslation('forms');

  return (
    <List disablePadding={true} sx={{ columnCount: 2, pb: 3 }} dense>
      {disabledMedicalHistoryKeys.map((field) => (
        <NonEditableAssessmentListItem
          isChecked={values[field]}
          withDivider={true}
          label={t(`medicalHistory.${field}.label`)}
          tooltip={t(`medicalHistory.${field}.tooltip`)}
          key={field}
        />
      ))}
      {tickableMedicalHistoryKeys.map((field, index, keys) => (
        <NonEditableAssessmentListItem
          isChecked={values[field]}
          withDivider={shouldHaveDivider(index, keys)}
          label={t(`medicalHistory.${field}.label`)}
          tooltip={t(`medicalHistory.${field}.tooltip`)}
          key={field}
        />
      ))}
    </List>
  );
}

function CCSSAFScoreInput({ score }: { score: CCS_SAF_Score }) {
  const { t } = useTranslation('forms');

  return (
    <Grid item xs={12} sx={{ mt: 2 }} alignItems={'left'}>
      <Stack direction={'row'}>
        <LabelWithTooltip
          label={
            <Typography variant={'h5'} sx={{ fontWeight: 'bold', m: 0 }}>
              {t('medicalHistory.CCS_SAF_score.label') + ':'}
            </Typography>
          }
          tooltipText={t('medicalHistory.CCS_SAF_score.tooltip')}
        />

        <Typography variant={'h4'} sx={{ fontWeight: 'bold', m: 0, pr: 1.5, pl: 4 }}>
          {score}
        </Typography>
        <Typography variant={'h6'} sx={{ fontWeight: 'lighter', m: 0, p: 0 }}>
          {'(' + CCS_SAF_Score[score] + ')'}
        </Typography>
      </Stack>
    </Grid>
  );
}
