import Grid from '@mui/material/Grid';
import React from 'react';
import { StyledTextField } from '../../../../core/input-components/StyledTextField';
import { useTranslation } from 'react-i18next';
import { ruleHelper } from '../../logic/rule-helper';
import { AssessmentFormType } from '../../AssessmentForm';

const ALLOWED_VALUES = {
  pulse: {
    min: 40,
    max: 200,
  },
  systolicBloodPressure: {
    min: 85,
    max: 200,
  },
  diastolicBloodPressure: {
    min: 50,
    max: 120,
  },
};

export function PulseBloodPressure({ form }: { form: AssessmentFormType }) {
  const { t } = useTranslation('forms');

  return (
    <Grid container columnSpacing={3} rowSpacing={1}>
      <Grid item xs={12} sm={4} md={12}>
        <StyledTextField
          name="pulse"
          type="number"
          label={t('vitals.pulse')}
          register={form.register('pulse', ruleHelper(t, ALLOWED_VALUES, 'pulse'))}
          errors={form.formState.errors}
          adornment="bpm"
        />
      </Grid>
      <Grid item xs={6} sm={4} md={6}>
        <StyledTextField
          name="systolicBloodPressure"
          type="number"
          label={t('vitals.systolicBloodPressure')}
          register={form.register(
            'systolicBloodPressure',
            ruleHelper(t, ALLOWED_VALUES, 'systolicBloodPressure', true),
          )}
          errors={form.formState.errors}
          adornment="mmHg"
        />
      </Grid>
      <Grid item xs={6} sm={4} md={6}>
        <StyledTextField
          name="diastolicBloodPressure"
          type="number"
          label={t('vitals.diastolicBloodPressure')}
          register={form.register(
            'diastolicBloodPressure',
            ruleHelper(t, ALLOWED_VALUES, 'diastolicBloodPressure'),
          )}
          errors={form.formState.errors}
          adornment="mmHg"
        />
      </Grid>
    </Grid>
  );
}
