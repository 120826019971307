import { getPDFReport, PDFReportRequest } from '../api/report';

export async function downloadPdfReport(request: PDFReportRequest) {
  const pdfReport = await getPDFReport(request);
  const a = document.createElement('a');
  a.hidden = true;
  a.href = 'data:application/pdf;base64,' + pdfReport.report;

  a.download = getFileName(request.data.patient);
  a.click();
  a.parentNode?.removeChild(a);
}

function getFileName(patientName: { firstName: string; lastName: string }): string {
  const currentTime = new Date();
  return `OptiCorMedicalReport_${patientName.firstName}-${
    patientName.lastName
  }:${currentTime.getFullYear()}-${atLeastTwoDigit(currentTime.getMonth() + 1)}-${atLeastTwoDigit(
    currentTime.getDate(),
  )}_${atLeastTwoDigit(currentTime.getHours())}-${atLeastTwoDigit(currentTime.getMinutes())}.pdf`;
}

function atLeastTwoDigit(num: number): string {
  if (num < 10) {
    if (num > 0) {
      return `0${num}`;
    } else if (num === 0) {
      return '00';
    } else {
      return `-0${-num}`;
    }
  } else {
    return `${num}`;
  }
}
